import React from 'react';
import Image from 'next/image';
import type { FeatureWithItems as FeatureWithItemsProps } from '@web-creditea/interfaces';
import { Typography, LinkAsButton, Gutter } from '@ipf-ui/components-library';
import { AccordionsList, IconItemsList } from './FeatureWithItems.components';

import * as Styled from './FeatureWithItems.style';
import Link from 'next/link';
import { definedValues, nonNullKeys, returnMaybe } from '../../../helpers';

const FeatureWithItems: React.FC<FeatureWithItemsProps> = (props) => {
  const { title, iconItemsList, featureVariant, accordionsList, image, link } = props;
  return (
    <Styled.SectionWrapper>
      {image?.asset?.url && (
        <Styled.ImageWrapper>
          <Image
            src={image?.asset.url}
            alt={image?.customAltText ?? image?.asset.altText ?? ''}
            fill
          />
        </Styled.ImageWrapper>
      )}
      <Styled.ListsContainer>
        <Typography.Header3>{title}</Typography.Header3>
        <Gutter size={8} />
        {featureVariant === 'accordionList' && (
          <AccordionsList list={definedValues(accordionsList)} />
        )}
        {featureVariant === 'iconItemsList' && (
          <>
            <IconItemsList list={definedValues(iconItemsList)} />
            <Gutter size={8} />
            {link?.linkTitle && (
              <LinkAsButton
                NextLink={Link}
                linkData={returnMaybe(
                  nonNullKeys({ ...link, internalLink: nonNullKeys(link.internalLink) })
                )}
              />
            )}
          </>
        )}
      </Styled.ListsContainer>
    </Styled.SectionWrapper>
  );
};

export default FeatureWithItems;
