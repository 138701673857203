import React from 'react';
import { Typography, Icon } from '@ipf-ui/components-library';
import * as Styled from './Link.style';

interface LinkProps {
  href?: string;
  name?: string;
  variant: 'ultraBold' | 'bold' | 'light' | 'ultraLight';
  active?: boolean;
  arrowLeft?: boolean;
  arrowRight?: boolean;
  anchorTarget?: boolean;
  externalLink?: boolean;
  scroll?: boolean;
  mode: 'dark' | 'light' | 'faq' | 'darkFaq' | 'navigation';
}

const Link = ({
  scroll,
  href,
  name,
  variant,
  active,
  arrowLeft,
  arrowRight,
  anchorTarget = false,
  externalLink,
  mode,
}: LinkProps) =>
  !externalLink ? (
    <Styled.InternalLinkStyle
      href={href ?? ''}
      scroll={scroll}
      variant={variant}
      $active={active}
      mode={mode}
    >
      {arrowLeft && (
        <Styled.Arrow arrowLeft>
          <Icon name="ArrowLeftOn" />
        </Styled.Arrow>
      )}
      {variant === 'ultraBold' && <Typography.Caption2>{name}</Typography.Caption2>}
      {variant === 'bold' && <Typography.Link1>{name}</Typography.Link1>}
      {variant === 'light' && <Typography.Link2>{name}</Typography.Link2>}
      {variant === 'ultraLight' && <Typography.Body4>{name}</Typography.Body4>}
      {arrowRight && (
        <Styled.Arrow>
          <Icon name="ArrowRightOn" />
        </Styled.Arrow>
      )}
    </Styled.InternalLinkStyle>
  ) : (
    <Styled.LinkStyle
      target={anchorTarget ? '_self' : '_blank'}
      href={href}
      rel="noreferrer noopener"
      variant={variant}
      $active={active}
      mode={mode}
    >
      {arrowLeft && (
        <Styled.Arrow arrowLeft>
          <Icon name="ArrowLeftOn" />
        </Styled.Arrow>
      )}
      {variant === 'ultraBold' && <Typography.Caption2>{name}</Typography.Caption2>}
      {variant === 'bold' && <Typography.Link1>{name}</Typography.Link1>}
      {variant === 'light' && <Typography.Link2>{name}</Typography.Link2>}
      {variant === 'ultraLight' && <Typography.Body4>{name}</Typography.Body4>}
      {arrowRight && (
        <Styled.Arrow>
          <Icon name="ArrowRightOn" />
        </Styled.Arrow>
      )}
    </Styled.LinkStyle>
  );

export default Link;
