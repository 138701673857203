import React from 'react';
import {
  LoanFormWrapperCZ,
  LoanFormWrapperEE,
  LoanFormWrapperLT,
  LoanFormWrapperLV,
  LoanFormWrapperMX,
} from '@web-creditea/utils';
import { Market, markets } from '@web-creditea/interfaces';
import { Col, Container } from '@ipf-ui/components-library';
import { match } from 'ts-pattern';

export interface LoanFormsWrapperProps {
  market?: Market;
  kubernetesNamespace?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentProps: any;
}

const LoanFormsWrapper = ({ market, kubernetesNamespace, contentProps }: LoanFormsWrapperProps) => {
  const getLoanByMarket = () => {
    return match(market)
      .with(markets.ee, () => (
        <LoanFormWrapperEE
          {...contentProps}
          market={market}
          kubernetesNamespace={kubernetesNamespace}
        />
      ))
      .with(markets.lv, () => (
        <Col xs={4} md={12} lg={8} paddingTop={0} paddingLeft={0}>
          <LoanFormWrapperLV
            {...contentProps}
            market={market}
            kubernetesNamespace={kubernetesNamespace}
          />
        </Col>
      ))
      .with(markets.lt, () => (
        <Col xs={4} md={12} lg={8} paddingTop={0} paddingLeft={0}>
          <LoanFormWrapperLT
            {...contentProps}
            market={market}
            kubernetesNamespace={kubernetesNamespace}
          />
        </Col>
      ))
      .with(markets.cz, () => (
        <LoanFormWrapperCZ
          {...contentProps}
          market={market}
          kubernetesNamespace={kubernetesNamespace}
        />
      ))
      .with(markets.mx, () => (
        <Col xs={4} md={12} lg={8} paddingTop={0} paddingLeft={0}>
          <LoanFormWrapperMX {...contentProps} kubernetesNamespace={kubernetesNamespace} />
        </Col>
      ))
      .otherwise(() => null);
  };

  return (
    <div
      style={{
        backgroundColor: contentProps.backgroundColor?.value,
        padding: '60px 0',
      }}
    >
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center' }}>{getLoanByMarket()}</div>
      </Container>
    </div>
  );
};

export default LoanFormsWrapper;
