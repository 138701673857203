import styled, { css } from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

export const NavWrapper = styled(Nav)`
  flex-grow: 1;
  margin-right: auto;
`;

export const NavItem = styled.div`
  display: block;
  padding: 0 10px;
  position: relative;
`;

export const NavItemLanguagePicker = styled.div<{ withoutButton: boolean }>`
  display: block;
  position: relative;
  padding: 0 20px;
  ${({ withoutButton }) =>
    withoutButton &&
    css`
      padding-right: 100px;
    `}
`;

export const NavItemApplyButton = styled.div`
  display: block;
  padding-left: 20px;
  position: relative;
`;

export const MenuWrapper = styled.div`
  display: none;
  flex: 1;
  min-height: 56px;
  ${({ theme }) => theme.breakpoints.lg} {
    display: flex;
  }
`;

export const PhoneButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const NavWhatsApp = styled.a`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;
