import React from 'react';
import { default as NextLink } from 'next/link';

export interface NextLinkComponentProps {
  href: string;
  scroll?: boolean;
}
const NextLinkComponent = ({ href, scroll, ...rest }: NextLinkComponentProps) => (
  <NextLink href={href} scroll={scroll} {...rest} />
);

export default NextLinkComponent;
