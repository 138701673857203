import {
  CampaignForm,
  Col,
  Gutter,
  LanguageCode,
  Row,
  Typography,
  CampaignFormValues,
  DownloadButton,
} from '@ipf-ui/components-library';
import { definedValues, nonNullKeys, PortableTextWrapper, returnMaybe } from '@web-creditea/utils';
import { TextRaw, UTM_COOKIES, markets } from '@web-creditea/interfaces';
import React, { useCallback, useState } from 'react';
import type { CampaignFormSection } from '@web-creditea/interfaces';
import { useRouter } from 'next/router';
import { Market } from '@web-creditea/interfaces';
import { getCookie } from 'cookies-next';
import { getDomainUrl } from '../../../helpers';
import { sendGTMEvent } from '@next/third-parties/google';

interface CampaignFormWrapperProps {
  urlFor?: (filename: string) => string;
  section: CampaignFormSection;
  market?: Market;
}

export const CampaignFormWrapper = ({ urlFor, section, market }: CampaignFormWrapperProps) => {
  const router = useRouter();
  const languageCode = (router.locale?.toUpperCase() as LanguageCode) ?? 'EN';

  const basePath =
    router.locale !== router.defaultLocale
      ? `${router.basePath}/${router.locale}`
      : router.basePath;

  const domain = getDomainUrl(market);
  const asPath = router.asPath;
  const slug = asPath.split('?')[0];
  const formURL = `${domain.origin}${basePath}${slug}`;

  const utmCookies = getCookie('utm_parameters');
  let parsedCookies: UTM_COOKIES = {};
  if (typeof window != 'undefined' && utmCookies) {
    parsedCookies = JSON.parse(utmCookies);
  }
  const sourceURLCookie = getCookie('sourceURL');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const resetSuccess = useCallback(() => {
    if (success) {
      setSuccess(false);
    }
  }, [success]);

  const handleSubmit = useCallback(
    async (data: CampaignFormValues) => {
      const {
        firstName,
        lastName,
        email,
        countrySelector,
        phoneNumber,
        longText,
        consent1Value,
        consent2Value,
        consent3Value,
        shortText1,
        shortText2,
        shortText3,
      } = data;
      const payload = {
        firstName,
        lastName,
        email,
        consent1Value,
      };

      const countryCode = countrySelector.label.replace('+', '');
      const connectedPhone = parseInt(countryCode + phoneNumber);

      const { utm_campaign, utm_source, utm_term, utm_content, utm_medium } = parsedCookies;

      const url = (market: Market | undefined) => {
        if (market === markets.ee || market === markets.lv || market === markets.lt) {
          return `${window.location.origin}${router.basePath}/api/lead-gathering`;
        } else return `${window.location.origin}/api/lead-gathering`;
      };

      const response = await fetch(url(market), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...payload,
          brand: 'Creditea',
          campaign: section.campaign,
          country: market?.slice(8, 10).toLowerCase(),
          ...(section.addPhoneNumber && phoneNumber ? { phone: connectedPhone } : {}),
          ...(longText ? { longText } : {}),
          ...(shortText1 ? { shortText1 } : {}),
          ...(shortText2 ? { shortText2 } : {}),
          ...(shortText3 ? { shortText3 } : {}),
          consent1Name: section?.consentsArray?.[0]?.consentName?.split(' ').join('_'),
          consent1Version: section?.consentsArray?.[0]?.consentVersion,
          ...(section?.consentsArray?.[1]
            ? { consent2Name: section?.consentsArray[1]?.consentName?.split(' ').join('_') }
            : {}),
          ...(section?.consentsArray?.[1]
            ? { consent2Version: section?.consentsArray[1]?.consentVersion }
            : {}),
          ...(section?.consentsArray?.[1] ? { consent2Value } : {}),
          ...(section?.consentsArray?.[2]
            ? { consent3Name: section?.consentsArray[2]?.consentName?.split(' ').join('_') }
            : {}),
          ...(section?.consentsArray?.[2]
            ? { consent3Version: section?.consentsArray[2]?.consentVersion }
            : {}),
          ...(section?.consentsArray?.[2] ? { consent3Value } : {}),
          ...(utm_medium ? { utmMedium: utm_medium } : {}),
          ...(utm_campaign ? { utmCampaign: utm_campaign } : {}),
          ...(utm_content ? { utmContent: utm_content } : {}),
          ...(utm_source ? { utmSource: utm_source } : {}),
          ...(utm_term ? { utmTerm: utm_term } : {}),
          ...(formURL ? { formURL } : {}),
          ...(sourceURLCookie ? { sourceURL: sourceURLCookie } : {}),
        }),
      });
      if (response.ok) {
        setSuccess(true);
        sendGTMEvent({ event: 'contact_form_submitted' });
        console.log('success');
      } else {
        setError(true);
        console.log(error);
      }
    },
    [router]
  );

  return (
    <Row>
      <Col xs={4} sm={4}>
        <Typography.Header3>{section?.campaignFormTitle}</Typography.Header3>
        <Gutter size={16} />
        <PortableTextWrapper
          urlFor={urlFor}
          portableText={section?.textContent?.textRaw as TextRaw}
        />
      </Col>
      <Col xs={4} sm={4}>
        <CampaignForm
          languageCode={languageCode}
          market={market}
          onSubmit={handleSubmit}
          addPhoneNumber={returnMaybe(section.addPhoneNumber)}
          commentField={returnMaybe(section.commentField)}
          commentPlaceholder={returnMaybe(section.commentPlaceholder)}
          requiredComment={returnMaybe(section.requiredComment)}
          additionalInputs={returnMaybe(
            definedValues(section.additionalInputs).map(
              (input) =>
                nonNullKeys({
                  ...input,
                  type: returnMaybe(input.type as 'small' | 'large'),
                })!
            )
          )}
          consentsArray={returnMaybe(
            definedValues(section.consentsArray).map(
              (consent) =>
                nonNullKeys({
                  ...consent,
                  consentText: consent.consentText as { textRaw: TextRaw },
                })!
            )
          )}
          PortableTextWrapper={PortableTextWrapper}
          success={success}
          resetSuccess={resetSuccess}
          error={error}
          overWriteSendButton={returnMaybe(section.overWriteSendButton)}
          downloadButton={returnMaybe(section.downloadButton as DownloadButton)}
        />
      </Col>
    </Row>
  );
};

export default CampaignFormWrapper;
