import {
  Body,
  Container,
  Head,
  Heading,
  Html,
  Img,
  Section,
  Preview,
} from '@react-email/components';
import { Markdown } from '@react-email/markdown';
import React, { CSSProperties } from 'react';
import { theme } from '@ipf-ui/components-library';

interface PayLaterAutoreplyProps {
  lang?: 'en' | 'es';
}

const headingEn = 'Greetings from Creditea!';
const headingEs = '¡Saludos de Creditea!';

const bodyEn = `
We've successfully received your information. Thank you for your interest in Creditea Pay payment method.

We are reviewing your details to better understand your needs and we'll be reaching out to you soon!

We appreciate the opportunity to discuss a potential business connection with you.

If you have any immediate questions, please don't hesitate to get in touch with us at [paylater@ipfdigital.com](mailto:paylater@ipfdigital.com).

Warm regards,   
Creditea Pay team
`;
const bodyEs = `
Hemos recibido correctamente tu información, gracias por tu interés en el método de pago Creditea Pay.

Estamos revisando los detalles de la información para entender mejor tus necesidades y ponernos en contacto contigo muy pronto.

Apreciamos el interés en colaborar y utilizar nuestro método de pago para ayudarte a impulsar tu negocio.

Si tienes alguna pregunta, no dudes en ponerte en contacto con nosotros en [paylater@ipfdigital.com](mailto:paylater@ipfdigital.com).

¡Saludos!      
Equipo de Creditea Pay
`;

export const logoUrl =
  'https://cdn.sanity.io/images/2v087r7r/ecommerce/379606060225886cf284e149e5da252071449fa7-134x24.png';
export const imgUrl =
  'https://cdn.sanity.io/images/2v087r7r/ecommerce/b90b04e1c4ab26ddb26aaad1c0902362a3aba630-631x260.jpg';

const PayLaterAutoreply = ({ lang }: PayLaterAutoreplyProps) => {
  return (
    <Html>
      <Head />
      <Preview>{lang === 'es' ? headingEs : headingEn}</Preview>
      <Body>
        <Container style={container}>
          <Section style={section}>
            <Img src={logoUrl} style={logo} />
            <Heading as="h1" style={h1}>
              {lang === 'es' ? headingEs : headingEn}
            </Heading>
          </Section>
          <Img src={imgUrl} style={image} />
          <Section style={section}>
            <Markdown
              markdownCustomStyles={{
                p: textBlock,
                link,
              }}
            >
              {lang === 'es' ? bodyEs : bodyEn}
            </Markdown>
          </Section>
        </Container>
      </Body>
    </Html>
  );
};

export default PayLaterAutoreply;

const container: CSSProperties = {
  backgroundColor: theme.crediteaColors.primary.brandBlue[500],
  borderRadius: '40px 0 40px 0',
  padding: '30px 0',
};

const h1: CSSProperties = {
  color: theme.crediteaColors.primary.neutral[50],
  fontFamily: 'Rubik, Arial, helvetica, sans-serif',
  marginBottom: '30px',
};

const logo: CSSProperties = {
  height: '15px',
  marginBottom: '40px',
};

const image: CSSProperties = {
  height: '260px',
  marginBottom: '20px',
};

const textBlock: CSSProperties = {
  color: theme.crediteaColors.primary.neutral[50],
  fontSize: '16px',
  fontFamily: 'Rubik, Arial, helvetica, sans-serif',
  marginBottom: '20px',
  lineHeight: '1.4',
};

const section: CSSProperties = {
  padding: '10px 54px',
};

const link: CSSProperties = {
  color: theme.crediteaColors.primary.neutral[50],
};
