import styled from 'styled-components';

export const MenuLink = styled.div`
  display: block;
  margin: 0;
  position: relative;

  a {
    padding: 0;
  }

  ${({ theme }) => theme.breakpoints.lg} {
    padding: 0 20px;
  }
`;
