import styled from 'styled-components';

export const SideMenu = styled.ul`
  margin: 150px 0 60px;
  padding: 0;
  list-style: none;
`;

export const SideMenuItem = styled.li`
  margin: 3px 0;
`;
