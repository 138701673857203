/**
 * Slider calculator
 * based on LoanCalculator Class from https://credit24.ee/
 */

const perMonthValue: (
  monthlyInterestRate: number,
  periodCount: number,
  presentValue: number,
  futureValue?: number,
  paymentType?: 'end' | 'beginning' // payment period (dafaults to end of the month)
) => number = (
  monthlyInterestRate,
  periodCount,
  presentValue,
  futureValue = 0,
  paymentType = 'end'
) => {
  if (monthlyInterestRate === 0) {
    return -(presentValue + futureValue) / periodCount;
  }
  const valueWithInterest = (1 + monthlyInterestRate) ** periodCount;
  let perMonthValue =
    (-monthlyInterestRate * presentValue * (valueWithInterest + futureValue)) /
    (valueWithInterest - 1);
  if (paymentType === 'beginning') {
    perMonthValue /= 1 + monthlyInterestRate;
  }
  return perMonthValue;
};

export const calculatePaymentInfo = (
  monthlyInterestPercent: number,
  sum: number,
  months: number,
  contractFeePercent = 0
) => {
  const result = {
    inputSum: sum,
    inputMonths: months,
    yearlyInterestPercent: 0,
    contractFee: 0,
    monthlyPayment: 0,
    overallPaymentsSum: 0,
  };

  result.yearlyInterestPercent = 12 * monthlyInterestPercent;
  result.contractFee = sum * contractFeePercent;
  result.monthlyPayment = -1 * perMonthValue(monthlyInterestPercent / 100, months, sum);
  if (result.monthlyPayment < 10) result.monthlyPayment = 10;
  result.overallPaymentsSum = months * result.monthlyPayment;

  return result;
};
