import {
  theme,
  Accordion,
  Divider,
  Gutter,
  MobileDrawer,
  PhoneButton,
} from '@ipf-ui/components-library';
import createMenuList, { createSingleLink } from '../../../helpers/createMenu';
import SubMenu from '../../molecules/SubMenu/SubMenu';
import MenuLink from '../../molecules/MenuLink/MenuLink';
import { ApplyButton, LoginButton, WhatsAppButton } from './components';
import { AllSettings } from '@web-creditea/interfaces';
import * as Styled from './MobileMenu.style';

import React from 'react';

interface IMobileMenu {
  isOpenMobileMenu: boolean;
  isPromoBar?: boolean;
  promoBarOnlyMobile?: boolean;
  settings: AllSettings;
  closeMobileMenu: () => void;
}

const COLOR_MODE = 'light';

const MobileMenu = ({
  isOpenMobileMenu,
  isPromoBar = false,
  promoBarOnlyMobile = false,
  settings,
  closeMobileMenu,
}: IMobileMenu) => {
  const {
    headerMenuItems,
    hideLanguagePicker,
    languagePicker,
    loginButton,
    applyButton,
    phoneButton,
    whatsApp,
    kubernetesNamespace,
  } = settings;

  const {
    link: loginButtonLink,
    title: loginButtonTitle,
    external: loginButtonExternal,
  } = createSingleLink(loginButton?.linkTitle ?? 'Log in', loginButton);
  const {
    link: applyButtonLink,
    title: applyButtonTitle,
    external: applyButtonExternal,
  } = createSingleLink(applyButton?.linkTitle ?? 'Apply now', applyButton);

  const showApplyButton = applyButton?.externalUrl || applyButton?.internalLink;
  const showLoginButton = loginButton?.externalUrl || loginButton?.internalLink;

  const menuList = createMenuList(headerMenuItems, kubernetesNamespace);

  const additionalElements =
    whatsApp ||
    phoneButton?.phoneNumber ||
    (languagePicker?.countries && !hideLanguagePicker) ||
    (languagePicker?.languages && !hideLanguagePicker);

  const toggleAccordion = (accordionId: string, type: 'start' | 'center') => {
    const element = document?.getElementById(accordionId);
    element?.scrollIntoView({ behavior: 'smooth', block: type });
  };
  return (
    <MobileDrawer
      data-testid="mobile-menu"
      isOpen={isOpenMobileMenu}
      hasPromoBar={isPromoBar}
      promoBarOnlyMobile={promoBarOnlyMobile}
    >
      {menuList?.map((item, index) => (
        <Styled.AccordionWrapper
          key={index}
          id={`accordion-${index}`}
          onClick={() => toggleAccordion(`accordion-${index}`, 'center')}
        >
          {item.submenu && item.list ? (
            <Accordion title={item.name as string}>
              <SubMenu
                list={item.list}
                linkVariant="light"
                mode={COLOR_MODE}
                closeMobileMenu={closeMobileMenu}
              />
            </Accordion>
          ) : (
            <MenuLink
              key={index}
              type="mobile"
              closeMobileMenu={closeMobileMenu}
              external={item.external}
              link={item.link}
              mode={COLOR_MODE}
            />
          )}
        </Styled.AccordionWrapper>
      ))}
      {additionalElements && <Divider vertical={false} length={'100%'} />}
      <Gutter size={32} />
      {!hideLanguagePicker && languagePicker?.countries && (
        <Styled.AccordionWrapper
          id={'accordion-countries'}
          onClick={() => toggleAccordion(`accordion-countries`, 'start')}
        >
          <Accordion
            icon="FlagGlobal"
            title={languagePicker.translations?.country || 'Select your country'}
          >
            <SubMenu
              list={languagePicker.countries.map((country) => {
                return { ...country, external: true };
              })}
              linkVariant="light"
              mode={COLOR_MODE}
              closeMobileMenu={closeMobileMenu}
            />
          </Accordion>
        </Styled.AccordionWrapper>
      )}
      {!hideLanguagePicker && languagePicker?.languages && (
        <Styled.AccordionWrapper
          id={'accordion-languages'}
          onClick={() => toggleAccordion(`accordion-languages`, 'start')}
        >
          <Accordion
            icon="FlagsChange"
            title={languagePicker.translations?.language || 'Select your language'}
          >
            <SubMenu
              list={languagePicker.languages.map((lang) => ({ ...lang, isLanguage: true }))}
              linkVariant="light"
              mode={COLOR_MODE}
              closeMobileMenu={closeMobileMenu}
            />
          </Accordion>
        </Styled.AccordionWrapper>
      )}
      {whatsApp && <WhatsAppButton href={whatsApp} />}
      {phoneButton?.phoneNumber && (
        <PhoneButton
          countryCode={phoneButton.countryCode}
          phoneNumber={phoneButton.phoneNumber}
          buttonText=""
          iconName="Phone"
          mode="dark"
          color={theme.crediteaColors.primary.brandBlue[500]}
        />
      )}
      {showApplyButton && showLoginButton ? <Gutter size={140} /> : <Gutter size={80} />}
      <Styled.ButtonsWrapper>
        {applyButtonLink && showApplyButton && (
          <ApplyButton
            buttonLink={applyButtonLink}
            buttonTitle={applyButtonTitle}
            buttonExternal={applyButtonExternal}
            closeMobileMenu={closeMobileMenu}
          />
        )}
        {loginButtonLink && showLoginButton && (
          <LoginButton
            buttonLink={loginButtonLink}
            buttonTitle={loginButtonTitle}
            buttonExternal={loginButtonExternal}
            closeMobileMenu={closeMobileMenu}
          />
        )}
      </Styled.ButtonsWrapper>
    </MobileDrawer>
  );
};

export default MobileMenu;
