import type { Link } from '@web-creditea/interfaces';

interface useLinkProps {
  href: string;
  linkTitle: string;
  isExternal: boolean;
  anchorTarget?: boolean;
}

export const createLink = (link: Link): useLinkProps => {
  const externalLink = link?.externalUrl;
  const internalLink =
    link.internalLink && link.internalLink.__typename !== 'HomePage' && 'slug' in link.internalLink
      ? link?.internalLink?.slug?.current ?? ''
      : '';
  const anchorTag = link?.anchorID ? `#${link.anchorID}` : '';

  const href = externalLink ? externalLink : `/${internalLink}${anchorTag}`;

  return {
    href,
    linkTitle: link?.linkTitle ?? '',
    isExternal: !!externalLink,
    anchorTarget: Boolean(link?.anchorTarget),
  };
};
