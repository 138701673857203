import styled from 'styled-components';
import { Typography } from '@ipf-ui/components-library';

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 18px;
  overflow: auto;

  ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 41px;
  }

  svg {
    width: 2rem;
    height: 2rem;
    margin-top: 2px;
  }

  span,
  p {
    color: ${({ theme }) => theme.crediteaColors.primary.neutral[500]};
  }
`;

export const BreadcrumbText = styled(Typography.Body3)`
  white-space: nowrap;
  ${({ theme }) => theme.breakpoints.xl} {
    white-space: pre-line;
  }
`;

export const LinkStyle = styled.div`
  a span {
    white-space: nowrap;
    ${({ theme }) => theme.breakpoints.xl} {
      white-space: pre-line;
    }
  }
`;
