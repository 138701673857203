import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetAllPageQuery,
  GetAllPageQueryVariables,
  GetAllPageDocument,
  SlugsDocument,
  SlugsQuery,
  SlugsQueryVariables,
} from '@web-creditea/interfaces';

export const getPageData =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (slug: string, language: string, market: string, preview: boolean) => {
    const {
      data: { allPages },
    } = await client.query<GetAllPageQuery, GetAllPageQueryVariables>({
      query: GetAllPageDocument,
      variables: { slug, language, market },
      fetchPolicy: 'no-cache',
    });

    const pageDraft = allPages.find((page) => page._id?.startsWith('drafts'));
    const publishedPage = allPages.find((page) => !page._id?.startsWith('drafts'));
    const pageData = preview && pageDraft ? pageDraft : publishedPage;

    return pageData;
  };

export const getPagesPath =
  (client: ApolloClient<NormalizedCacheObject>) => async (locales: string[], market: string) => {
    const localesUpperCase = locales.map((locale) => locale.toUpperCase());

    const { data } = await client.query<SlugsQuery, SlugsQueryVariables>({
      query: SlugsDocument,
      variables: { language: localesUpperCase, market },
      fetchPolicy: 'no-cache',
    });

    return [...data.allPages].map((page) => {
      const slug = page.slug?.current ?? '';
      const lang = page?.language?.toLowerCase();

      return {
        params: { slug: slug.split(' ') },
        locale: lang,
      };
    });
  };
