import styled, { DefaultTheme, css } from 'styled-components';
import Link from 'next/link';

type TLink = {
  variant: 'ultraBold' | 'bold' | 'light' | 'ultraLight';
  $active?: boolean;
  mode: 'dark' | 'light' | 'faq' | 'darkFaq' | 'navigation';
};

export const modeStyles = (theme: DefaultTheme, mode = 'dark', $active?: boolean) =>
  ({
    dark: css`
      span {
        color: ${theme.crediteaColors.primary.neutral[50]};
        ${$active && `color: ${theme.crediteaColors.secondary.lightBlue[500]};`}
      }
      &:hover {
        span {
          color: ${theme.crediteaColors.secondary.lightBlue[500]};
        }
      }
    `,
    light: css`
      span {
        color: ${theme.crediteaColors.primary.neutral[800]};
        ${$active && `color: ${theme.crediteaColors.primary.brandBlue[500]};`}
      }
      &:hover {
        span {
          color: ${theme.crediteaColors.primary.brandBlue[500]};
        }
      }
    `,
    faq: css`
      span {
        color: ${theme.crediteaColors.primary.neutral[500]};
      }
      &:hover {
        span {
          color: ${theme.crediteaColors.primary.brandBlue[500]};
        }
      }
    `,
    darkFaq: css`
      span {
        color: ${theme.crediteaColors.primary.brandBlue[500]};
      }
    `,
    navigation: css`
      &:hover {
        span {
          color: ${theme.crediteaColors.primary.neutral[800]};
        }
      }
    `,
  })[mode];

export const LinkStyle = styled.a<TLink>`
  text-decoration: none;
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;

  ${({ theme, mode, $active }) => modeStyles(theme, mode, $active)}

  ${({ variant }) =>
    variant === 'ultraLight'
      ? css`
          padding: 10px 0;
        `
      : css`
          padding: 15px 0;
        `}
`;

export const InternalLinkStyle = styled(Link)<TLink>`
  text-decoration: none;
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;

  ${({ theme, mode, $active }) => modeStyles(theme, mode, $active)}

  ${({ variant }) =>
    variant === 'ultraLight'
      ? css`
          padding: 10px 0;
        `
      : css`
          padding: 15px 0;
        `}
`;

export const Arrow = styled.span<{ arrowLeft?: boolean }>`
  position: relative;
  top: 2px;
  ${({ arrowLeft }) => (arrowLeft ? `padding-right: 15px;` : `padding-left: 15px;`)}
`;
