type NonNullValues<T> = {
  [K in keyof T]?: Exclude<T[K], null>;
};
type SilentAny<T> = string | number | boolean | object | null | undefined | T;

// use this one with simple values to replace null with undefined
export function returnMaybe<T>(something: T | null | undefined) {
  return something ?? undefined;
}

// use this one with objects to remove nulls from values
export function nonNullKeys<T extends Partial<Record<keyof T, SilentAny<T>> | null | undefined>>(
  object: T
) {
  if (!object) return;

  return Object.fromEntries(
    Object.entries(object).map(([key, val]) => [key, returnMaybe(val)])
  ) as NonNullValues<T>;
}

// use this one to remove nulls and undefineds from the array
export function definedValues<T>(array: (T | null | undefined)[] | null | undefined): T[] {
  if (!array) return [];
  return array.filter((item): item is T => Boolean(item));
}
