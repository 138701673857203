import React, { useState, useEffect } from 'react';
import loanFormData from '../../../data/products/creditlineLV.json';
import {
  LoanForm,
  Slider,
  SliderProps,
  Markets,
  currencyFormatLV,
  ColorModeForButton,
} from '@ipf-ui/components-library';
import { useRouter } from 'next/router';
import { swapSubdomain } from '../../../helpers/createMenu';

const loanValues = loanFormData.map((loanVariant) => {
  return loanVariant.sum / 100;
});

export type LoanFormWrapperLVProps = {
  sliderVariant: Markets;
  currencySymbol: string;
  interestRate: number;
  amountSlider: SliderProps;
  periodSlider: SliderProps;
  termsDescription: string;
  title: string;
  description: string;
  refundsLabel: string;
  buttonText: string;
  buttonUrl: string;
  anchorTarget?: boolean;
  colorMode?: ColorModeForButton;
  market: string;
  kubernetesNamespace: string;
  backgroundColor?: {
    value: string;
  };
};

const LoanFormWrapperLV = ({
  sliderVariant,
  amountSlider,
  termsDescription,
  title,
  description,
  refundsLabel,
  buttonText,
  buttonUrl,
  anchorTarget,
  colorMode,
  market,
  kubernetesNamespace,
}: LoanFormWrapperLVProps) => {
  const [amountInputValue, setAmountInputValue] = useState<number>(1000);
  const [periodValue, setPeriodValue] = useState<number>(60);
  const [interestRate, setInterestRate] = useState(1.5);
  const [monthlyPaymentValue, setMonthlyPaymentValue] = useState<number>(0);
  const router = useRouter();
  const sliders = [
    {
      ...amountSlider,
      value: amountInputValue,
      marks: loanValues,
      type: Slider.Types.Primary,
      format: currencyFormatLV,
      onChange: (value: number) => setAmountInputValue(value),
    },
  ];

  const applyButton = `${swapSubdomain(
    buttonUrl,
    kubernetesNamespace
  )}?principal=${amountInputValue}&maturity=${periodValue}&channel=creditea_web&lang=${
    router.locale
  }`;

  const loanFormProps = {
    sliders,
    termsDescription,
    periodValue,
    interestRate,
    format: currencyFormatLV,
    callToAction: {
      title,
      description,
      refundsLabel,
      buttonText,
      colorMode,
      buttonAction: () => window.open(applyButton, anchorTarget === false ? '_blank' : '_self'),
    },
  };

  useEffect(() => {
    const index = loanFormData.findIndex((loanVariant) => {
      return loanVariant.sum === amountInputValue * 100;
    });
    setMonthlyPaymentValue(loanFormData[index].mmp / 100);
    setPeriodValue(loanFormData[index].product.maturityPeriods);
    setInterestRate(loanFormData[index].product.interest);
  }, [amountInputValue]);

  if (sliderVariant !== market.substring(8)) return null;
  return (
    <>
      <LoanForm {...loanFormProps} market={sliderVariant} paymentValue={monthlyPaymentValue} />
    </>
  );
};

export default LoanFormWrapperLV;
