import React from 'react';

import * as Styled from './FeatureWithItems.style';
import type { IconListItem as IconListItemProps, FaqItem, TextRaw } from '@web-creditea/interfaces';
import { Accordion } from '@ipf-ui/components-library';
import IconListItem from './IconListItem/IconListItem';
import { PortableTextWrapper } from '@web-creditea/utils';
import { nonNullKeys, returnMaybe } from '../../../helpers';

export const IconItemsList: React.FC<{ list: IconListItemProps[] }> = ({ list }) => {
  return (
    <Styled.List>
      {list?.map((iconItem) => (
        <IconListItem
          key={iconItem._key}
          data={nonNullKeys({
            ...iconItem,
            textContent: { textRaw: iconItem.textContent?.textRaw as TextRaw },
          })}
        />
      ))}
    </Styled.List>
  );
};

export const AccordionsList: React.FC<{ list: FaqItem[] }> = ({ list }) => {
  return (
    <Styled.List>
      {list?.map((accordion, i) => (
        <li style={{ listStyleType: 'none' }} key={i}>
          <Accordion key={accordion._key} title={returnMaybe(accordion.title)} variant="shortFaq">
            {accordion.content && (
              <PortableTextWrapper portableText={accordion.content.textRaw as TextRaw} />
            )}
          </Accordion>
        </li>
      ))}
    </Styled.List>
  );
};
