import React from 'react';

import { Container, Typography, Gutter, Col } from '@ipf-ui/components-library';
import Image from 'next/image';
import XFactorImage from './XFactorImage.png';
import MobileImage from './MobileImage.png';

import * as Styled from './HeroForXFactor.style';
import { PortableTextBlock } from '@portabletext/types';
import { PortableTextWrapper, definedValues } from '@web-creditea/utils';

interface HeroForXFactorProps {
  title?: string;
  textContent?: {
    textRaw?: Array<PortableTextBlock>;
  };
  isPromoBar?: boolean;
  promoBarOnlyMobile?: boolean;
}

interface ContentProps {
  title: string;
  textRaw: Array<PortableTextBlock>;
}

const TextSectionForXFactor = ({ textRaw, title }: ContentProps) => {
  return (
    <Styled.TextSection justify={Col.Justify.Center} xs={4} sm={8} lg={5} paddingTop={0}>
      <Typography.Header1 color={'#FFF'} dangerouslySetInnerHTML={{ __html: title }} />
      <Gutter size={24} />
      <Styled.HeroDescription>
        <PortableTextWrapper portableText={textRaw} mode={'light'} />
      </Styled.HeroDescription>
    </Styled.TextSection>
  );
};

const DesktopContent = ({ textRaw, title }: ContentProps) => {
  return (
    <Styled.RowForDesktop reverse={true}>
      <Styled.ImageWrapper lg={6}>
        <Image src={XFactorImage} alt={'x-factor image'} fill />
      </Styled.ImageWrapper>
      <TextSectionForXFactor textRaw={textRaw} title={title} />
    </Styled.RowForDesktop>
  );
};

const MobileContent = ({ textRaw, title }: ContentProps) => {
  return (
    <Styled.RowForMobile>
      <Styled.MobileImage>
        <Styled.ImageWrapper>
          <Image src={MobileImage} alt={'x-factor image'} fill />
        </Styled.ImageWrapper>
      </Styled.MobileImage>
      <Styled.TabletImage>
        <Styled.ImageWrapper>
          <Image src={XFactorImage} alt={'x-factor image'} fill />
        </Styled.ImageWrapper>
      </Styled.TabletImage>
      <Container>
        <TextSectionForXFactor textRaw={textRaw} title={title} />
      </Container>
    </Styled.RowForMobile>
  );
};

const HeroForXFactor = ({
  title,
  textContent,
  isPromoBar,
  promoBarOnlyMobile,
}: HeroForXFactorProps) => {
  if (!textContent?.textRaw || !title) return null;
  return (
    <Styled.HeroWrapper isPromoBar={isPromoBar} promoBarOnlyMobile={promoBarOnlyMobile}>
      <DesktopContent textRaw={definedValues(textContent.textRaw)} title={title} />
      <MobileContent textRaw={definedValues(textContent.textRaw)} title={title} />
    </Styled.HeroWrapper>
  );
};

export default HeroForXFactor;
