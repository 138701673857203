import styled, { css } from 'styled-components';
import { Col, Row } from '@ipf-ui/components-library';

export interface HeroWrapperProps {
  isPromoBar?: boolean;
  promoBarOnlyMobile?: boolean;
}

const navbarHeight = 74;
const promoBarMobileHeight = 80;
const promoBarDesktopHeight = 58;
const mobileSpacing = 32;
const tabletSpacing = 60;
const desktopSpacing = 60;

const navbarHeightWithPromoBar = () => css`
  padding-top: ${navbarHeight + promoBarMobileHeight + mobileSpacing}px;
  box-shadow: inset 0 154px 0 #10465b;
  ${({ theme }) => theme.breakpoints.sm} {
    padding-top: ${navbarHeight + promoBarDesktopHeight + tabletSpacing}px;
    box-shadow: inset 0 132px 0 #10465b;
  }
  ${({ theme }) => theme.breakpoints.md} {
    padding-top: ${navbarHeight + promoBarDesktopHeight + desktopSpacing}px;
  }
`;

const navbarHeightWithSpacing = () => css`
  padding-top: ${navbarHeight + mobileSpacing}px;
  box-shadow: inset 0 74px 0 #10465b;
  ${({ theme }) => theme.breakpoints.sm} {
    padding-top: ${navbarHeight + tabletSpacing}px;
  }
  ${({ theme }) => theme.breakpoints.md} {
    padding-top: ${navbarHeight + desktopSpacing}px;
  }
`;

export const HeroWrapper = styled.section<HeroWrapperProps>`
  background-color: #120f11;
  //background: linear-gradient(
  //  to left,
  //  rgba(58, 58, 59, 1) 0%,
  //  rgba(18, 15, 17, 1) 90%,
  //  rgba(0, 0, 0, 1) 100%
  //);
  padding-bottom: 60px;
  display: flex;
  width: 100%;
  ${({ isPromoBar }) => (isPromoBar ? navbarHeightWithPromoBar() : navbarHeightWithSpacing())};
  ${({ theme }) => theme.breakpoints.sm} {
    ${({ isPromoBar, promoBarOnlyMobile }) =>
      isPromoBar && !promoBarOnlyMobile ? navbarHeightWithPromoBar() : navbarHeightWithSpacing()}
  }
  ${({ theme }) => theme.breakpoints.sm} {
    border-bottom-right-radius: 150px;
  }

  ${({ theme }) => theme.breakpoints.md} {
    justify-content: center;
  }
  ${({ theme }) => theme.breakpoints.lg} {
    border-bottom-right-radius: 250px;
    padding-bottom: 0;
  }
`;

export const ImageWrapper = styled(Col)`
  &&& {
    height: 280px;
    width: 100%;
    position: relative;
    padding-left: 0;
    display: flex;
    margin-bottom: 24px;
    overflow: visible;

    & > img {
      object-fit: cover;
      ${({ theme }) => theme.breakpoints.lg} {
        border-bottom-right-radius: 250px;
      }
    }

    ${({ theme }) => theme.breakpoints.sm} {
      height: 300px;
    }

    ${({ theme }) => theme.breakpoints.md} {
      display: flex;
    }

    ${({ theme }) => theme.breakpoints.lg} {
      width: 100vw;
      height: 480px;
      border-bottom-right-radius: 250px;
      margin-bottom: 0;
      margin-top: 24px;
    }
  }
`;

export const TextSection = styled(Col)`
  &&& {
    padding-left: 0;
    ${({ theme }) => theme.breakpoints.lg} {
      padding-right: 20px;
    }
    ${({ theme }) => theme.breakpoints.xl} {
      padding-left: 100px;
    }
  }
`;

export const HeroDescription = styled.div`
  text-align: left;

  > :is(h1, h2, h3, h4, h5, h6, p) {
    color: white;
    *:not(a) {
      color: inherit;
    }
  }
`;

export const RowForDesktop = styled(Row)`
  &&& {
    display: none;
    ${({ theme }) => theme.breakpoints.lg} {
      display: flex;
    }
  }
`;

export const RowForMobile = styled(Row)`
  &&& {
    margin-left: 0;
    ${({ theme }) => theme.breakpoints.lg} {
      display: none;
    }
  }
`;

export const TabletImage = styled.div`
  display: none;
  ${({ theme }) => theme.breakpoints.sm} {
    display: flex;
    width: 100%;
  }
`;

export const MobileImage = styled.div`
  display: flex;
  width: 100%;
  ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;
