import React from 'react';
import {
  LanguagePicker,
  Button,
  IconNames,
  LinkAsButton,
  PhoneButton,
  ColorMode,
  Icon,
} from '@ipf-ui/components-library';
import ToggleButton, { MenuList } from '../../atoms/ToggleButton/ToggleButton';
import { useRouter } from 'next/router';
import createMenuList, { getCountryFlag } from '../../../helpers/createMenu';
import MenuLink from '../../molecules/MenuLink/MenuLink';

import * as Styled from './Menu.style';
import NextLink from 'next/link';
import { AllSettings } from '@web-creditea/interfaces';
import { definedValues, getUserDevice, nonNullKeys, returnMaybe } from '../../../helpers';

export type NavItems = {
  [key: string]: {
    title: string;
    list: MenuList;
  };
};

interface MenuProps {
  showButton?: boolean;
  settings: AllSettings;
  mode: ColorMode;
}

const Menu = ({ showButton = false, mode, settings }: MenuProps) => {
  const {
    headerMenuItems,
    languagePicker,
    CTAButton,
    hideLanguagePicker,
    phoneButton,
    loginButton,
    applyButton,
    whatsApp,
    kubernetesNamespace,
    market,
  } = settings;

  const router = useRouter();
  const pathName = router?.asPath;
  const activeSubMenu = pathName?.split('/')[1];

  const flagName = getCountryFlag(market);
  const userDevice = getUserDevice(market);

  const handleButtonClick = () => {
    window.open(CTAButton.link || userDevice.deviceAppLink, '_blank');
  };

  const createLanguageList = () => {
    return languagePicker.languages?.map((lang) => {
      const hashIndex = router.asPath.indexOf('#');
      if (hashIndex > -1) {
        router.asPath = router.asPath.slice(0, hashIndex);
      }
      return {
        name: returnMaybe(lang?.name),
        onClick: () => {
          if (!lang) return;
          router.push(router.asPath, undefined, { locale: returnMaybe(lang?.href) });
        },
      };
    });
  };

  const menuList = createMenuList(headerMenuItems, kubernetesNamespace);
  const withoutButton =
    !loginButton?.externalUrl &&
    !loginButton?.internalLink &&
    !applyButton?.externalUrl &&
    !applyButton?.internalLink;
  const inverseColorMode = mode === 'dark' ? 'light' : 'dark';

  return (
    <Styled.MenuWrapper>
      <Styled.NavWrapper data-testid="main-menu">
        {menuList?.map((item, index) =>
          item.submenu ? (
            <Styled.NavItem key={index}>
              <ToggleButton
                name={item.name}
                menuList={item.list!}
                active={item.list?.some(
                  (item) => activeSubMenu?.includes(item.href.replace('/', ''))
                )}
                mode={mode}
              >
                {item.name}
              </ToggleButton>
            </Styled.NavItem>
          ) : (
            <MenuLink
              key={index}
              type="desktop"
              external={item.external}
              link={item.link}
              active={item.link?.href === pathName}
              mode={mode}
            />
          )
        )}
      </Styled.NavWrapper>
      <Styled.Nav>
        {!hideLanguagePicker && languagePicker && (
          <Styled.NavItemLanguagePicker withoutButton={withoutButton}>
            <LanguagePicker
              hideFlagsIcon
              countryList={returnMaybe(
                definedValues(languagePicker.countries?.map((country) => nonNullKeys(country)))
              )}
              languageList={returnMaybe(createLanguageList())}
              countryTitle={returnMaybe(languagePicker.translations?.country)}
              languageTitle={returnMaybe(languagePicker.translations?.language)}
              buttonFlag={flagName as IconNames}
              currentLanguageText={router.locale}
              mode={mode}
            />
          </Styled.NavItemLanguagePicker>
        )}
        {whatsApp && (
          <Styled.NavWhatsApp
            target="_blank"
            href={whatsApp}
            rel="noreferrer noopener"
            aria-label={'Call on WhatsApp'}
          >
            <Icon name={'MenuWhatsApp' as IconNames} width={36} height={36} />
          </Styled.NavWhatsApp>
        )}
        {phoneButton && (
          <Styled.PhoneButtonWrapper>
            <PhoneButton
              buttonText={phoneButton.buttonText}
              countryCode={phoneButton.countryCode || ''}
              phoneNumber={phoneButton.phoneNumber}
              mode={inverseColorMode}
            />
          </Styled.PhoneButtonWrapper>
        )}
        {showButton && (
          <Styled.NavItem>
            <Button
              size="small"
              variant="primary"
              mode={inverseColorMode}
              onClick={handleButtonClick}
            >
              {CTAButton.title}
            </Button>
          </Styled.NavItem>
        )}
      </Styled.Nav>
      {loginButton && (
        <Styled.Nav>
          <LinkAsButton
            NextLink={NextLink}
            linkData={loginButton}
            mode={inverseColorMode}
            variant="text"
            id="login-button"
          />
        </Styled.Nav>
      )}
      {applyButton && (
        <Styled.Nav>
          <LinkAsButton
            NextLink={NextLink}
            linkData={applyButton}
            mode={inverseColorMode}
            variant="primary"
            id="apply-button"
          />
        </Styled.Nav>
      )}
    </Styled.MenuWrapper>
  );
};

export default Menu;
