import React, { useCallback } from 'react';
import { DownloadAppButtons } from '@ipf-ui/components-library';
import { isIOS } from 'react-device-detect';
import { UserDevice } from '@web-creditea/interfaces';

interface IProps {
  iconVariant?: 'light' | 'dark';
  showMobileButton?: boolean;
  userDevice?: UserDevice;
  downloadAppButtonsTitle?: string;
}

const DownloadAppButtonsWrapper = ({
  iconVariant = 'dark',
  showMobileButton,
  userDevice = {
    isMobile: false,
    deviceAppLink: '',
    appLinks: {
      iOS: '',
      android: '',
    },
  },
  downloadAppButtonsTitle,
}: IProps) => {
  const handleButtonClick = useCallback(() => {
    window.open(userDevice?.deviceAppLink, '_blank');
  }, [userDevice]);

  return (
    <DownloadAppButtons
      iconVariant={iconVariant}
      appLinks={userDevice.appLinks}
      buttonId={isIOS ? 'apple-app-store' : 'google-play-store'}
      buttonTitle={downloadAppButtonsTitle || ''}
      showMobileButton={showMobileButton}
      handleButtonClick={handleButtonClick}
    />
  );
};

export default DownloadAppButtonsWrapper;
