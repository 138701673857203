import React from 'react';
import {
  Col,
  Row,
  Typography,
  Socials,
  useIsScreenSize,
  SocialIconsNames,
} from '@ipf-ui/components-library';
import SubMenu from '../../molecules/SubMenu/SubMenu';
import * as Styled from './FooterNavDesktop.style';
import { SocialWrapper, TextSocialWrapper } from './FooterNavDesktop.style';
import { FooterNavProps } from '../../organisms/Footer/Footer.types';
import { definedValues, nonNullKeys } from '../../../helpers';

const FooterNavDesktop = ({ menuList, socialList, socialsTitle }: FooterNavProps) => {
  const { isScreenAboveSM } = useIsScreenSize();

  return (
    <Row>
      <Col sm={8} md={8} lg={8}>
        <Row>
          {menuList?.map((item) => (
            <Col sm={2} md={3} lg={4} key={item.name}>
              <Styled.Header>
                <Typography.Header7>
                  {item.link ? (
                    <Styled.LinkWrapper
                      href={item.link.href}
                      prefetch={!item.external ? false : undefined}
                      target={!item.link.anchorTarget ? '_blank' : '_self'}
                    >
                      {item.link.name || item.name}
                    </Styled.LinkWrapper>
                  ) : (
                    item.name
                  )}
                </Typography.Header7>
              </Styled.Header>
              <SubMenu
                list={item.list!}
                linkVariant="ultraLight"
                listItemToLeft={true}
                mode={'dark'}
              />
            </Col>
          ))}
        </Row>
      </Col>
      <Col sm={8} md={4} lg={4} align={isScreenAboveSM ? Col.Align.End : Col.Align.Start}>
        <SocialWrapper>
          <TextSocialWrapper>
            <Styled.Header>
              <Typography.Header7>{socialsTitle}</Typography.Header7>
            </Styled.Header>
            <Socials
              socialList={definedValues(
                socialList?.map((social) => {
                  return nonNullKeys({ ...social, icon: social.icon as SocialIconsNames });
                })
              )}
              withMargin={false}
            />
          </TextSocialWrapper>
        </SocialWrapper>
      </Col>
    </Row>
  );
};

export default FooterNavDesktop;
