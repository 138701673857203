import styled, { css } from 'styled-components';
import { Button } from '@ipf-ui/components-library';

export const SubMenuList = styled.ul<{ isRow?: boolean }>`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${(props) =>
    props.isRow &&
    css`
      flex-direction: row;
    `}
`;

export const SubMenuListItem = styled.li<{
  isRow?: boolean;
  listItemToLeft?: boolean;
  variant?: string;
}>`
  margin: 0;
  display: flex;
  align-items: center;
  padding: 1px 24px;

  ${(props) =>
    props.listItemToLeft &&
    css`
      padding: 0;
    `}

  border-radius: 8px;
  ${({ variant }) =>
    variant !== 'ultraLight' &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.crediteaColors.primary.brandBlue[100]};
      }
    `}
  ${(props) =>
    props.isRow &&
    css`
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    `}

  svg {
    margin-right: 10px;
  }
`;

export const ButtonElement = styled(Button)`
  &&& {
    padding: 15px 0;
    flex: 1;
  }
`;
