import React from 'react';
import { Controller, Control, FormState, UseFormRegister } from 'react-hook-form';
import { Button, CheckboxItem, Gutter, InputItem, SelectItem } from '@ipf-ui/components-library';
import { FormValues, PayLaterFormProps, ResetOnChange } from '../PayLaterForm.types';

interface BusinessInformationProps {
  register: UseFormRegister<FormValues>;
  control: Control<FormValues>;
  formState: FormState<FormValues>;
  translations: PayLaterFormProps['translations'];
  success: boolean;
  resetSuccess: () => void;
  resetOnChange: ResetOnChange;
}

export const BusinessInformation: React.FC<BusinessInformationProps> = (props) => {
  const { control, formState, register, translations, resetSuccess, resetOnChange } = props;
  const { errors } = formState;
  return (
    <div>
      <InputItem
        {...register('tradeName', {
          required: translations.requiredError,
          pattern: {
            value: /^.+$/g,
            message: translations.requiredError,
          },
        })}
        type="text"
        placeholder={translations.tradeName.placeholder}
        errorMessage={errors.tradeName?.message}
        mode="dark"
        onKeyDown={resetSuccess}
      />
      <Gutter size={32} />
      {translations.ecommercePlatform.options.length && (
        <Controller
          name="sector.value"
          control={control}
          rules={{ required: translations.requiredError, pattern: /^.+$/g }}
          render={({ field }) => (
            <SelectItem
              {...field}
              onChange={(e) => resetOnChange(field.onChange, e.currentTarget.value)}
              value={translations.sector.options.find((o) => o.value === field.value)}
              title={translations.sector.label}
              defaultOption={translations.sector.options[0]}
              options={translations.sector.options}
              errorMessage={errors.sector?.value?.message}
              mode="dark"
              menuHeight={234}
            />
          )}
        />
      )}
      <Gutter size={32} />
      <InputItem
        {...register('website', {
          required: translations.requiredError,
          pattern: {
            value:
              /^(https:\/\/(www\.)?|www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g,
            message: translations.website.error ?? translations.requiredError,
          },
        })}
        type="text"
        placeholder={translations.website.placeholder}
        errorMessage={errors.website?.message}
        mode="dark"
        onKeyDown={resetSuccess}
      />
      <Gutter size={32} />
      {translations.ecommercePlatform.options.length && (
        <Controller
          name="ecommercePlatform.value"
          control={control}
          rules={{ required: translations.requiredError, pattern: /^.+$/g }}
          render={({ field }) => (
            <SelectItem
              {...field}
              onChange={(e) => resetOnChange(field.onChange, e.currentTarget.value)}
              value={translations.ecommercePlatform.options.find((o) => o.value === field.value)}
              title={translations.ecommercePlatform.label}
              defaultOption={translations.ecommercePlatform.options[0]}
              options={translations.ecommercePlatform.options}
              errorMessage={errors.ecommercePlatform?.value?.message}
              mode="dark"
            />
          )}
        />
      )}
      <Gutter size={32} />
      <Controller
        control={control}
        name="campaignRules"
        rules={{ required: translations.campainRules.error }}
        render={({ field }) => (
          <CheckboxItem
            {...field}
            label={translations.campainRules.placeholder}
            mode="dark"
            errorMessage={errors.campaignRules?.message}
          />
        )}
      />
      <Gutter size={32} />
      <Button type="submit" variant="primary" mode="light">
        {translations.submitButtonLabel}
      </Button>
    </div>
  );
};
