import styled from 'styled-components';
import { Container, Typography } from '@ipf-ui/components-library';

export const Container404 = styled(Container)`
  &&& {
    padding-top: 106px;
    padding-bottom: 60px;

    ${({ theme }) => theme.breakpoints.sm} {
      padding-top: 120px;
      padding-bottom: 100px;
    }

    ${({ theme }) => theme.breakpoints.md} {
      padding-top: 214px;
      padding-bottom: 110px;
    }
  }
`;

export const Header1 = styled(Typography.Header1)`
  ${({ theme }) => theme.breakpoints.xs} {
    margin-top: 32px;
  }

  ${({ theme }) => theme.breakpoints.md} {
    margin-top: 0;
  }
`;

export const Body1 = styled(Typography.Body1)`
  ${({ theme }) => theme.breakpoints.xs} {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 24px;
    margin-bottom: 48px;
  }
`;
