export const PRIMARY_COUNTRY_CODE = 'EE';
export const PRIMARY_LOCALES = 'et-EE';
export const SECONDARY_COUNTRY_CODE = 'RU';
export const SECONDARY_LOCALES = 'ru-RU';
export const PRIMARY_LANGUAGE_CODE = 'et';
export const SECONDARY_LANGUAGE_CODE = 'ru';
export const FAQ_TRANSLATION = 'abi';
export const PRIMARY_FAQ_BREADCRUMB_TRANSLATION = 'abi';
export const SECONDARY_FAQ_BREADCRUMB_TRANSLATION = 'чаво';
export const BLOG_TRANSLATION = 'blog';
export const MARKET = 'crediteaEE';
export const IMPERVA_URL = `https://my.imperva.com/api/prov/v2/sites/${process.env.IMPERVA_SITE_ID}/cache`;
export const APP_URL = 'https://www.creditea.com';
export const TESTING_PREVIEW_DOMAIN = 'https://st08.creditea.com/ee';
export const PRODUCTION_PREVIEW_DOMAIN = 'https://web-creditea-ee.nonprod.ipfdigital.io';
export const SANITY_CDN_IMAGE_URL = 'https://cdn.sanity.io/images';
export const REVALIDATION_TIME = 15;
export const SANITY_API_VERSION = '2024-06-01';
