import React, { useEffect } from 'react';
import { PortableTextWrapper } from '../../atoms';
import { useForm } from 'react-hook-form';
import { Gutter, Container, Snackbar } from '@ipf-ui/components-library';
import { defaultValues } from './PayLaterForm.data';
import * as Styled from './PayLaterForm.style';
import type { PayLaterFormProps, FormValues } from './PayLaterForm.types';
import { BusinessInformation } from './components/BusinessInformation';
import { UserInformation } from './components/UserInformation';

export const PayLaterForm = ({
  onSubmit,
  translations,
  title,
  subtitle,
  success,
  resetSuccess,
  resetOnChange,
}: PayLaterFormProps) => {
  const { control, register, handleSubmit, formState, reset } = useForm<FormValues>({
    defaultValues: defaultValues(translations),
    mode: 'onTouched',
  });
  useEffect(() => {
    if (success) {
      reset(defaultValues(translations));
    }
  }, [success, reset, translations]);

  return (
    <Styled.SectionWrapper>
      <Container>
        <Styled.FormHeader>
          <div>
            {title && (
              <>
                <Styled.WhiteText>
                  <PortableTextWrapper portableText={title} />
                </Styled.WhiteText>
                <Gutter size={32} />
              </>
            )}
            {subtitle && (
              <Styled.WhiteText>
                <PortableTextWrapper portableText={subtitle} />
              </Styled.WhiteText>
            )}
          </div>
          {!success && <Gutter size={32} />}
          {success && (
            <>
              <Gutter size={24} />
              <Snackbar variant="success" isOpen text={translations.formSuccess} />
              <Gutter size={32} />
            </>
          )}
        </Styled.FormHeader>

        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <UserInformation
            {...{
              control,
              register,
              formState,
              success,
              translations,
              title,
              subtitle,
              resetSuccess,
              resetOnChange,
            }}
          />
          <BusinessInformation
            {...{
              control,
              register,
              formState,
              success,
              translations,
              resetSuccess,
              resetOnChange,
            }}
          />
        </Styled.Form>
      </Container>
    </Styled.SectionWrapper>
  );
};

export default PayLaterForm;
