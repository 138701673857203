import React from 'react';
import { Row, Col, Typography, Gutter } from '@ipf-ui/components-library';
import { TextSection as TextSectionProps } from '@web-creditea/interfaces';

type TextSectionType = Pick<
  TextSectionProps,
  'title' | 'titleFontSize' | 'titleAlignment' | 'isSectionFullWidth'
> & {
  children?: React.ReactNode;
};

const getFontSizeForTitle = (
  titleFontSize: TextSectionType['titleFontSize'],
  title: TextSectionType['title']
) => {
  switch (titleFontSize) {
    case 'H3':
      return <Typography.Header3>{title} </Typography.Header3>;
    case 'H4':
      return <Typography.Header4>{title} </Typography.Header4>;
    case 'H5':
      return <Typography.Header5>{title} </Typography.Header5>;
    default:
      return <Typography.Header2>{title} </Typography.Header2>;
  }
};

const TextSection = ({
  children,
  title,
  titleFontSize,
  titleAlignment,
  isSectionFullWidth,
}: TextSectionType) => {
  return (
    <Row center={'xs'}>
      <Col xs={4} sm={8} md={isSectionFullWidth ? 12 : 8}>
        {title && (
          <div style={{ textAlign: titleAlignment ? 'center' : 'left' }}>
            {getFontSizeForTitle(titleFontSize, title)}
            {children && <Gutter size={24} />}
          </div>
        )}
        {children}
      </Col>
    </Row>
  );
};

export default TextSection;
