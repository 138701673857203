import React from 'react';

interface FaviconProps {
  countryCode?: string;
}

const Favicon = ({ countryCode }: FaviconProps) => {
  const prefix = countryCode ? `/${countryCode}` : '';
  return (
    <>
      <link rel="apple-touch-icon" sizes="180x180" href={`${prefix}/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" href={`${prefix}/favicon-32x32.png`} sizes="32x32" />
      <link rel="icon" type="image/png" href={`${prefix}/favicon-16x16.png`} sizes="16x16" />
      <link rel="mask-icon" href={`${prefix}/safari-pinned-tab.svg`} color="#5bbad5" />
      <link rel="shortcut icon" type="image/png" href={`${prefix}/favicon.ico`} />
      <meta name="msapplication-config" content={`${prefix}/public/browserconfig.xml`} />
      <meta name="theme-color" content="#ffffff" />
    </>
  );
};

export default Favicon;
