import styled from 'styled-components';

import { Icon } from '@ipf-ui/components-library';

export const ListItem = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.crediteaColors.primary.neutral[700]};
`;

export const NewIcon = styled(Icon)`
  margin-right: 8px;
`;
