import { PayLaterTranslations } from '../../components/organisms/PayLaterForm/PayLaterForm.types';

const countryOptions = [
  { label: '(+1)', value: 'United States', id: '1' },
  { label: '(+51)', value: 'Peru', id: '2' },
  { label: '(+52)', value: 'Mexico', id: '3' },
  { label: '(+53)', value: 'Cuba', id: '4' },
  { label: '(+54)', value: 'Argentina', id: '5' },
  { label: '(+55)', value: 'Brazil', id: '6' },
  { label: '(+56)', value: 'Chile', id: '7' },
  { label: '(+57)', value: 'Colombia', id: '8' },
  { label: '(+58)', value: 'Venezuela', id: '9' },
  { label: '(+502)', value: 'Guatemala', id: '10' },
  { label: '(+509)', value: 'Haiti', id: '11' },
  { label: '(+593)', value: 'Ecuador', id: '12' },
  { label: '(+809)', value: 'Dominican Republic', id: '13' },
];
const sectorOptions = [
  { label: 'Seleccione...', value: '', id: '0' },
  { label: 'Auto', value: 'car', id: '1' },
  { label: 'Belleza y bienestar', value: 'beauty-and-wellness', id: '2' },
  { label: 'Educación y cultura', value: 'education-and-culture', id: '3' },
  { label: 'Electrónicos', value: 'electronic', id: '4' },
  { label: 'Eventos', value: 'events', id: '5' },
  { label: 'Moda y accesorios', value: 'fashion-and-accessories', id: '6' },
  { label: 'Comidas y bebidas (gourmet)', value: 'gourmet', id: '7' },
  { label: 'Salud y ejercicio', value: 'health-and-excercise', id: '8' },
  { label: 'Hogar y muebles', value: 'home-and-furniture', id: '9' },
  { label: 'Seguros', value: 'insurance', id: '10' },
  { label: 'Joyería y accesorios', value: 'jewellery-and-accessories', id: '11' },
  { label: 'Marketplaces/Retail', value: 'marketplace/retail', id: '12' },
  {
    label: 'Servicios en línea y transmisiones en vivo',
    value: 'online-services-and-live-broadcast',
    id: '13',
  },
  { label: 'Mascotas', value: 'pets', id: '14' },
  { label: 'Juguetes y videojuegos', value: 'toys-and-video-games', id: '15' },
  { label: 'Viajes', value: 'trips', id: '16' },
  { label: 'Aerolíneas', value: 'airlines', id: '17' },
  { label: 'Otros', value: 'others', id: '18' },
];
const ecommercePlatformOptions = [
  { label: 'Seleccione...', value: '', id: '0' },
  { label: 'Shopify', value: 'shopify', id: '1' },
  { label: 'WooCommerce', value: 'woocommerce', id: '2' },
  { label: 'Prestashop', value: 'prestashop', id: '3' },
  { label: 'Magento', value: 'magento', id: '4' },
  { label: 'VTEX', value: 'vtex', id: '5' },
  { label: 'Tienda Nube', value: 'cloud-store', id: '6' },
  { label: 'Wix', value: 'wix', id: '7' },
  { label: 'Propio', value: 'own', id: '8' },
  { label: 'Otro', value: 'other', id: '9' },
];

export const esTranslations: PayLaterTranslations = {
  language: 'es',
  formSuccess:
    'Gracias por acercarse a nosotros! Nos pondremos en contacto con usted lo antes posible.',
  requiredError: 'Este campo es obligatorio',
  name: {
    placeholder: 'Nombre de contacto',
  },
  position: {
    placeholder: 'Puesto',
  },
  email: {
    placeholder: 'Correo Electrónico',
    error:
      'El formato del correo electrónico es incorrecto. Proporcione un correo electrónico válido.',
  },
  phoneNumber: {
    placeholder: 'Teléfono',
    error: 'El número de teléfono no es válido. Por favor, proporcione el válido.',
  },
  campainRules: {
    placeholder:
      '<span>He leído y acepto el</span> <a href="https://documents.ipfdigital.io/mobilewallet/MX/Aviso_de_Priacidad_Terceros_y_Comercios.pdf" target="_blank" style="color: #FFF; text-decoration: underline; text-underline-offset: 3px;">Aviso de Privacidad.</a>',
    error: 'Para continuar, marque la casilla',
  },
  submitButtonLabel: 'Enviar',
  tradeName: {
    placeholder: 'Nombre de Comercio',
  },
  website: {
    placeholder: 'Sitio web',
    error:
      'Indica la dirección completa de la página web de tu empresa. Si no cuentas con página web, lamentablemente Creditea no puede atender a tu negocio.',
  },
  country: {
    label: 'País',
    options: countryOptions,
    error: 'This field is mandatory',
  },
  sector: {
    label: 'Sector',
    options: sectorOptions,
    error: 'This field is mandatory',
  },
  ecommercePlatform: {
    label: 'Platforma de E-commerce',
    options: ecommercePlatformOptions,
    error: 'This field is mandatory',
  },
};
