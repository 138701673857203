import styled, { css } from 'styled-components';
import { SectionContainerProps } from './SectionContainer.types';

type SectionWrapperProps = Pick<SectionContainerProps, 'id' | 'withGutterSpacing' | 'isPromoBar'>;

const navbarHeight = 74;
const promoBarMobileHeight = 80;
const promoBarDesktopHeight = 58;
const navbarAndPromoBarMobile = navbarHeight + promoBarMobileHeight;
const navbarAndPromoBarDesktop = navbarHeight + promoBarDesktopHeight;

export const SectionWrapper = styled.section<SectionWrapperProps>`
  ${({ isPromoBar }) => {
    return css`
      > [id] {
        margin-top: ${isPromoBar ? -`${navbarAndPromoBarMobile}` : -`${navbarHeight}`}px;
        padding-top: ${isPromoBar ? `${navbarAndPromoBarMobile}` : `${navbarHeight}`}px;
        ${({ theme }) => theme.breakpoints.sm} {
          margin-top: ${isPromoBar ? -`${navbarAndPromoBarDesktop}` : -`${navbarHeight}`}px;
          padding-top: ${isPromoBar ? `${navbarAndPromoBarDesktop}` : `${navbarHeight}`}px;
        }
      }
    `;
  }}

  ${({ withGutterSpacing }) =>
    withGutterSpacing &&
    css`
      + section {
        &:before {
          content: '';
          display: block;
          padding-top: ${withGutterSpacing}px;
        }
    `}
`;
