import { ReactNode } from 'react';
import { TableSectionDataFragment } from '@web-creditea/interfaces';
import { definedValues } from '../returnMaybe';

export interface TableProps {
  variant?: string; // 'simple' | 'styled';
  caption?: string;
  rows: {
    header?: boolean;
    cells: {
      children: ReactNode;
      header?: boolean;
      scope?: string;
      colspan?: number;
      align?: 'left' | 'right' | 'center';
      mobileLabel?: boolean;
    }[];
  }[];
}

export const tableContentMapper = (
  tableData: TableSectionDataFragment['tableData'],
  hasHeaderRow: boolean,
  hasHeaderColumn: boolean
): TableProps['rows'] => {
  if (!tableData?.rows) return [];

  const mappedTableRows = definedValues(tableData.rows).map((row, index) => {
    if (hasHeaderRow && index === 0) {
      return {
        header: true,
        cells: definedValues(
          row.cells?.map((cell) => {
            if (!cell) return;
            return { scope: 'col', children: cell };
          })
        ),
      };
    }
    return {
      cells: definedValues(
        row.cells?.map((cell, index) => {
          if (hasHeaderColumn && index === 0) {
            return { header: true, scope: 'row', children: cell };
          }
          return { children: cell };
        })
      ),
    };
  });

  return mappedTableRows;
};
