import styled from 'styled-components';

export const Footer = styled.footer`
  position: relative;
  padding: 60px 0 100px;
  background: ${({ theme }) => theme.crediteaColors.primary.neutral[100]};

  ${({ theme }) => theme.breakpoints.sm} {
    padding: 100px 0;
  }
`;

export const FooterMobile = styled.div`
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;

export const FooterDesktop = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.sm} {
    display: block;
    margin-bottom: 40px;
  }

  ${({ theme }) => theme.breakpoints.md} {
    margin-bottom: 60px;
  }
`;
