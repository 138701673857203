import React from 'react';
import { Col, LinksWithTitle, Row, Typography } from '@ipf-ui/components-library';
import { Link } from '@web-creditea/interfaces';
import NextLinkComponent from '../../atoms/NextLinkComponent/NextLinkComponent';
import { returnMaybe } from '../../../helpers';

interface DocumentsSectionProps {
  title?: string;
  documentLinks?: Link[];
}

const DocumentsSection = ({ title, documentLinks }: DocumentsSectionProps) => {
  const links = documentLinks?.map((link) => {
    const internalLink =
      link?.internalLink &&
      'slug' in link.internalLink &&
      link.internalLink.slug != null &&
      'current' in link.internalLink.slug &&
      typeof link.internalLink.slug.current === 'string'
        ? link.internalLink.slug.current
        : '';
    if (link.internalLink) {
      return {
        href: returnMaybe(`/${internalLink}`),
        title: returnMaybe(link.linkTitle),
        internalLink: true,
      };
    } else {
      return { href: returnMaybe(link.externalUrl), title: returnMaybe(link.linkTitle) };
    }
  });

  return (
    <>
      <Row>
        <Col xs={4}>
          <Typography.Header2>{title}</Typography.Header2>
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={4} sm={6} md={7}>
          <LinksWithTitle
            links={links}
            linksTarget="_blank"
            borderTop={false}
            NextLinkComponent={NextLinkComponent}
          />
        </Col>
      </Row>
    </>
  );
};

export default DocumentsSection;
