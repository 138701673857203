import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Header from '../../organisms/Header/Header';
import MobileMenu from '../../organisms/MobileMenu/MobileMenu';
import Footer from '../../organisms/Footer/Footer';
import FloatingButton from '../../molecules/FloatingButton/FloatingButton';
import { AllSettings, LocaleCode, Blog, FaqQuestion } from '@web-creditea/interfaces';
import createMenuList from '../../../helpers/createMenu';
import { getAlternates, getCanonicalUrl, getUserDevice, returnMaybe } from '../../../helpers';
import { NextSeo, SocialProfileJsonLd, FAQPageJsonLd } from 'next-seo';
import { socialMedia } from '../../../data/socialMedia';
import {
  PromoBarItemDataFragment,
  SeoGroupFragment,
  CtaButtonDataFragment,
  LinkRequiredDataFragment,
} from 'libs/interfaces/src/lib/gql/graphql';

type LayoutProps = {
  children: React.ReactNode;
  promoBarItem?: PromoBarItemDataFragment;
  title: string;
  seo?: SeoGroupFragment;
  ctaButton?: CtaButtonDataFragment;
  loginButton?: LinkRequiredDataFragment;
  articleInfo?: {
    _createdAt: Blog['_createdAt'];
    publishedDate: Blog['publishedDate'];
  };
  mode?: 'dark' | 'light';
  settings: AllSettings;
};

const Layout = ({
  articleInfo,
  children,
  promoBarItem,
  title,
  seo,
  ctaButton,
  settings,
  mode = 'light',
}: LayoutProps) => {
  const [isOpen, setOpen] = useState(false);
  const router = useRouter();
  const seoTitle = `${seo?.metaTitle ?? title} | Creditea`;
  const basePath =
    router.locale !== router.defaultLocale
      ? `${router.basePath}/${router.locale}`
      : router.basePath;

  const {
    CTAButton,
    footerMenuItems,
    socials,
    socialsTitle,
    loginButton,
    footerLegal,
    showCTAButton,
    market,
  } = settings;
  const menuList = createMenuList(footerMenuItems, settings.kubernetesNamespace);
  const userDevice = getUserDevice(market);

  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  const closeMobileMenu = () => {
    setOpen(false);
  };

  const ogImage = seo?.openGraph?.image?.asset;
  const seoImages = ogImage?.url
    ? [
        {
          url: ogImage?.url ?? '',
          alt: seo?.openGraph?.imageAlt ?? '',
          type: ogImage?.mimeType ?? '',
          height: returnMaybe(ogImage?.metadata?.dimensions?.height),
          width: returnMaybe(ogImage?.metadata?.dimensions?.width),
        },
      ]
    : [];
  const articleMetatdata = articleInfo && {
    publishedTime: String(articleInfo?.publishedDate),
  };
  const blogArticle = router.route === '/blog/[slug]';

  const canonicalURL = getCanonicalUrl(market, basePath, router.asPath);

  const baseLocale = router.basePath.split('/')[1] as keyof typeof socialMedia extends LocaleCode
    ? LocaleCode
    : unknown;
  const socialLinks = socialMedia[baseLocale];

  const prepareFaqSchema = (questions: Array<FaqQuestion | null>) => {
    questions.forEach((question) => {
      delete question?.__typename;
    });
    return questions;
  };

  return (
    <>
      <NextSeo
        noindex={returnMaybe(seo?.noIndex)}
        nofollow={returnMaybe(seo?.noFollow)}
        defaultTitle="Creaditea.com"
        title={seoTitle}
        description={returnMaybe(seo?.metaDescription)}
        canonical={seo?.overWriteCanonical ? returnMaybe(seo?.canonicalURL) : canonicalURL.href}
        openGraph={{
          title: seo?.openGraph?.title || seoTitle,
          description:
            returnMaybe(seo?.openGraph?.description) || returnMaybe(seo?.metaDescription),
          locale: router.locale,
          type: blogArticle ? 'article' : 'website',
          siteName: 'Creditea.com',
          images: seoImages,
          article: articleMetatdata,
        }}
        languageAlternates={getAlternates(returnMaybe(seo?.languageAlternates))}
        additionalMetaTags={[
          { name: 'commit-build-id', content: process.env['NEXT_PUBLIC_GIT_REV'] || 'localhost' },
          { name: 'facebook-domain-verification', content: '9pzun0zbw7gxbbm1zz227x1u6v5e0w' },
          { name: 'twitter:card', content: seo?.openGraph?.twitterCardType || 'summary' },
          { name: 'twitter:app:name:iphone', content: 'Creditea | Card and Credit Line' },
          { name: 'twitter:app:id:iphone', content: '1546903528' },
          {
            name: 'twitter:app:url:iphone',
            content: 'https://apps.apple.com/ee/app/creditea-card-and-creditline/id1546903528',
          },
          { name: 'twitter:app:name:googleplay', content: 'Creditea | Card and App' },
          { name: 'twitter:app:id:googleplay', content: 'com.ipfdigital.creditea.mw' },
          {
            name: 'twitter:app:url:googleplay',
            content: 'https://play.google.com/store/apps/details?id=com.ipfdigital.creditea.mw',
          },
          { name: 'twitter:app:country', content: baseLocale },
          {
            name: 'twitter:text:title',
            content: 'Creditea | App',
          },
        ]}
      />
      {seo?.faqSchema?.questions?.length && (
        <FAQPageJsonLd mainEntity={prepareFaqSchema(seo.faqSchema.questions)} />
      )}
      {socialLinks?.length > 0 && (
        <SocialProfileJsonLd
          type="Organization"
          name="Creditea"
          url="https://www.creditea.com/"
          sameAs={socialLinks}
        />
      )}
      <Header
        promoBarItem={promoBarItem}
        isOpen={isOpen}
        toggleMenu={toggleMenu}
        loginButton={loginButton}
        settings={settings}
        mode={mode}
      />
      <MobileMenu
        isOpenMobileMenu={isOpen}
        closeMobileMenu={closeMobileMenu}
        isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        promoBarOnlyMobile={returnMaybe(promoBarItem?.promoBarOnlyMobile)}
        settings={settings}
      />
      {children}
      <Footer
        footerLegal={footerLegal}
        menuList={menuList}
        socialList={socials}
        socialsTitle={socialsTitle}
      />
      {showCTAButton && (
        <FloatingButton
          customFloatingButton={ctaButton}
          userDevice={userDevice}
          CTAButton={CTAButton}
          kubernetesNamespace={settings.kubernetesNamespace}
        />
      )}
    </>
  );
};

export default Layout;
