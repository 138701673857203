import { useEffect } from 'react';

export const useForceHtmlLangAttribute = (lang: string) => {
  useEffect(() => {
    document.documentElement.lang = lang;

    const langObserver = new MutationObserver(() => {
      if (document.documentElement.lang !== lang) {
        document.documentElement.lang = lang;
      }
    });
    langObserver.observe(document.documentElement, {
      attributeFilter: ['lang'],
    });

    return () => {
      langObserver.disconnect();
    };
  }, [lang]);
};
