const ET_CATEGORY = 'Kategooria';
const RU_CATEGORY = 'Категория';
const LV_CATEGORY = 'Kategorija';
const LT_CATEGORY = 'Kategorija';
const ES_CATEGORY = 'Categoría';
const CZ_CATEGORY = 'Kategorie';
const EN_CATEGORY = 'Category';

export const CATEGORY = {
  ET_CATEGORY,
  RU_CATEGORY,
  LV_CATEGORY,
  LT_CATEGORY,
  ES_CATEGORY,
  CZ_CATEGORY,
  EN_CATEGORY,
};

const ET_LATEST_ARTICLES = 'Viimased postitused';
const RU_LATEST_ARTICLES = 'Последние статьи';
const LV_LATEST_ARTICLES = 'Pēdējie raksti';
const LT_LATEST_ARTICLES = 'Naujausi straipsniai';
const ES_LATEST_ARTICLES = 'Últimos artículos';
const CZ_LATEST_ARTICLES = 'Poslední články';
const EN_LATEST_ARTICLES = 'Latest articles';

export const LATEST_ARTICLES = {
  ET_LATEST_ARTICLES,
  RU_LATEST_ARTICLES,
  LV_LATEST_ARTICLES,
  LT_LATEST_ARTICLES,
  ES_LATEST_ARTICLES,
  CZ_LATEST_ARTICLES,
  EN_LATEST_ARTICLES,
};

const ET_READ_TIME = 'Lugemisaeg';
const RU_READ_TIME = 'Время на прочтение ';
const LV_READ_TIME = 'Lasīšanas laiks';
const LT_READ_TIME = 'Skaitymo laikas';
const ES_READ_TIME = 'Tiempo de lectura';
const CZ_READ_TIME = 'čtení';
const EN_READ_TIME = 'Reading time';

export const READ_TIME = {
  ET_READ_TIME,
  RU_READ_TIME,
  LV_READ_TIME,
  LT_READ_TIME,
  ES_READ_TIME,
  CZ_READ_TIME,
  EN_READ_TIME,
};

const ET_ALL_CATEGORIES = 'Kõik kategooriad';
const RU_ALL_CATEGORIES = 'Все категории';
const LV_ALL_CATEGORIES = 'Visas kategorijas';
const LT_ALL_CATEGORIES = 'Visos kategorijos';
const ES_ALL_CATEGORIES = 'Todas las categorias';
const CZ_ALL_CATEGORIES = 'Všechny kategorie';
const EN_ALL_CATEGORIES = 'All categories';

export const ALL_CATEGORIES = {
  ET_ALL_CATEGORIES,
  RU_ALL_CATEGORIES,
  LV_ALL_CATEGORIES,
  LT_ALL_CATEGORIES,
  ES_ALL_CATEGORIES,
  CZ_ALL_CATEGORIES,
  EN_ALL_CATEGORIES,
};

const ET_RELATED_ARTICLES = 'Seotud artiklid';
const RU_RELATED_ARTICLES = 'Cтатьи по теме';
const LV_RELATED_ARTICLES = 'Līdzīgie raksti';
const LT_RELATED_ARTICLES = 'Susiję straipsniai';
const ES_RELATED_ARTICLES = 'Artículos relacionados';
const CZ_RELATED_ARTICLES = 'Podobné články';
const EN_RELATED_ARTICLES = 'Related articles';

export const RELATED_ARTICLES = {
  ET_RELATED_ARTICLES,
  RU_RELATED_ARTICLES,
  LV_RELATED_ARTICLES,
  LT_RELATED_ARTICLES,
  ES_RELATED_ARTICLES,
  CZ_RELATED_ARTICLES,
  EN_RELATED_ARTICLES,
};

const ET_PREVIOUS_TEXT = 'Eelmine';
const RU_PREVIOUS_TEXT = 'Предыдущий';
const LV_PREVIOUS_TEXT = 'Iepriekšējais';
const LT_PREVIOUS_TEXT = 'Ankstesnis';
const ES_PREVIOUS_TEXT = 'Anterior';
const CZ_PREVIOUS_TEXT = 'Předchozí';
const EN_PREVIOUS_TEXT = 'Previous';

export const PREVIOUS_TEXT = {
  ET_PREVIOUS_TEXT,
  RU_PREVIOUS_TEXT,
  LV_PREVIOUS_TEXT,
  LT_PREVIOUS_TEXT,
  ES_PREVIOUS_TEXT,
  CZ_PREVIOUS_TEXT,
  EN_PREVIOUS_TEXT,
};

const ET_NEXT_TEXT = 'Järgmine';
const RU_NEXT_TEXT = 'Cледующий';
const LV_NEXT_TEXT = 'Nākamais';
const LT_NEXT_TEXT = 'Kitas';
const ES_NEXT_TEXT = 'Siguiente';
const CZ_NEXT_TEXT = 'Další';
const EN_NEXT_TEXT = 'Next';

export const NEXT_TEXT = {
  ET_NEXT_TEXT,
  RU_NEXT_TEXT,
  LV_NEXT_TEXT,
  LT_NEXT_TEXT,
  ES_NEXT_TEXT,
  CZ_NEXT_TEXT,
  EN_NEXT_TEXT,
};

const ET_SHARE_THE_ARTICLE = 'Jaga artiklit';
const RU_SHARE_THE_ARTICLE = 'Поделиться статьей';
const LV_SHARE_THE_ARTICLE = 'Dalīties ar rakstu';
const LT_SHARE_THE_ARTICLE = 'Dalintis straipsniu';
const ES_SHARE_THE_ARTICLE = 'Compartir el artículo';
const CZ_SHARE_THE_ARTICLE = 'Sdílet článek';
const EN_SHARE_THE_ARTICLE = 'Share the article';

export const SHARE_THE_ARTICLE = {
  ET_SHARE_THE_ARTICLE,
  RU_SHARE_THE_ARTICLE,
  LV_SHARE_THE_ARTICLE,
  LT_SHARE_THE_ARTICLE,
  ES_SHARE_THE_ARTICLE,
  CZ_SHARE_THE_ARTICLE,
  EN_SHARE_THE_ARTICLE,
};

export const TRANSLATIONS = {
  CATEGORY,
  LATEST_ARTICLES,
  READ_TIME,
  ALL_CATEGORIES,
  RELATED_ARTICLES,
  PREVIOUS_TEXT,
  NEXT_TEXT,
  SHARE_THE_ARTICLE,
};
