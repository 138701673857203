import React from 'react';
import Link from '../../atoms/Link/Link';

import * as Styled from './SideMenu.style';
import { useRouterPath } from '../../../helpers/useRouterPath';
type SideMenuProps = {
  list?: { name?: string; href?: string }[];
};

const SideMenu = ({ list }: SideMenuProps) => {
  const { pathName } = useRouterPath();

  if (!list) return null;
  return (
    <Styled.SideMenu>
      {list.map(({ name, href }, index) => {
        if (!name || !href) return;
        return (
          <Styled.SideMenuItem key={index}>
            <Link
              scroll={false}
              href={href}
              name={name}
              variant={pathName?.includes(href) ? 'bold' : 'light'}
              mode={pathName?.includes(href) ? 'darkFaq' : 'faq'}
            />
          </Styled.SideMenuItem>
        );
      })}
    </Styled.SideMenu>
  );
};

export default SideMenu;
