import styled from 'styled-components';

export const ResponsiveGutter = styled.div`
  height: ${({ theme }) => theme.space[48]};
  ${({ theme }) => theme.breakpoints.md} {
    height: ${({ theme }) => theme.space[60]};
  }
`;

export const CarouselWrapper = styled.div`
  /* Container widths
xs: '100%',
sm: '100%',
md: '856px',
lg: '1120px',
xl: '1120px', */
  ${({ theme }) => theme.breakpoints.md} {
    padding-left: calc((100vw - 856px) / 2);
  }
  ${({ theme }) => theme.breakpoints.lg} {
    padding-left: calc((100vw - 1120px) / 2);
  }
`;
