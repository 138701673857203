import React, { useEffect, useState } from 'react';
import { useScroll } from '@ipf-ui/components-library';

interface Props {
  children: React.ReactNode;
}

interface Scroll {
  y?: number;
  direction?: 'up' | 'down';
}

const HideOnScroll = ({ children }: Props) => {
  const scroll: Scroll = useScroll();
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (scroll.direction === 'up' && hide) {
      setHide(false);
    } else if (scroll.direction === 'down' && !hide) {
      setHide(true);
    }
  }, [scroll.direction, hide]);

  return !hide ? <div>{children}</div> : null;
};

export default HideOnScroll;
