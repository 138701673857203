import { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { GTM_ID, pageview } from '../lib/gtm';
import { createGlobalStyle, StyleSheetManager } from 'styled-components';
import { crediteaGlobalStyle, ThemeProvider as ClThemeProvider } from '@ipf-ui/components-library';
import { appWithTranslation } from 'next-i18next';
import { Rubik } from '@next/font/google';
import { shouldForwardProp } from '@web-creditea/utils';
import { GoogleTagManager } from '@next/third-parties/google';

const GlobalStyle = createGlobalStyle`${crediteaGlobalStyle}`;

const rubik = Rubik({
  subsets: ['latin', 'cyrillic'],
  display: 'swap',
});

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  return (
    <ClThemeProvider>
      <StyleSheetManager {...{ shouldForwardProp }}>
        <GlobalStyle />
        <main className={rubik.className}>
          <Component {...pageProps} />
          <GoogleTagManager gtmId={GTM_ID} />
        </main>
      </StyleSheetManager>
    </ClThemeProvider>
  );
};

export default appWithTranslation(App);
