import { AllSettings } from '@web-creditea/interfaces';
import { swapSubdomain } from '../createMenu';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetAllSettingsDocument,
  GetAllSettingsQuery,
  GetAllSettingsQueryVariables,
} from 'libs/interfaces/src/lib/gql/graphql';
import { Market } from '@web-creditea/interfaces';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPageTypes = (pages: any[]) => {
  const productionPage = pages?.find((page: { _id: string }) => !page._id.startsWith('drafts'));
  const previewPage = pages?.find((page: { _id: string }) => page._id.startsWith('drafts'));

  return [productionPage, previewPage];
};

export const getAllSettings = async (
  client: ApolloClient<NormalizedCacheObject>,
  market: Market,
  language: string,
  preview: boolean
): Promise<AllSettings> => {
  const { data } = await client.query<GetAllSettingsQuery, GetAllSettingsQueryVariables>({
    query: GetAllSettingsDocument,
    variables: { market, language },
    fetchPolicy: 'no-cache',
  });

  const { allMarketSettings, allHeader, allFooter } = data;
  const [productionSettings, previewSettings] = getPageTypes(allMarketSettings);
  const [productionHeader, previewHeader] = getPageTypes(allHeader);
  const [productionFooter, previewFooter] = getPageTypes(allFooter);

  const allSettings = {
    settings: preview && previewSettings ? previewSettings : productionSettings,
    header: preview && previewHeader ? previewHeader : productionHeader,
    footer: preview && previewFooter ? previewFooter : productionFooter,
  };

  const { settings, header, footer } = allSettings;

  const loginButtonExternalUrl = swapSubdomain(
    header.loginButton?.externalUrl,
    process.env['CONFIG_PROFILE']
  );
  const loginButton = { ...header.loginButton, externalUrl: loginButtonExternalUrl ?? null };

  const applyButtonExternalUrl = swapSubdomain(
    header.applyButton?.externalUrl,
    process.env['CONFIG_PROFILE']
  );
  const applyButton = { ...header.applyButton, externalUrl: applyButtonExternalUrl ?? null };

  return {
    headerMenuItems: header.headerNavigations,
    footerMenuItems: footer.footerNavigations,
    footerLegal: footer.legal,
    socials: footer.socialList,
    socialsTitle: footer.title,
    languagePicker: settings.languagePicker,
    notFoundPage: settings.notFoundPage,
    CTAButton: settings.other.CTAButton,
    downloadAppButtonsTitle: settings.other.downloadAppButtonsTitle,
    hideLanguagePicker: header.hideLanguagePicker,
    whatsApp: header.whatsApp,
    phoneButton: header.phoneButton,
    loginButton,
    applyButton,
    showCTAButton: settings.other.showCTAButton,
    market,
    kubernetesNamespace: process.env['CONFIG_PROFILE'] || '',
  };
};
