import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.lg} {
    display: none;
  }
`;

export const Holder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.crediteaColors.primary.neutral[50]};
  box-shadow: 0 0 12px rgba(0, 0, 0, 20%);
  padding: 15px;
`;
