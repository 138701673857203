import React, { useState, useEffect } from 'react';
import loanFormData from '../../../data/products/creditlineCZ.json';
import { LoanForm, Slider, currencyFormatCZ } from '@ipf-ui/components-library';
import { useRouter } from 'next/router';

import { LoanFormWrapperProps } from '@web-creditea/interfaces';
import { swapSubdomain } from '../../../helpers/createMenu';

const LoanFormWrapperCZ = ({
  sliderVariant,
  loanFormTitle,
  loanFormDescription,
  interestRate,
  amountSlider,
  periodSlider,
  termsDescription,
  title,
  description,
  refundsLabel,
  buttonText,
  buttonUrl,
  anchorTarget,
  colorMode,
  market,
  kubernetesNamespace,
}: LoanFormWrapperProps) => {
  const [amountInputValue, setAmountInputValue] = useState<number>(amountSlider?.value);
  const [periodInputValue, setPeriodInputValue] = useState<number>(periodSlider?.value);
  const [currentLoanIndex, setCurrentLoanIndex] = useState(0);

  const router = useRouter();
  const sliders = [
    {
      ...amountSlider,
      infoPill: amountSlider.hasInfoPill && amountSlider.infoPill,
      value: amountInputValue,
      type: Slider.Types.Labeled,
      format: currencyFormatCZ,
      onChange: (value: number) => setAmountInputValue(value),
    },
    {
      ...periodSlider,
      infoPill: periodSlider.hasInfoPill && periodSlider.infoPill,
      value: periodInputValue,
      type: Slider.Types.Labeled,
      onChange: (value: number) => setPeriodInputValue(value),
    },
  ];
  const applyButton = `${swapSubdomain(
    buttonUrl,
    kubernetesNamespace
  )}?principal=${amountInputValue}&maturity=${periodInputValue}&channel=web&lang=${router.locale}`;

  const loanFormProps = {
    loanFormTitle,
    loanFormDescription,
    format: currencyFormatCZ,
    sliders,
    interestRate,
    termsDescription: termsDescription,
    callToAction: {
      title,
      description,
      refundsLabel,
      buttonText,
      colorMode,
      buttonAction: () => window.open(applyButton, anchorTarget === false ? '_blank' : '_self'),
    },
  };

  useEffect(() => {
    const index = loanFormData.findIndex((loanVariant) => {
      return (
        loanVariant.principal === amountInputValue * 100 &&
        loanVariant.maturityPeriods === periodInputValue
      );
    });
    if (index > -1) {
      setCurrentLoanIndex(index);
    }
  }, [amountInputValue, periodInputValue]);

  if (sliderVariant !== market.substring(8)) return null;
  return (
    <LoanForm
      {...loanFormProps}
      market={sliderVariant}
      amountValue={amountInputValue}
      paymentValue={loanFormData[currentLoanIndex].fullMaturityMmp / 100}
      periodValue={periodInputValue}
      totalValue={Math.round(loanFormData[currentLoanIndex].totalSumToBePaid / 100)}
      fixedInterest={
        (loanFormData[currentLoanIndex].totalSumToBePaid - amountInputValue * 100) / 100
      }
    />
  );
};

export default LoanFormWrapperCZ;
