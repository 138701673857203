import React from 'react';

import { Container } from '@ipf-ui/components-library';

import { SectionContainerProps } from './SectionContainer.types';
import * as Styled from './SectionContainer.style';

export const SectionContainer = ({
  children,
  id,
  fluid = false,
  withoutPadding = false,
  withGutterSpacing,
  isPromoBar = false,
  fullWidthOnMobile = false,
}: SectionContainerProps) => {
  return (
    <Styled.SectionWrapper withGutterSpacing={withGutterSpacing} isPromoBar={isPromoBar}>
      <Container
        withoutPadding={withoutPadding}
        fluid={fluid}
        id={id}
        fullWidthOnMobile={fullWidthOnMobile}
      >
        {children}
      </Container>
    </Styled.SectionWrapper>
  );
};

export default SectionContainer;
