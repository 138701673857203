import { Icon, Col, theme, PromoBar } from '@ipf-ui/components-library';

import Logo from '../../atoms/Logo/Logo';
import DesktopMenu from '../../organisms/Menu/Menu';

import { createSingleLink } from '../../../helpers/createMenu';

import * as Styled from './Header.style';
import React, { useEffect, useState } from 'react';
import { AllSettings, NavItem, PromoBarItemDataFragment } from '@web-creditea/interfaces';
import NextLink from 'next/link';
import Link from 'next/link';
import { returnMaybe } from '../../../helpers';

interface HeaderProps {
  isOpen: boolean;
  isAppButtonShown?: boolean;
  toggleMenu: () => void;
  loginButton: NavItem;
  mode: 'dark' | 'light';
  settings: AllSettings;
  promoBarItem?: PromoBarItemDataFragment;
}

const Header = ({
  toggleMenu,
  isOpen,
  isAppButtonShown = false,
  loginButton,
  mode,
  settings,
  promoBarItem,
}: HeaderProps) => {
  const { link: loginButtonLink, external: loginButtonExternal } = createSingleLink(
    loginButton?.linkTitle ?? 'Log in',
    loginButton
  );
  const showLoginButton = loginButton?.externalUrl || loginButton?.internalLink;

  const [scrollTop, setScrollTop] = useState(0);
  const isDarkMode = mode === 'dark' && !isOpen && scrollTop < 1;

  useEffect(() => {
    function handleScroll() {
      setScrollTop(window.scrollY);
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Styled.Header
      id="header-menu"
      promoBarOnlyMobile={returnMaybe(promoBarItem?.promoBarOnlyMobile)}
      hasPromoBar={Boolean(promoBarItem?.showPromoBar)}
      isOpen={isOpen}
      offset={scrollTop}
    >
      {promoBarItem?.showPromoBar && (
        <PromoBar
          promoBarItem={promoBarItem?.promoBarItem}
          NextLink={Link}
          promoBarAlignment={promoBarItem?.promoBarAlignment}
          promoBarOnlyMobile={promoBarItem?.promoBarOnlyMobile}
        />
      )}
      <Styled.BigDesktopPadding>
        <Styled.HeaderContainer>
          <Styled.RowWrapper between={'xs'} middle={'xs'}>
            <Styled.LogoWrapper>
              <Logo variant="full">
                <Icon
                  name={isDarkMode ? 'LogoCrediteaDarkMode' : 'LogoCrediteaLightMode'}
                  title={'Logo_Creditea'}
                  data-testid="LogoCrediteaRedirect"
                  width={134}
                  height={24}
                />
              </Logo>
            </Styled.LogoWrapper>
            <DesktopMenu
              showButton={isAppButtonShown && scrollTop !== undefined && scrollTop > 1}
              settings={settings}
              mode={isDarkMode ? 'dark' : 'light'}
            />
            <Styled.MobileStylesWrapper>
              {loginButtonLink && showLoginButton && !isOpen && (
                <Col paddingTop={1} paddingLeft={1}>
                  {loginButtonExternal ? (
                    <a
                      href={loginButtonLink.href}
                      aria-label={'Login button'}
                      target={!loginButtonLink.anchorTarget ? '_blank' : '_self'}
                    >
                      <Icon
                        name="Account"
                        fillColor={
                          isDarkMode
                            ? theme.crediteaColors.primary.neutral[50]
                            : theme.crediteaColors.primary.neutral[800]
                        }
                      />
                    </a>
                  ) : (
                    <NextLink href={loginButtonLink.href} aria-label={'Login button'}>
                      <Icon
                        name="Account"
                        fillColor={
                          isDarkMode
                            ? theme.crediteaColors.primary.neutral[50]
                            : theme.crediteaColors.primary.neutral[800]
                        }
                      />
                    </NextLink>
                  )}
                </Col>
              )}
              <Col paddingTop={1} paddingLeft={16}>
                <Styled.MenuButtonWrapper onClick={() => toggleMenu()} aria-label={'Menu'}>
                  {isOpen ? (
                    <Icon
                      name="ButtonClose"
                      width={26}
                      height={26}
                      fillColor={theme.crediteaColors.primary.neutral[800]}
                    />
                  ) : (
                    <Icon
                      name="ButtonMenu"
                      width={26}
                      height={26}
                      fillColor={
                        isDarkMode
                          ? theme.crediteaColors.primary.neutral[50]
                          : theme.crediteaColors.primary.neutral[800]
                      }
                    />
                  )}
                </Styled.MenuButtonWrapper>
              </Col>
            </Styled.MobileStylesWrapper>
          </Styled.RowWrapper>
        </Styled.HeaderContainer>
      </Styled.BigDesktopPadding>
    </Styled.Header>
  );
};

export default Header;
