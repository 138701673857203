import type { SubemnuItem, MenuItems, MenuList, Link } from '@web-creditea/interfaces';

export default function createMenuList(
  menu: Array<MenuItems>,
  kubernetesNamespace?: string
): Array<MenuList> {
  return menu?.map((item) =>
    item.submenu
      ? createSubmenu(
          item.text,
          item.navigationItem?.linkTitle,
          item.subMenuNavigationItems,
          kubernetesNamespace
        )
      : createSingleLink(item.text, item.navigationItem, kubernetesNamespace)
  );
}

export const createSubmenu = (
  name: string,
  title: string,
  submenuNavigation?: Array<SubemnuItem>,
  kubernetesNamespace?: string
): MenuList => {
  const submenu = submenuNavigation?.map((submenuItems) => {
    const navItem = submenuItems.navigationItemUrl;
    const externalLink = swapSubdomain(navItem?.externalUrl, kubernetesNamespace);
    const internalLink = navItem?.internalLink?.slug ? navItem.internalLink.slug.current : '';
    const anchorTag = navItem?.anchorID ? `#${navItem.anchorID}` : '';

    const link = externalLink ? externalLink : `/${internalLink}${anchorTag}`;
    return {
      name: navItem.linkTitle,
      href: link,
      external: !!externalLink,
      anchorTarget: externalLink ? navItem.anchorTarget : false,
    };
  });

  return {
    submenu: true,
    name: name,
    title: title,
    list: submenu,
  };
};

export const createSingleLink = (
  title?: string,
  navigationItem?: Link,
  kubernetesNamespace?: string
): MenuList => {
  const externalLink = swapSubdomain(navigationItem?.externalUrl, kubernetesNamespace);
  const internalLink =
    navigationItem?.internalLink &&
    'slug' in navigationItem.internalLink &&
    navigationItem.internalLink.slug != null &&
    'current' in navigationItem.internalLink.slug &&
    typeof navigationItem.internalLink.slug.current === 'string'
      ? navigationItem.internalLink.slug.current
      : '';
  const anchorTag = navigationItem?.anchorID ? `#${navigationItem.anchorID}` : '';

  const link = externalLink ? externalLink : `/${internalLink}${anchorTag}`;
  return {
    submenu: false,
    name: navigationItem?.linkTitle ?? '',
    title: title ?? '',
    external: !!externalLink,
    link: {
      name: navigationItem?.linkTitle ?? title ?? '',
      href: link,
      anchorTarget: externalLink ? Boolean(navigationItem?.anchorTarget) : false,
    },
  };
};

export const swapSubdomain = (url?: string | null, kubernetesNamespace?: string | null) => {
  if (kubernetesNamespace === 'prod' || !kubernetesNamespace) return url;
  if (kubernetesNamespace === 'uat' && url) {
    return url.replace('www', 'uat01');
  }
  if (kubernetesNamespace && url) {
    return url.replace('www', kubernetesNamespace);
  }
  return;
};

export const swapSubdomainEcommerce = (url?: string, kubernetesNamespace?: string) => {
  if (kubernetesNamespace === 'prod' || !kubernetesNamespace) return url;
  if (kubernetesNamespace === 'uat' && url) {
    return url.replace('ecommerce', 'uat01.ecommerce');
  }
  if (kubernetesNamespace && url) {
    return url.replace('ecommerce', `${kubernetesNamespace}.ecommerce`);
  }
  return;
};

export const getCountryFlag = (market: string) => {
  switch (market) {
    case 'crediteaEE':
      return 'FlagEstonia';
    case 'crediteaLT':
      return 'FlagLithuania';
    case 'crediteaLV':
      return 'FlagLatvia';
    case 'crediteaMX':
      return 'FlagMexico';
    default:
      return 'FlagGlobal';
  }
};
