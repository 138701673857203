import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  Container,
  Hero,
  useIsScreenSize,
  Row,
  Col,
  theme,
  Gutter,
} from '@ipf-ui/components-library';
import { mapIntoContentProps, definedValues, renderSections, returnMaybe } from '../../../helpers';
import Layout from '../../templates/Layout/Layout';
import SideMenu from '../../molecules/SideMenu/SideMenu';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import {
  FaqPageTranslations,
  HeroSectionFragment,
  FaqAdditionalSectionsFragment,
  SeoGroupFragment,
  AllSettings,
} from '@web-creditea/interfaces';

interface HelpLayoutProps {
  children: ReactNode;
  constants: {
    language: string;
    market: string;
    translations: FaqPageTranslations & { faqTranslation: string };
  };
  helpCategories?: { name?: string; href?: string }[];
  heroSection: HeroSectionFragment;
  additionalSections: Array<FaqAdditionalSectionsFragment> | null;
  settings: AllSettings;
  seo?: SeoGroupFragment;
  showSideMenu: boolean;
  title?: string;
}

const HelpLayout = ({
  children,
  helpCategories,
  heroSection,
  seo,
  showSideMenu,
  title,
  constants,
  additionalSections,
  settings,
}: HelpLayoutProps) => {
  const { isScreenAboveSM, isScreenDesktop } = useIsScreenSize();
  const [searchInput, setSearchInput] = useState('');
  const {
    translations: { faqTranslation },
  } = constants;

  const router = useRouter();
  const colorMode =
    heroSection?.backgroundColor?.value === theme.crediteaColors.primary.brandBlue[600]
      ? 'dark'
      : 'light';

  const debouncedRedirectToResults = useRef(
    debounce(async (searchInput) => {
      const searchQuery = searchInput.split(' ').join('+');
      router.push(`/${faqTranslation}/results?searchQuery=${searchQuery}`, undefined, {
        scroll: false,
      });
    }, 500)
  ).current;

  useEffect(() => {
    if (searchInput.length > 0) {
      debouncedRedirectToResults(searchInput);
    }
    return () => {
      debouncedRedirectToResults.cancel();
    };
  }, [searchInput, debouncedRedirectToResults]);

  return (
    <Layout title={title ?? 'Help'} settings={settings} seo={seo} mode={colorMode}>
      {heroSection && (
        <Hero
          backgroundColor={
            heroSection?.backgroundColor?.value ?? theme.crediteaColors.primary.brandBlue[600]
          }
          content={mapIntoContentProps({
            content: returnMaybe(definedValues(heroSection.content)),
            searchInput,
            setSearchInput,
          })}
          topPadding={isScreenAboveSM ? 100 : undefined}
          bottomPadding={isScreenAboveSM ? 100 : 60}
          align="middle"
          rightBottomCornerRounded={returnMaybe(heroSection.rightBottomCornerRounded)}
          titleColor={returnMaybe(heroSection.titleColor?.value)}
          textColor={returnMaybe(heroSection.textColor?.value)}
        />
      )}
      <Container>
        <Row>
          {isScreenDesktop && showSideMenu && (
            <Col lg={4}>
              <SideMenu list={helpCategories} />
            </Col>
          )}
          <Col xs={4} sm={8} lg={showSideMenu ? 8 : 12}>
            {children}
          </Col>
        </Row>
      </Container>
      <Gutter size={100} sizeMobile={60} />
      {additionalSections?.length && renderSections(additionalSections)}
    </Layout>
  );
};

export default HelpLayout;
