import React from 'react';
import { Container } from '@ipf-ui/components-library';

import Copyrights from '../../molecules/Copyrights/Copyrights';
import FooterNavMobile from '../../molecules/FooterNavMobile/FooterNavMobile';
import FooterNavDesktop from '../../molecules/FooterNavDesktop/FooterNavDesktop';
import { FooterProps } from './Footer.types';
import * as Styled from './Footer.style';

const Footer = ({ footerLegal, menuList, socialList, socialsTitle }: FooterProps) => {
  return (
    <Styled.Footer id="footer-menu" data-testid="footer">
      <Container>
        <Styled.FooterDesktop>
          <FooterNavDesktop
            menuList={menuList}
            socialList={socialList}
            socialsTitle={socialsTitle}
          />
        </Styled.FooterDesktop>
        <Styled.FooterMobile>
          <FooterNavMobile
            menuList={menuList}
            socialList={socialList}
            socialsTitle={socialsTitle}
          />
        </Styled.FooterMobile>
        <Copyrights footerLegal={footerLegal} />
      </Container>
    </Styled.Footer>
  );
};

export default Footer;
