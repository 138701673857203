import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

export const Box = styled.div`
  padding: 3rem;
  border-radius: 3px;
  -webkit-box-shadow: 0px 8px 40px 8px rgba(0, 20, 135, 0.15);
  -moz-box-shadow: 0px 8px 40px 8px rgba(0, 20, 135, 0.15);
  box-shadow: 0px 8px 40px 8px rgba(0, 20, 135, 0.15);
  text-align: center;
`;

export const Note = styled.div`
  text-align: center;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
