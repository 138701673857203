import React, { useCallback, useMemo } from 'react';
import { Button, DownloadAppButtons } from '@ipf-ui/components-library';

import * as Styled from './FloatingButton.style';
import HideOnScroll from '../HideOnScroll/HideOnScroll';
import { isIOS } from 'react-device-detect';
import { returnMaybe, swapSubdomain } from '@web-creditea/utils';

import { CtaButtonDataFragment, UserDevice } from '@web-creditea/interfaces';

interface FloatingButtonProps {
  customFloatingButton?: CtaButtonDataFragment;
  userDevice: UserDevice;
  CTAButton: CtaButtonDataFragment;
  kubernetesNamespace?: string | null;
}

const FloatingButton = ({
  customFloatingButton,
  CTAButton,
  userDevice,
  kubernetesNamespace,
}: FloatingButtonProps) => {
  const { appLinks } = userDevice;
  const customFloatingButtonExternalUrl = swapSubdomain(
    customFloatingButton?.link,
    kubernetesNamespace
  );
  const customFloatingButtonWithSwapSubdomain = {
    ...customFloatingButton,
    link: returnMaybe(customFloatingButtonExternalUrl),
  };

  const CTAButtonExternalUrl = swapSubdomain(CTAButton?.link, kubernetesNamespace);
  const CTAButtonWithSwapSubdomain = { ...CTAButton, link: returnMaybe(CTAButtonExternalUrl) };

  const properAnchorTarget = () => {
    if (customFloatingButton?.link) {
      return customFloatingButton?.anchorTarget ? '_self' : '_blank';
    }
    if (CTAButton.link) {
      return CTAButton.anchorTarget ? '_self' : '_blank';
    }
    return '_self';
  };

  const anchorTargetForDAB = () => {
    if (customFloatingButton?.title) {
      return customFloatingButton?.anchorTarget ? '_self' : '_blank';
    } else return CTAButton.anchorTarget ? '_self' : '_blank';
  };

  const handleButtonClick = useCallback(() => {
    window.open(
      customFloatingButtonWithSwapSubdomain?.link || CTAButtonWithSwapSubdomain?.link,
      properAnchorTarget()
    );
  }, [customFloatingButton, CTAButton]);

  const handleButtonClickWithId = useCallback(() => {
    window.open(userDevice?.deviceAppLink, anchorTargetForDAB());
  }, [userDevice]);

  const renderButton = useMemo(() => {
    if (customFloatingButton?.title && !customFloatingButton?.link) {
      return (
        <DownloadAppButtons
          iconVariant={'dark'}
          appLinks={appLinks}
          buttonId={isIOS ? 'apple-app-store' : 'google-play-store'}
          buttonTitle={customFloatingButton?.title}
          showMobileButton={true}
          handleButtonClick={handleButtonClickWithId}
          size={'small'}
        />
      );
    }
    if (customFloatingButton?.link || CTAButton.link) {
      return (
        <Button size="small" variant="primary" onClick={handleButtonClick} autoWidth={'xs'}>
          {customFloatingButton?.title || CTAButton.title}
        </Button>
      );
    } else {
      return (
        <DownloadAppButtons
          iconVariant={'dark'}
          appLinks={{
            iOS: appLinks.iOS,
            android: appLinks.android,
          }}
          buttonId={isIOS ? 'apple-app-store' : 'google-play-store'}
          buttonTitle={returnMaybe(CTAButton.title)}
          showMobileButton={true}
          handleButtonClick={handleButtonClickWithId}
          size={'small'}
        />
      );
    }
  }, [
    CTAButton.link,
    CTAButton.title,
    CTAButton.anchorTarget,
    appLinks.android,
    appLinks.iOS,
    customFloatingButton?.link,
    customFloatingButton?.title,
    customFloatingButton?.anchorTarget,
    handleButtonClick,
    handleButtonClickWithId,
  ]);

  return (
    <Styled.Container>
      <HideOnScroll>
        <Styled.Holder>{renderButton}</Styled.Holder>
      </HideOnScroll>
    </Styled.Container>
  );
};

export default FloatingButton;
