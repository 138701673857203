import {
  hasRequiredFields,
  isEmail,
  maxLength,
  isNumber,
  isBoolean,
  checkOptionalFields,
  phoneValidator,
} from './validators';
import {
  AUTH_ERROR,
  BAD_REQUEST,
  ENVIRONMENTS,
  ERROR,
  LeadGatheringRequestBody,
  LeadGatheringResponseBody,
  MARKET_COUNTRY_CODES,
  ResponseData,
  SERVER_ERROR,
  SUCCESS,
} from '@web-creditea/interfaces';
import { NextApiRequest, NextApiResponse } from 'next';
import {
  APP_LOCALHOST,
  FRONTEND_HOST_URL,
  SIT_PREVIEW_DOMAIN,
  TESTING_PREVIEW_DOMAIN,
  UAT_PREVIEW_DOMAIN,
} from '../../data';
import { isEmpty } from 'lodash';

export const leadGatheringRequest = async (
  req: NextApiRequest,
  res: NextApiResponse<ResponseData>,
  kubernetesNamespace?: string
) => {
  const { body, method } = req;
  const requiredFields = hasRequiredFields<LeadGatheringRequestBody>({
    body,
    fields: [
      'country',
      'campaign',
      'firstName',
      'lastName',
      'email',
      'consent1Value',
      'consent1Name',
      'consent1Version',
    ],
  });
  checkOptionalFields(body, [
    {
      name: 'brand',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 50,
        },
      },
    },
    {
      name: 'consent2Value',
      validators: [isBoolean, maxLength],
      args: {
        maxLength: {
          length: 5,
        },
      },
    },
    {
      name: 'consent3Value',
      validators: [isBoolean, maxLength],
      args: {
        maxLength: {
          length: 5,
        },
      },
    },
    {
      name: 'consent2Version',
      validators: [maxLength, isNumber],
      args: {
        maxLength: {
          length: 50,
        },
      },
    },
    {
      name: 'consent3Version',
      validators: [maxLength, isNumber],
      args: {
        maxLength: {
          length: 50,
        },
      },
    },
    {
      name: 'consent2Name',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 50,
        },
      },
    },
    {
      name: 'consent3Name',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 50,
        },
      },
    },
    {
      name: 'shortText1',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 100,
        },
      },
    },
    {
      name: 'shortText2',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 100,
        },
      },
    },
    {
      name: 'shortText3',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 100,
        },
      },
    },
    {
      name: 'longText',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 2000,
        },
      },
    },
    {
      name: 'sourceURL',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 500,
        },
      },
    },
    {
      name: 'formURL',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 500,
        },
      },
    },
    {
      name: 'utmSource',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 100,
        },
      },
    },
    {
      name: 'utmMedium',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 100,
        },
      },
    },
    {
      name: 'utmCampaign',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 100,
        },
      },
    },
    {
      name: 'utmContent',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 100,
        },
      },
    },
    {
      name: 'utmTerm',
      validators: [maxLength],
      args: {
        maxLength: {
          length: 100,
        },
      },
    },
  ]);

  if (method === 'POST') {
    if (requiredFields) {
      try {
        const {
          country,
          campaign,
          firstName,
          lastName,
          email,
          phone,
          consent1Value,
          consent1Name,
          consent1Version,
        } = body as LeadGatheringRequestBody;
        maxLength({
          length: 2,
          fieldName: 'country',
          value: country,
        });
        maxLength({
          length: 200,
          fieldName: 'campaign',
          value: campaign,
        });
        maxLength({
          length: 100,
          fieldName: 'firstName',
          value: firstName,
        });
        maxLength({
          length: 100,
          fieldName: 'lastName',
          value: lastName,
        });
        maxLength({
          length: 254,
          fieldName: 'email',
          value: email,
        });
        isEmail({
          fieldName: 'email',
          value: email,
        });
        maxLength({
          length: 15,
          fieldName: 'consent1Value',
          value: consent1Value.toString(),
        });
        isBoolean({
          fieldName: 'consent1Value',
          value: consent1Value,
        });
        maxLength({
          length: 50,
          fieldName: 'consent1Name',
          value: consent1Name,
        });
        maxLength({
          length: 50,
          fieldName: 'consent1Version',
          value: consent1Version.toString(),
        });
        isNumber({
          fieldName: 'consent1Version',
          value: consent1Version,
        });

        if (phone) {
          checkOptionalFields(body, [phoneValidator(country)]);
        }

        const response = await fetch(getLeadGatheringUrl(kubernetesNamespace as string), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        if (!response.ok) {
          console.error(response.statusText);
          throw new Error('Poc response error');
        }
        const { logID } = (await response.json()) as LeadGatheringResponseBody;
        return res.status(200).json({ id: logID, status: SUCCESS });
      } catch (error) {
        console.error('Lead Gathering error:', error);
        return res.status(500).json({ status: ERROR, message: SERVER_ERROR });
      }
    } else {
      return res.status(400).json({ status: ERROR, message: BAD_REQUEST });
    }
  } else {
    return res.status(400).json({ status: ERROR, message: BAD_REQUEST });
  }
};

export const authMiddleware = (req: NextApiRequest) => {
  const { headers } = req;
  const currentHost = isEmpty(headers.host)
    ? APP_LOCALHOST
    : `${headers['x-forwarded-proto']}://${headers.host}`;
  const hostsAllowedDev = [
    APP_LOCALHOST,
    TESTING_PREVIEW_DOMAIN,
    SIT_PREVIEW_DOMAIN,
    UAT_PREVIEW_DOMAIN,
    ...generateMarketUrls(),
  ];

  if (!hostsAllowedDev.includes(currentHost)) {
    throw new Error(AUTH_ERROR);
  }

  return true;
};

const getLeadGatheringUrl = (env: string): URL => {
  const baseUrl = 'https://cloud.email.creditea.com';
  let path: string;
  switch (env) {
    case 'prod':
      path = 'lgw';
      break;
    case 'uat':
      path = 'lgw-uat';
      break;
    case 'sit':
      path = 'lgw-sit';
      break;
    default:
      path = 'lead-gathering-poc';
  }

  return new URL(path, baseUrl);
};

export function generateMarketUrls() {
  const addresses = [];
  for (const marketCode in MARKET_COUNTRY_CODES) {
    for (const env in ENVIRONMENTS) {
      addresses.push(FRONTEND_HOST_URL.replace('MARKET', marketCode).replace('ENV', env));
    }
  }
  return addresses;
}
