import styled from 'styled-components';

export const BreakLine = styled.br`
  margin-top: 24px;
`;

export const UnderlineWrapper = styled.span`
  position: relative;
  display: inline-block;
  z-index: 1;

  svg {
    position: absolute;
    left: -5px;
    right: -5px;
    top: 0.7em;
    width: calc(100% + 10px);
    height: 0.7em;
    z-index: -1;
  }
`;
