import { PayLaterTranslations } from '../../components/organisms/PayLaterForm/PayLaterForm.types';

const countryOptions = [
  { label: '(+1)', value: 'United States', id: '1' },
  { label: '(+36)', value: 'Hungary', id: '2' },
  { label: '(+40)', value: 'Romania', id: '3' },
  { label: '(+44)', value: 'United Kingdom', id: '4' },
  { label: '(+48)', value: 'Poland', id: '5' },
  { label: '(+61)', value: 'Australia', id: '6' },
  { label: '(+370)', value: 'Lithuania', id: '7' },
  { label: '(+371)', value: 'Latvia', id: '8' },
  { label: '(+372)', value: 'Estonia', id: '9' },
  { label: '(+420)', value: 'Czech Republic', id: '10' },
];
const sectorOptions = [
  { label: 'Select...', value: '', id: '0' },
  { label: 'Car', value: 'car', id: '1' },
  { label: 'Beauty and wellness', value: 'beauty-and-wellness', id: '2' },
  { label: 'Education and culture', value: 'education-and-culture', id: '3' },
  { label: 'Electronic', value: 'electronic', id: '4' },
  { label: 'Events', value: 'events', id: '5' },
  { label: 'Fashion and accessories', value: 'fashion-and-accessories', id: '6' },
  { label: 'Food and drinks (gourmet)', value: 'gourmet', id: '7' },
  { label: 'Health and excercise', value: 'health-and-excercise', id: '8' },
  { label: 'Home and furniture', value: 'home-and-furniture', id: '9' },
  { label: 'Insurance', value: 'insurance', id: '10' },
  { label: 'Jewellery and accessories', value: 'jewellery-and-accessories', id: '11' },
  { label: 'Marketplace/Retail', value: 'marketplace/retail', id: '12' },
  {
    label: 'Online services and live broadcast',
    value: 'online-services-and-live-broadcast',
    id: '13',
  },
  { label: 'Pets', value: 'pets', id: '14' },
  { label: 'Toys and video games', value: 'toys-and-video-games', id: '15' },
  { label: 'Trips', value: 'trips', id: '16' },
  { label: 'Airlines', value: 'airlines', id: '17' },
  { label: 'Others', value: 'others', id: '18' },
];
const ecommercePlatformOptions = [
  { label: 'Select...', value: '', id: '0' },
  { label: 'Shopify', value: 'shopify', id: '1' },
  { label: 'WooCommerce', value: 'woocommerce', id: '2' },
  { label: 'Prestashop', value: 'prestashop', id: '3' },
  { label: 'Magento', value: 'magento', id: '4' },
  { label: 'VTEX', value: 'vtex', id: '5' },
  { label: 'Cloud Store', value: 'cloud-store', id: '6' },
  { label: 'Wix', value: 'wix', id: '7' },
  { label: 'Own', value: 'own', id: '8' },
  { label: 'Other', value: 'other', id: '9' },
];

export const enTranslations: PayLaterTranslations = {
  language: 'en',
  formSuccess: 'Thank you for approaching us! We will get back to you as soon as possible.',
  requiredError: 'This field is mandatory',
  name: {
    placeholder: 'Contact name',
  },
  position: {
    placeholder: 'Position',
  },
  email: {
    placeholder: 'E-mail',
    error: 'The email format is incorrect. Please provide a valid email.',
  },
  phoneNumber: {
    placeholder: 'Phone number',
    error: 'Phone number is invalid. Please provide a valid one.',
  },
  campainRules: {
    placeholder:
      '<span>I have read and accepted the</span> <a href="https://documents.ipfdigital.io/mobilewallet/EN/Partners_Privacy_Notice.pdf" target="_blank" style="color: #FFF; text-decoration: underline; text-underline-offset: 3px;">Privacy Notice.</a>',
    error: 'To proceed, please tick the box',
  },
  submitButtonLabel: 'Send',
  tradeName: {
    placeholder: 'Company name',
  },
  website: {
    placeholder: 'Website',
    error:
      "Please provide the full address of your company's website. If you do not have a website, unfortunately Creditea is not available for your business.",
  },
  country: {
    label: 'Country',
    options: countryOptions,
    error: 'This field is mandatory',
  },
  sector: {
    label: 'Sector',
    options: sectorOptions,
    error: 'This field is mandatory',
  },
  ecommercePlatform: {
    label: 'E-commerce platform',
    options: ecommercePlatformOptions,
    error: 'This field is mandatory',
  },
};
