import React from 'react';
import * as Styled from './BulletListWrapper.style';

type TBulletListWrapper = {
  children: React.ReactNode;
};

function BulletListWrapper({ children }: TBulletListWrapper) {
  return <Styled.List>{children}</Styled.List>;
}

export default BulletListWrapper;
