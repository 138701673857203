import React from 'react';
import {
  Accordion,
  Col,
  Row,
  Typography,
  Socials,
  Gutter,
  SocialIconsNames,
} from '@ipf-ui/components-library';
import * as Styled from './FooterNavMobile.styles';

import SubMenu from '../../molecules/SubMenu/SubMenu';

import { FooterNavProps } from '../../organisms/Footer/Footer.types';
import { definedValues, nonNullKeys } from '../../../helpers';

const FooterNavMobile = ({ menuList, socialList, socialsTitle }: FooterNavProps) => {
  return (
    <Row>
      <Gutter size="grid" />
      {menuList?.map((item) => (
        <Col key={item.name} xs={4} paddingTop={0}>
          {item.link ? (
            <Styled.LinkWrapper
              href={item.link.href}
              prefetch={!item.external ? false : undefined}
              target={!item.link.anchorTarget ? '_blank' : '_self'}
            >
              {item.link.name || item.name}
            </Styled.LinkWrapper>
          ) : (
            <Accordion title={item.name} variant="footer">
              <SubMenu
                list={item.list!}
                linkVariant="ultraLight"
                listItemToLeft={true}
                mode={'light'}
              />
            </Accordion>
          )}
        </Col>
      ))}
      <Styled.SocialsWrapper>
        <Typography.Header7>{socialsTitle}</Typography.Header7>
        <Socials
          socialList={definedValues(
            socialList?.map((social) => {
              return nonNullKeys({ ...social, icon: social.icon as SocialIconsNames });
            })
          )}
        />
      </Styled.SocialsWrapper>
    </Row>
  );
};

export default FooterNavMobile;
