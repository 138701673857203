import React from 'react';
import { useRouter } from 'next/router';

import Link from '../../atoms/Link/Link';
import { Typography } from '@ipf-ui/components-library';

import * as Styled from './SubMenu.style';

type TSubMenu = {
  list?: {
    name?: string | null;
    href?: string | null;
    anchorTarget?: boolean | null;
    icon?: string | null;
    external?: boolean | null;
    isLanguage?: boolean | null;
  }[];
  linkVariant: 'light' | 'bold' | 'ultraLight';
  isRow?: boolean;
  listItemToLeft?: boolean;
  mode: 'dark' | 'light';
  closeMobileMenu?: () => void;
};

const SubMenu = ({ list, linkVariant, isRow, listItemToLeft, closeMobileMenu }: TSubMenu) => {
  const router = useRouter();

  const handlerSetLanguage = async (lang?: string | null) => {
    if (!lang) return;
    router.push(router.asPath, undefined, { locale: lang });
  };

  return (
    <Styled.SubMenuList isRow={isRow}>
      {list?.map((item, i) => (
        <Styled.SubMenuListItem
          key={i}
          isRow={isRow}
          listItemToLeft={listItemToLeft}
          variant={linkVariant}
          onClick={closeMobileMenu}
        >
          {item.isLanguage ? (
            <Styled.ButtonElement variant="text" onClick={() => handlerSetLanguage(item.href)}>
              <Typography.Link2>{item.name}</Typography.Link2>
            </Styled.ButtonElement>
          ) : (
            <Link
              href={item.href ?? ''}
              name={item.name ?? ''}
              variant={linkVariant}
              mode={'navigation'}
              anchorTarget={Boolean(item.anchorTarget)}
              externalLink={Boolean(item.external)}
              scroll={!item.anchorTarget}
            />
          )}
        </Styled.SubMenuListItem>
      ))}
    </Styled.SubMenuList>
  );
};

export default SubMenu;
