import React from 'react';
import {
  PortableText,
  PortableTextMarkComponentProps,
  PortableTextReactComponents,
} from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types';
import { Typography, BulletListItem, theme, ColorMode } from '@ipf-ui/components-library';
import BulletListWrapper from '../../atoms/BulletListWrapper/BulletListWrapper';
import { default as NextLink } from 'next/link';
import SanityImageWrapper from '../SanityImageWrapper/SanityImageWrapper';
import styled from 'styled-components';
import * as Styled from './PortableTextWrapper.style';

interface IPortableText {
  portableText: Array<PortableTextBlock>;
  urlFor?: (filename: string) => string;
  mode?: ColorMode;
}

const PortableLink = styled.a<Pick<IPortableText, 'mode'>>`
  color: ${({ mode }) =>
    mode === 'dark'
      ? theme.crediteaColors.primary.brandBlue[500]
      : theme.crediteaColors.secondary.lightBlue[500]};
`;
const PortableNextLink = styled(NextLink)<Pick<IPortableText, 'mode'>>`
  color: ${({ mode }) =>
    mode === 'dark'
      ? theme.crediteaColors.primary.brandBlue[500]
      : theme.crediteaColors.secondary.lightBlue[500]};
`;

const PortableTextWrapper = ({ portableText, urlFor, mode = 'dark' }: IPortableText) => {
  const myPortableTextComponents: Partial<PortableTextReactComponents> = {
    block: {
      normal: ({ children }) => <Typography.Body3>{children}</Typography.Body3>,
      h1: ({ children }) => <Typography.Header1>{children}</Typography.Header1>,
      h2: ({ children }) => <Typography.Header2>{children}</Typography.Header2>,
      h3: ({ children }) => <Typography.Header3>{children}</Typography.Header3>,
      h4: ({ children }) => <Typography.Header4>{children}</Typography.Header4>,
      h5: ({ children }) => <Typography.Header5>{children}</Typography.Header5>,
      h6: ({ children }) => <Typography.Header6>{children}</Typography.Header6>,
      h7: ({ children }) => <Typography.Header7>{children}</Typography.Header7>,
      h8: ({ children }) => <Typography.Header8>{children}</Typography.Header8>,
      body1: ({ children }) => <Typography.Body1>{children}</Typography.Body1>,
      body2: ({ children }) => <Typography.Body2>{children}</Typography.Body2>,
      body3: ({ children }) => <Typography.Body3>{children}</Typography.Body3>,
      body4: ({ children }) => <Typography.Body4>{children}</Typography.Body4>,
      body5: ({ children }) => <Typography.Body5>{children}</Typography.Body5>,
      caption1: ({ children }) => <Typography.Caption1>{children}</Typography.Caption1>,
      caption2: ({ children }) => <Typography.Caption2>{children}</Typography.Caption2>,
      caption3: ({ children }) => <Typography.Caption3>{children}</Typography.Caption3>,
      caption4: ({ children }) => <Typography.Caption4>{children}</Typography.Caption4>,
      caption5: ({ children }) => <Typography.Caption5>{children}</Typography.Caption5>,
    },
    marks: {
      link: ({ children, value }: PortableTextMarkComponentProps) =>
        value?.['href']?.startsWith('/') ? (
          <PortableNextLink
            href={value?.['href']}
            mode={mode}
            style={{ textDecoration: 'underline' }}
          >
            {children}
          </PortableNextLink>
        ) : (
          <PortableLink href={value?.['href']} mode={mode} style={{ textDecoration: 'underline' }}>
            {children}
          </PortableLink>
        ),
      // @deprecated, use empty lines in the text editor instead
      hardBreak: () => '',
      alignLeft: ({ children }) => (
        <span style={{ display: 'flex', textAlign: 'left' }}>{children}</span>
      ),
      alignCenter: ({ children }) => (
        <span style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
          {children}
        </span>
      ),
      styledUnderline: ({ children }) => (
        <Styled.UnderlineWrapper>
          {children}
          <svg
            viewBox="0 0 247 26"
            fill="none"
            xmlns="http://www.w3.org/2000/"
            preserveAspectRatio="none"
          >
            <path d="M14.0533 6L242.486 20H1L246 12.9999" stroke="#A7CB19" strokeWidth="12" />
          </svg>
        </Styled.UnderlineWrapper>
      ),
      color: ({ children, value }) => <span style={{ color: value.hex }}>{children}</span>,
    },
    list: {
      bullet: ({ children }) => <BulletListWrapper>{children}</BulletListWrapper>,
    },
    listItem: {
      bullet: ({ children }) => <BulletListItem>{children}</BulletListItem>,
      number: ({ children }) => <BulletListItem>{children}</BulletListItem>,
    },
    types: {
      imageWithAlt: ({ value }) => {
        return <SanityImageWrapper value={value} urlFor={urlFor} />;
      },
    },
  };

  return <PortableText value={portableText} components={myPortableTextComponents} />;
};
export default PortableTextWrapper;
