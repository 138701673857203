type SanityImageConfig = {
  cdnUrl: string;
  projectId: string;
  dataset: string;
};

export const buildSanityImageUrl = (args: SanityImageConfig) => (filename: string) => {
  const { cdnUrl, dataset, projectId } = args;
  const validFilename = filename.replace(/-([^-.]*)$/, '.$1');

  return `${cdnUrl}/${projectId}/${dataset}/${validFilename}`;
};
