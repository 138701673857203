import styled from 'styled-components';
import Link from 'next/link';

export const Header = styled.div`
  padding: 18px 0;
`;

export const LinkWrapper = styled(Link)`
  color: inherit;
`;

export const SocialWrapper = styled.div`
  margin-top: 0;
  ${({ theme }) => theme.breakpoints.sm} {
    margin-top: 36px;
  }
  ${({ theme }) => theme.breakpoints.md} {
    margin-top: 0;
  }
`;

export const TextSocialWrapper = styled.span``;
