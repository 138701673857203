import { FeatureList, LinkWithChildren } from '@ipf-ui/components-library';
import React from 'react';
import type { FeatureList as FeatureListProps, ImageWithLink } from '@web-creditea/interfaces';
import { default as NextImage } from 'next/image';
import styled from 'styled-components';
import Link from 'next/link';
import { definedValues, nonNullKeys, returnMaybe } from '../../../helpers';

const ImageWrapper = styled.div<{ width: number | undefined; height: number | undefined }>`
  position: relative;
  width: ${({ width }) => width || 150}px;
  height: ${({ height }) => height || 45}px;

  & > img {
    object-fit: contain;
  }
`;

export const FeatureListSection = (props: FeatureListProps) => {
  const { featureVariant, backgroundColor } = props;
  if (featureVariant === 'text' && props.textFeatures?.length) {
    return (
      <FeatureList
        variant="text"
        features={returnMaybe(definedValues(props.textFeatures))}
        backgroundColor={returnMaybe(backgroundColor?.value)}
        textColor={returnMaybe(props.textColor?.value)}
      />
    );
  }
  if (featureVariant === 'images' && props.imageFeatures?.length) {
    const imageElements = props.imageFeatures?.map((image) => {
      if (!image) return null;
      const { imageWidth, imageHeight } = props;
      const hasLink = Boolean(image.link?.internalLink || image.link?.externalUrl);

      return (
        <>
          {hasLink ? (
            <LinkWithChildren
              linkData={returnMaybe(
                nonNullKeys({
                  ...image?.link,
                  internalLink: {
                    ...image.link?.internalLink,
                  },
                })
              )}
              NextLink={Link}
            >
              <FeatureListImage
                height={returnMaybe(imageHeight)}
                width={returnMaybe(imageWidth)}
                image={image}
              />
            </LinkWithChildren>
          ) : (
            <FeatureListImage
              height={returnMaybe(imageHeight)}
              width={returnMaybe(imageWidth)}
              image={image}
            />
          )}
        </>
      );
    });

    return (
      <FeatureList
        variant={'images'}
        features={returnMaybe(definedValues(imageElements))}
        backgroundColor={returnMaybe(backgroundColor?.value)}
      />
    );
  }
  return null;
};

interface FeatureListImageProps {
  height?: number;
  width?: number;
  image?: ImageWithLink;
}
const FeatureListImage = ({ height, width, image }: FeatureListImageProps) => {
  return (
    <ImageWrapper height={height} width={width}>
      {image?.asset?.url && (
        <NextImage
          src={image.asset.url}
          alt={image.customAltText ?? image.asset.altText ?? ''}
          fill
        />
      )}
    </ImageWrapper>
  );
};
