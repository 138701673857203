import React from 'react';
import { Button } from '@ipf-ui/components-library';

import { Link } from '@web-creditea/interfaces';
import { createSingleLink } from '../../../helpers/createMenu';
import * as Styled from './Error404.style';
import NextLink from 'next/link';

interface FourOhFourProps {
  title?: string;
  text?: string;
  button?: Link;
}

const Error404 = ({ title, text, button }: FourOhFourProps) => {
  const { link } = createSingleLink(title, button);
  return (
    <Styled.Container404>
      <Styled.Header1>{title}</Styled.Header1>
      <Styled.Body1>{text}</Styled.Body1>
      {button?.linkTitle && (
        <NextLink
          target={link?.anchorTarget === false ? '_blank' : '_self'}
          href={link?.href ?? '/'}
        >
          <Button size={'small'} variant="primary" autoWidth={'xs'}>
            {button.linkTitle}
          </Button>
        </NextLink>
      )}
    </Styled.Container404>
  );
};

export default Error404;
