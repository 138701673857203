type Alternates = ({ hrefLang?: string | null; externalUrl?: string | null } | null)[];

export const getAlternates = (alternates?: Alternates): { hrefLang: string; href: string }[] => {
  return [
    ...(alternates?.map((alternate) => {
      return {
        hrefLang: alternate?.hrefLang || '',
        href: alternate?.externalUrl || '',
      };
    }) || []),
  ];
};
