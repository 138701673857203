import { Container, Row } from '@ipf-ui/components-library';
import styled from 'styled-components';

type THeader = {
  offset: number;
  isOpen: boolean;
  hasPromoBar: boolean;
  promoBarOnlyMobile?: boolean;
};

export const Header = styled.header<THeader>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[24]};
  padding-bottom: ${({ theme }) => theme.space[24]};
  padding-top: ${({ theme, hasPromoBar }) => (hasPromoBar ? 0 : theme.space[24])};
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1500;
  background-color: ${({ offset, isOpen, theme }) =>
    offset > 1 || isOpen ? theme.crediteaColors.primary.neutral[50] : 'transparent'};
  transition: background-color 0.15s ease-in;

  ${({ theme }) => theme.breakpoints.sm} {
    padding-top: ${({ theme, hasPromoBar, promoBarOnlyMobile }) =>
      hasPromoBar && !promoBarOnlyMobile ? 0 : theme.space[24]};
  }

  ${({ theme }) => theme.breakpoints.lg} {
    gap: ${({ theme }) => theme.space[10]};
    padding-bottom: ${({ theme }) => theme.space[10]};
    padding-top: ${({ theme, hasPromoBar, promoBarOnlyMobile }) =>
      hasPromoBar && !promoBarOnlyMobile ? 0 : theme.space[10]};
  }
`;

export const LogoWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 6px;
  margin-right: ${({ theme }) => theme.space[32]};
  ${({ theme }) => theme.breakpoints.sm} {
    padding-bottom: 0;
  }
  ${({ theme }) => theme.breakpoints.lg} {
    padding-bottom: 6px;
  }
`;

export const MenuButtonWrapper = styled.button`
  border: none;
  background-color: transparent;
`;

export const HeaderContainer = styled(Container)`
  &&& {
    ${({ theme }) => theme.breakpoints.lg} {
      min-width: 1120px;
      width: 100%;
      padding: 0 48px !important;
    }

    ${({ theme }) => theme.breakpoints.xl} {
      padding: 0 !important;
      max-width: 1440px;
    }
  }
`;

export const MobileStylesWrapper = styled(Row)`
  &&& {
    margin-top: 0;
    margin-left: 0;
    ${({ theme }) => theme.breakpoints.lg} {
      display: none;
    }
  }
`;

export const RowWrapper = styled(Row)`
  &&& {
    margin-top: 0;
    margin-left: 0;
  }
`;

export const BigDesktopPadding = styled.div`
  width: 100%;
  ${({ theme }) => theme.breakpoints.xl} {
    padding-left: 48px;
    padding-right: 48px;
  }
`;
