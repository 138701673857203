import { theme } from '@ipf-ui/components-library';
import { AllSectionsFragment } from 'libs/interfaces/src/lib/gql/graphql';

export const isDarkMode = (sections: Partial<AllSectionsFragment>[]): 'light' | 'dark' => {
  if (
    sections?.find(
      (section) =>
        (section.__typename === 'Hero' &&
          section.backgroundColor?.value === theme.crediteaColors.primary.brandBlue[600]) ||
        section.__typename === 'Iframe' ||
        // #TODO to be deleted after end of campaign
        section.__typename === 'HeroForXFactor'
    )
  )
    return 'dark';
  return 'light';
};
