import styled from 'styled-components';
import { theme } from '@ipf-ui/components-library';

export const SectionWrapper = styled.section`
  min-height: 478px;
  display: grid;
  gap: 48px;

  ${theme.breakpoints.md} {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 128px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 290px;

  img {
    object-fit: contain;
  }

  ${theme.breakpoints.md} {
    height: 448px;
  }
`;

export const ListsContainer = styled.div``;

export const List = styled.ul`
  display: grid;
  padding: 0;
  gap: 18px;

  li {
    line-height: 26px;
    list-style-type: disc;
  }
`;
