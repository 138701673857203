import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetBlogPageQuery,
  GetBlogPageQueryVariables,
  GetBlogPageDocument,
  BlogAllCategoriesDocument,
  BlogAllCategoriesQuery,
  BlogAllCategoriesQueryVariables,
  BlogSlugsDocument,
  BlogSlugsQuery,
  BlogSlugsQueryVariables,
  BlogSingleArticleDocument,
  BlogSingleArticleQuery,
  BlogSingleArticleQueryVariables,
} from '@web-creditea/interfaces';

export const getBlogPage =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (lang: string, market: string, preview: boolean) => {
    const {
      data: { allBlogPage },
    } = await client.query<GetBlogPageQuery, GetBlogPageQueryVariables>({
      query: GetBlogPageDocument,
      variables: { lang, market },
      fetchPolicy: 'no-cache',
    });

    const pageDraft = allBlogPage.find((page) => page._id?.startsWith('drafts'));
    const publishedPage = allBlogPage.find((page) => !page._id?.startsWith('drafts'));
    const pageData = preview && pageDraft ? pageDraft : publishedPage;

    return pageData;
  };

export const getAllBlogCategories =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (language: string, market: string, preview: boolean) => {
    const {
      data: { allBlogCategories },
    } = await client.query<BlogAllCategoriesQuery, BlogAllCategoriesQueryVariables>({
      query: BlogAllCategoriesDocument,
      variables: {
        language,
        market,
      },
      fetchPolicy: 'no-cache',
    });

    const publishedCategories = allBlogCategories.filter(({ _id }) => !_id?.startsWith('drafts'));
    return preview ? allBlogCategories : publishedCategories;
  };

export const getBlogSlugs =
  (client: ApolloClient<NormalizedCacheObject>) => async (language: string[], market: string) => {
    const {
      data: { allBlog },
    } = await client.query<BlogSlugsQuery, BlogSlugsQueryVariables>({
      query: BlogSlugsDocument,
      variables: {
        market,
        language,
      },
      fetchPolicy: 'no-cache',
    });

    return allBlog;
  };

export const getSingleBlogArticleBySlug =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (language: string, market: string, preview: boolean, slug: string | string[]) => {
    const {
      data: { allBlog },
    } = await client.query<BlogSingleArticleQuery, BlogSingleArticleQueryVariables>({
      query: BlogSingleArticleDocument,
      variables: {
        language,
        market,
        slug: { current: { eq: typeof slug === 'string' ? slug : slug[0] } },
      },
      fetchPolicy: 'no-cache',
    });

    const pageDraft = allBlog.find((page) => page._id?.startsWith('drafts'));
    const publishedPage = allBlog.find((page) => !page._id?.startsWith('drafts'));
    const pageData = preview && pageDraft ? pageDraft : publishedPage;

    return pageData;
  };
