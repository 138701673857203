import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { LanguageCode } from '@ipf-ui/components-library';
import { PayLaterForm } from './PayLaterForm';
import { enTranslations } from '../../../data/payLaterFormTranslations/en';
import { esTranslations } from '../../../data/payLaterFormTranslations/es';
import { sendGTMEvent } from '@next/third-parties/google';
import { getCookie } from 'cookies-next';
import { getDomainUrl } from '../../../helpers';
import { Market, markets, UTM_COOKIES } from '@web-creditea/interfaces';

import type {
  PayLaterFormWrapperProps,
  PayLaterTranslations,
  FormValues,
  ResetOnChange,
} from './PayLaterForm.types';

const localeTranslationMap: Partial<Record<LanguageCode, PayLaterTranslations>> = {
  EN: enTranslations,
  ES: esTranslations,
};

const PayLaterFormWrapper: React.FC<PayLaterFormWrapperProps> = ({
  title,
  subtitle,
  market,
  campaign,
}) => {
  const [success, setSuccess] = useState(false);
  const { locale, basePath: routerBasePath, asPath, defaultLocale } = useRouter();

  const basePath = locale !== defaultLocale ? `${routerBasePath}/${locale}` : routerBasePath;

  const domain = getDomainUrl(market);
  const slug = asPath.split('?')[0];
  const formURL = `${domain.origin}${basePath}${slug}`;

  const utmCookies = getCookie('utm_parameters');
  let parsedCookies: UTM_COOKIES = {};
  if (typeof window != 'undefined' && utmCookies) {
    parsedCookies = JSON.parse(utmCookies);
  }
  const sourceURLCookie = getCookie('sourceURL');

  const languageCode = (locale?.toUpperCase() as LanguageCode) ?? 'EN';
  const translations = localeTranslationMap[languageCode];

  const resetSuccess = useCallback(() => {
    if (success) {
      setSuccess(false);
    }
  }, [success]);

  const resetOnChange: ResetOnChange = useCallback(
    (onChange, value) => {
      resetSuccess();
      onChange(value);
    },
    [resetSuccess]
  );

  const handleSubmit = useCallback(
    async (data: FormValues) => {
      const countrySelector = translations?.country.options.find(
        ({ value }) => value === data.countrySelector.value
      );
      const sector = translations?.sector.options.find(({ value }) => value === data.sector.value);
      const ecommercePlatform = translations?.ecommercePlatform.options.find(
        ({ value }) => value === data.ecommercePlatform.value
      );

      const { utm_campaign, utm_source, utm_term, utm_content, utm_medium } = parsedCookies;

      const url = (market: Market | undefined) => {
        if (market === markets.ee || market === markets.lv || market === markets.lt) {
          return `${window.location.origin}${basePath}/api/lead-gathering`;
        } else return `${window.location.origin}/api/lead-gathering`;
      };

      const payload = {
        firstName: data.clientName,
        lastName: data.clientName,
        email: data.email,
        consent1Value: data.campaignRules,
        consent1Name: 'Campaign Rules',
        consent1Version: 1,
      };

      const countryCode = data.countrySelector.label.replace('+', '');
      // eslint-disable-next-line no-useless-escape
      const pulledCountryCode = countryCode.replace(/[(\)]/g, '');
      const connectedPhone = parseInt(pulledCountryCode + data.phoneNumber);
      const longText = `trade name: ${data.tradeName}, website: ${data.website}, country: ${data.countrySelector.value}`;

      const cloudResponse = await fetch(url(market), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...payload,
          brand: 'Creditea',
          campaign: campaign ?? 'Pay Later Campaign',
          country: market?.slice(8, 10).toLowerCase(),
          phone: connectedPhone,
          longText,
          shortText1: data.position,
          shortText2: data.sector.value,
          shortText3: data.ecommercePlatform.value,
          ...(utm_medium ? { utmMedium: utm_medium } : {}),
          ...(utm_campaign ? { utmCampaign: utm_campaign } : {}),
          ...(utm_content ? { utmContent: utm_content } : {}),
          ...(utm_source ? { utmSource: utm_source } : {}),
          ...(utm_term ? { utmTerm: utm_term } : {}),
          ...(formURL ? { formURL } : {}),
          ...(sourceURLCookie ? { sourceURL: sourceURLCookie } : {}),
        }),
      });

      const emailResponse = await fetch(`${window.location.origin}/api/paylater-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          countrySelector,
          ecommercePlatform,
          sector,
          language: locale,
          ...(utm_medium ? { utmMedium: utm_medium } : {}),
          ...(utm_campaign ? { utmCampaign: utm_campaign } : {}),
          ...(utm_content ? { utmContent: utm_content } : {}),
          ...(utm_source ? { utmSource: utm_source } : {}),
          ...(utm_term ? { utmTerm: utm_term } : {}),
          ...(formURL ? { formURL } : {}),
          ...(sourceURLCookie ? { sourceURL: sourceURLCookie } : {}),
        }),
      });
      if (emailResponse.ok && cloudResponse.ok) {
        setSuccess(true);
        sendGTMEvent({ event: 'contact_form_submitted' });
        try {
          const { email } = await emailResponse.json();
          await fetch(`${window.location.origin}/api/paylater-autoreply`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, language: locale }),
          });
        } catch (error) {
          return;
        }
      }
    },
    [translations, locale]
  );

  if (!translations) return null;
  return (
    <PayLaterForm
      onSubmit={handleSubmit}
      {...{ translations, title, subtitle, success, resetSuccess, resetOnChange }}
    />
  );
};

export default PayLaterFormWrapper;
