import React from 'react';
import { Controller, Control, FormState, UseFormRegister } from 'react-hook-form';
import type { PortableTextBlock } from '@portabletext/types';

import {
  InputItem,
  SelectItem,
  Col,
  Gutter,
  Row,
  formValidationPattern,
} from '@ipf-ui/components-library';

import { FormValues, PayLaterFormProps, ResetOnChange } from '../PayLaterForm.types';

interface UserInformationProps {
  register: UseFormRegister<FormValues>;
  control: Control<FormValues>;
  formState: FormState<FormValues>;
  translations: PayLaterFormProps['translations'];
  success: boolean;
  title: Array<PortableTextBlock>;
  subtitle: Array<PortableTextBlock>;
  resetSuccess: () => void;
  resetOnChange: ResetOnChange;
}

export const UserInformation: React.FC<UserInformationProps> = (props) => {
  const { control, register, formState, translations, resetSuccess, resetOnChange } = props;
  const { errors } = formState;
  return (
    <div>
      <InputItem
        {...register('clientName', {
          required: translations.requiredError,
          pattern: {
            value: /^.+$/g,
            message: translations.requiredError,
          },
        })}
        placeholder={translations.name.placeholder}
        errorMessage={errors.clientName?.message}
        isInvalid={!!errors.clientName?.message}
        mode="dark"
        onKeyDown={resetSuccess}
      />
      <Gutter size={32} />
      <InputItem
        {...register('position', {
          required: translations.requiredError,
          pattern: {
            value: /^.+$/g,
            message: translations.requiredError,
          },
        })}
        placeholder={translations.position.placeholder}
        errorMessage={errors.position?.message}
        isInvalid={!!errors.position?.message}
        mode="dark"
        onKeyDown={resetSuccess}
      />
      <Gutter size={32} />
      <InputItem
        {...register('email', {
          required: translations.requiredError,
          pattern: {
            value: formValidationPattern.EMAIL_REGEXP,
            message: translations.email.error ?? '',
          },
        })}
        type="email"
        placeholder={translations.email.placeholder}
        errorMessage={errors.email?.message}
        isInvalid={!!errors.email?.message}
        mode="dark"
        onKeyDown={resetSuccess}
      />
      <Gutter size={32} />
      <Row>
        {translations.country.options.length && (
          <Col xs={2} sm={3} md={4}>
            <Controller
              name="countrySelector.value"
              control={control}
              render={({ field }) => (
                <SelectItem
                  {...field}
                  onChange={(e) => resetOnChange(field.onChange, e.currentTarget.value)}
                  value={translations.country.options.find((o) => o.value === field.value)}
                  title={translations.country.label}
                  defaultOption={
                    translations.language === 'en'
                      ? translations.country.options[3]
                      : translations.country.options[2]
                  }
                  options={translations.country.options}
                />
              )}
            />
          </Col>
        )}
        <Col xs={2} sm={5} md={8}>
          <InputItem
            {...register('phoneNumber', {
              pattern: {
                value: formValidationPattern.PHONE_REGEXP,
                message: translations.phoneNumber.error ?? '',
              },
            })}
            type="number"
            placeholder={translations.phoneNumber.placeholder}
            errorMessage={errors.phoneNumber?.message}
            mode="dark"
            onKeyDown={resetSuccess}
          />
        </Col>
        <Gutter size={32} />
      </Row>
    </div>
  );
};
