import styled from 'styled-components';

export const CopyrightsContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.crediteaColors.primary.neutral[500]};
  padding-top: 40px;
  margin-top: 40px;

  ${({ theme }) => theme.breakpoints.md} {
    padding-top: 60px;
    margin-top: 60px;
  }
`;

export const CopyrightsAccountNumber = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
  padding-bottom: 15px;

  ${({ theme }) => theme.breakpoints.md} {
    padding-bottom: 25px;
  }
`;
export const CopyrightsAccountNumberItem = styled.li``;

export const CopyrightsText = styled.div`
  padding-top: 15px;

  ${({ theme }) => theme.breakpoints.md} {
    padding-top: 25px;
  }
`;
