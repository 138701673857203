export const socialMedia = {
  ee: ['https://www.instagram.com/crediteaeesti/'],
  lt: ['https://www.facebook.com/creditea.lietuva/', 'https://www.instagram.com/creditealietuva/'],
  lv: [
    'https://www.facebook.com/creditealatvija',
    'https://www.instagram.com/creditealatvija',
    'https://www.tiktok.com/@creditealatvija',
  ],
  cz: [],
  mx: [],
  en: [],
};
