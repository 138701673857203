import type { FormValues, PayLaterTranslations } from './PayLaterForm.types';

export const defaultValues = (translations: PayLaterTranslations): FormValues => ({
  ecommercePlatform: translations?.ecommercePlatform?.options[0],
  sector: translations?.sector?.options[0],
  tradeName: '',
  website: '',
  clientName: '',
  email: '',
  position: '',
  countrySelector:
    translations.language === 'en'
      ? translations?.country?.options[3]
      : translations?.country?.options[2],
  phoneNumber: '',
  campaignRules: false,
  marketingConsent: false,
});
