import styled, { css } from 'styled-components';
import { modeStyles } from '../Link/Link.style';

export const ToggleButton = styled.button<{
  isLang?: boolean;
  active?: boolean;
  mode: 'dark' | 'light';
}>`
  border: none;
  background: none;
  cursor: pointer;

  ${({ theme, mode, active }) => modeStyles(theme, mode, active)}

  ${(props) =>
    props.isLang &&
    css`
      display: flex;
      align-items: center;

      svg {
        margin: 0 8px;
      }
    `}
`;

export const MenuItemsWrapper = styled.div`
  width: max-content;
`;
