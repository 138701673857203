import styled from 'styled-components';

export const SanityImageWrapper = styled.div`
  position: relative;
  margin: 26px 0;
  height: 388px;

  ${({ theme }) => theme.breakpoints.md} {
    height: 448px;
  }
`;
