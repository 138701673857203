import { RefObject } from 'react';

export const getRefElement = <T extends object>(
  element?: RefObject<Element> | T
): Element | T | undefined | null => {
  if (element && 'current' in element) {
    return element.current;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return element;
};
