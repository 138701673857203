import React from 'react';
import NextLink from 'next/link';
import { Button, Icon, IconNames, theme, Typography } from '@ipf-ui/components-library';
import * as Styled from './MobileMenu.style';

interface LinkButton {
  buttonLink: {
    anchorTarget: boolean;
    href: string;
  };
  buttonTitle: string;
  buttonExternal: boolean | undefined;
  closeMobileMenu?: () => void;
}

export const ApplyButton = ({
  buttonLink,
  buttonTitle,
  buttonExternal,
  closeMobileMenu,
}: LinkButton) => {
  if (buttonExternal) {
    return (
      <a href={buttonLink.href} target={!buttonLink.anchorTarget ? '_blank' : '_self'}>
        <Button variant="primary" size="small" autoWidth={false} onClick={closeMobileMenu}>
          <Typography.Link1 color={theme.crediteaColors.primary.neutral[50]}>
            {buttonTitle}
          </Typography.Link1>
        </Button>
      </a>
    );
  }
  return (
    <NextLink href={buttonLink.href} passHref>
      <Button variant="primary" size="small" autoWidth={false} onClick={closeMobileMenu}>
        <Typography.Link1 color={theme.crediteaColors.primary.neutral[50]}>
          {buttonTitle}
        </Typography.Link1>
      </Button>
    </NextLink>
  );
};

export const LoginButton = ({
  buttonLink,
  buttonTitle,
  buttonExternal,
  closeMobileMenu,
}: LinkButton) => {
  if (buttonExternal) {
    return (
      <a href={buttonLink.href} target={!buttonLink.anchorTarget ? '_blank' : '_self'}>
        <Button variant="secondary" size="small" autoWidth={false} onClick={closeMobileMenu}>
          <Typography.Link1 color={theme.crediteaColors.primary.brandBlue[500]}>
            {buttonTitle}
          </Typography.Link1>
        </Button>
      </a>
    );
  }
  return (
    <NextLink href={buttonLink.href} passHref>
      <Button variant="secondary" size="small" autoWidth={false} onClick={closeMobileMenu}>
        <Typography.Link1 color={theme.crediteaColors.primary.brandBlue[500]}>
          {buttonTitle}
        </Typography.Link1>
      </Button>
    </NextLink>
  );
};

interface WhatsAppButtonProps {
  href: string;
}

export const WhatsAppButton = ({ href }: WhatsAppButtonProps) => {
  return (
    <Styled.WhatsAppWrapper
      target="_blank"
      href={href}
      rel="noreferrer noopener"
      aria-label={'Call on WhatsApp'}
    >
      <Icon name={'MenuWhatsApp' as IconNames} />
      <Styled.Contact color={theme.crediteaColors.primary.brandBlue[500]}>WhatsApp</Styled.Contact>
    </Styled.WhatsAppWrapper>
  );
};
