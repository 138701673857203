import React, { useState, useRef } from 'react';
import { Icon, Typography, MenuDropdown, IconNames } from '@ipf-ui/components-library';
import * as Styled from './ToggleButton.style';
import SubMenu from '../../molecules/SubMenu/SubMenu';

export type MenuList = {
  name: string;
  href: string;
  anchorTarget: boolean;
  icon?: IconNames;
}[];

interface ToggleButtonProps {
  children: React.ReactNode;
  name: string;
  isLang?: boolean;
  flag?: IconNames;
  menuList?: MenuList;
  active?: boolean;
  mode: 'dark' | 'light';
}

const ToggleButton = ({
  children,
  name,
  isLang,
  menuList,
  flag = 'FlagGlobal',
  active,
  mode,
}: ToggleButtonProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const buttonRef = useRef(null);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <Styled.ToggleButton
        isLang={isLang}
        id={name}
        onClick={toggleMenu}
        ref={buttonRef}
        active={active}
        mode={mode}
      >
        {isLang && (
          <>
            <Icon name={flag} />
          </>
        )}
        <Typography.Link1>{children}</Typography.Link1>
      </Styled.ToggleButton>
      <MenuDropdown
        open={openMenu}
        handleClose={() => setOpenMenu(false)}
        togglerRef={buttonRef}
        autoClose
      >
        <Styled.MenuItemsWrapper>
          <SubMenu list={menuList} linkVariant="bold" mode={mode} />
        </Styled.MenuItemsWrapper>
      </MenuDropdown>
    </>
  );
};

export default ToggleButton;
