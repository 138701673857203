import { ExitPreviewButton } from './ExitPreviewButton.style';
import { useRouter } from 'next/router';
import React from 'react';

const ExitPreview = () => {
  async function handleExitPreview() {
    const res = await fetch(`${location.origin + router.basePath}/api/exit-preview`, {
      method: 'POST',
      body: location.pathname,
      cache: 'no-store',
    });
    if (res.ok) {
      location.reload();
    }
  }
  const router = useRouter();
  return <ExitPreviewButton onClick={handleExitPreview}>Exit preview mode</ExitPreviewButton>;
};

export default ExitPreview;
