import React, { useState } from 'react';
import { PromoBanner as BasePromoBanner, ButtonConfig } from '@ipf-ui/components-library';
import Image from 'next/image';
import type { ImageWithAlt } from '@web-creditea/interfaces';
import { returnMaybe } from '../../../helpers';

interface PromoBannerProps {
  title?: string;
  backgroundColor?: string;
  textColor?: string;
  image?: ImageWithAlt;
  countrySelectPlaceholder?: string;
  phoneInputPlaceholder?: string;
  buttonText?: string;
  buttonConfig?: ButtonConfig;
}

const OPTIONS = [
  { label: '(+371)', value: '+371', id: '1' },
  { label: '(+372)', value: '+372', id: '2' },
  { label: '(+373)', value: '+373', id: '3' },
];

const PromoBanner = ({
  countrySelectPlaceholder,
  phoneInputPlaceholder,
  title,
  backgroundColor,
  textColor,
  buttonText,
  buttonConfig,
  image,
}: PromoBannerProps) => {
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = () => {
    if (!countryCode || !phoneNumber) return;
    // other phone validation
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;
    // logic
    console.log({ fullPhoneNumber });
  };

  return (
    <BasePromoBanner
      countrySelectPlaceholder={countrySelectPlaceholder}
      phoneInputPlaceholder={phoneInputPlaceholder}
      title={title}
      backgroundColor={backgroundColor}
      textColor={textColor}
      buttonText={buttonText}
      buttonConfig={buttonConfig}
      nextImage={returnMaybe(
        image?.asset && (
          <Image
            src={image?.asset.url ?? ''}
            alt={image?.customAltText ?? image?.asset.altText ?? ''}
            fill
            sizes="
          (max-width: 599) 100vw,
          (max-width: 904) 75vw,
          50vw"
          />
        )
      )}
      onSelectChange={(o) => o?.value && setCountryCode(o.value)}
      onButtonClick={handleSubmit}
      onTextInputChange={(e) => setPhoneNumber(e.target.value)}
      selectOptions={OPTIONS}
    />
  );
};

export default PromoBanner;
