import { domainUrl, Market, markets } from '@web-creditea/interfaces';
import { match } from 'ts-pattern';

export const getDomainUrl = (market?: Market): URL =>
  match(market)
    .with(markets.ee, () => new URL(domainUrl.EE))
    .with(markets.lv, () => new URL(domainUrl.LV))
    .with(markets.lt, () => new URL(domainUrl.LT))
    .with(markets.mx, () => new URL(domainUrl.MX))
    .with(markets.cz, () => new URL(domainUrl.CZ))
    .with(markets.ecommerce, () => new URL(domainUrl.ECOMMERCE))
    .otherwise(() => new URL(domainUrl.EE));
