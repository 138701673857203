type ValueOf<T> = T[keyof T];

export const MARKET_COUNTRY_CODES = {
  lv: 'lv',
  lt: 'lt',
  ee: 'ee',
  mx: 'mx',
  cz: 'cz',
  ecommerce: 'ecommerce',
} as const;

export const ENVIRONMENTS = {
  st08: 'st08',
  sit: 'sit',
  uat: 'uat',
  prod: 'prod',
} as const;

export type CountryCode = ValueOf<typeof MARKET_COUNTRY_CODES>;

export const OTHER_COUNTRY_CODES = {
  us: 'us',
};

export type OtherCountryCode = ValueOf<typeof OTHER_COUNTRY_CODES>;

export type Markets = {
  [C in CountryCode]: `creditea${Uppercase<C>}`;
};

export const markets: Markets = {
  ee: 'crediteaEE',
  lv: 'crediteaLV',
  lt: 'crediteaLT',
  mx: 'crediteaMX',
  cz: 'crediteaCZ',
  ecommerce: 'crediteaECOMMERCE',
} as const;

export type Market = ValueOf<typeof markets>;

export const domainUrl = {
  EE: 'https://www.creditea.com',
  LV: 'https://www.creditea.com',
  LT: 'https://www.creditea.com',
  CZ: 'https://www.creditea.cz',
  MX: 'https://www.creditea.mx',
  ECOMMERCE: 'https://ecommerce.creditea.com',
};
