import { Market } from '@web-creditea/interfaces';
import { getCookie, setCookie } from 'cookies-next';
import { NextRouter } from 'next/router';
import { getDomainUrl } from '../contentMappers';

export const setUtmCookies = (router: NextRouter, market: Market) => {
  const basePath =
    router.locale !== router.defaultLocale
      ? `${router.basePath}/${router.locale}`
      : router.basePath;
  const asPath = router.asPath;
  const domain = getDomainUrl(market);
  const sourceURL = `${domain.origin}${basePath}${asPath}`;

  const { utm_medium, utm_campaign, utm_content, utm_source, utm_term } = router.query;

  const utmStringified = JSON.stringify({
    ...(utm_medium ? { utm_medium } : {}),
    ...(utm_campaign ? { utm_campaign } : {}),
    ...(utm_content ? { utm_content } : {}),
    ...(utm_source ? { utm_source } : {}),
    ...(utm_term ? { utm_term } : {}),
  });

  const hasUtmInUrl = utmStringified.includes('utm');

  const sourceURLCookie = getCookie('sourceURL');
  if ((!sourceURLCookie || sourceURLCookie === '') && hasUtmInUrl) {
    setCookie('sourceURL', sourceURL, { maxAge: 60 * 60 * 24 * 30, sameSite: 'lax' });
  }

  const utmCookie = getCookie('utm_parameters');
  if ((!utmCookie || utmCookie === '{}') && hasUtmInUrl) {
    setCookie('utm_parameters', utmStringified, {
      maxAge: 60 * 60 * 24 * 30,
      sameSite: 'lax',
    });
  }
};
