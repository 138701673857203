import React from 'react';
import * as Styled from './FeedbackPage.style';
import { Col, Container, Gutter, Icon, IconNames, Typography } from '@ipf-ui/components-library';

type FeedbackPageProps = {
  children: React.ReactNode;
  title: string;
  logo: IconNames;
  actionIcon: IconNames;
  color: string;
};

const FeedbackPage = ({ children, color, title, logo, actionIcon }: FeedbackPageProps) => {
  return (
    <Styled.Wrapper>
      <Styled.Logo>
        <Icon name={logo} width={300} />
      </Styled.Logo>
      <Gutter size={40} />
      <Container>
        <Styled.Box>
          <Col>
            <Icon name={actionIcon} width={64} height={64} fillColor={color} />
            <Gutter size={32} />
            <Typography.Header3 color={color}>{title}</Typography.Header3>
            <Gutter size={32} />
          </Col>
        </Styled.Box>
        <Gutter size={32} />
        <Styled.Note>{children}</Styled.Note>
      </Container>
    </Styled.Wrapper>
  );
};

export default FeedbackPage;
