import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import {
  LatestArticles,
  BlogArticles,
  Pagination,
  Gutter,
  BlogCardProps,
  LastArticleForCz,
  LanguageCode,
} from '@ipf-ui/components-library';
import Link from 'next/link';

import { parsePostData, getBlogTranslationByKey, translationsKeys } from '@web-creditea/utils';
import { BlogArticle } from '@web-creditea/interfaces';

interface BlogContentProps {
  articles: BlogArticle[];
  articlesCount: number;
  page: number;
  perPage: number;
  isFirstPageActive: boolean;
  handlePageChange: (page: number) => void;
  language: LanguageCode;
  locales: string;
}

interface LastOrLatestArticlesProps {
  latestArticles: Array<BlogCardProps>;
  featuredPost: BlogCardProps;
  latestArticlesHeadline?: string;
  language: LanguageCode;
}

const LastOrLatestArticles = ({
  language,
  featuredPost,
  latestArticles,
  latestArticlesHeadline,
}: LastOrLatestArticlesProps) => {
  return language === 'CZ' ? (
    <LastArticleForCz
      featuredPost={featuredPost as BlogCardProps}
      language={language}
      nextLink={Link}
    />
  ) : (
    <>
      <LatestArticles
        allArticles={latestArticles}
        featuredPost={featuredPost as BlogCardProps}
        headline={latestArticlesHeadline}
        language={language}
        nextLink={Link}
      />
      <Gutter size={100} />
    </>
  );
};

const BlogContent = ({
  articles: articlesData,
  articlesCount,
  page,
  perPage,
  isFirstPageActive,
  handlePageChange,
  language,
  locales,
}: BlogContentProps) => {
  const router = useRouter();

  const articles = articlesData?.map((article) => ({
    ...parsePostData(article, language, locales),
    onClickLinkAction: () => router.push(`/blog/post/${article.slug.current}`),
  }));
  const [featuredPost, ...articlesWithoutFeatured] = articles;

  let latestArticles = articlesWithoutFeatured;
  if (language !== 'CZ') {
    latestArticles = articlesWithoutFeatured?.splice(0, 4);
  }

  const pageArticles = isFirstPageActive ? articlesWithoutFeatured : articles;

  const latestArticlesHeadline = getBlogTranslationByKey(translationsKeys.latestArticles, language);

  const showPagination = (language === 'CZ' && articlesCount > 7) || articlesCount > 11;

  const previousText = useMemo(
    () => getBlogTranslationByKey(translationsKeys.previousText, language) || 'Previous',
    [language]
  );

  const nextText = useMemo(
    () => getBlogTranslationByKey(translationsKeys.nextText, language) || 'Next',
    [language]
  );

  return (
    <>
      {isFirstPageActive && (!isEmpty(latestArticles) || !isEmpty(featuredPost)) && (
        <LastOrLatestArticles
          latestArticles={latestArticles as BlogCardProps[]}
          featuredPost={featuredPost as BlogCardProps}
          language={language}
          latestArticlesHeadline={latestArticlesHeadline}
        />
      )}
      {!isEmpty(pageArticles) && (
        <>
          <BlogArticles
            allArticles={pageArticles as BlogCardProps[]}
            nextLink={Link}
            language={language}
          />
          {showPagination && (
            <Pagination
              handlePage={handlePageChange}
              resourcesCount={articlesCount}
              currentPage={page}
              perPage={perPage}
              previousText={previousText}
              nextText={nextText}
              language={language}
              offset={language === 'CZ' ? 6 : 10}
            />
          )}
        </>
      )}
    </>
  );
};

export default BlogContent;
