import React, { useState, useEffect } from 'react';
import loanFormData from '../../../data/products/creditlineLT.json';
import {
  LoanForm,
  Slider,
  SliderProps,
  Markets,
  currencyFormatLT,
  ColorModeForButton,
} from '@ipf-ui/components-library';
import { swapSubdomain } from '../../../helpers/createMenu';

const loanValues = loanFormData.map((loanVariant) => {
  return loanVariant.sum / 100;
});
export type LoanFormWrapperLTProps = {
  sliderVariant: Markets;
  currencySymbol: string;
  termLabel: string;
  termSymbol: string;
  interestRate: number;
  amountSlider: SliderProps;
  periodSlider: SliderProps;
  termsDescription: string;
  title: string;
  description: string;
  refundsLabel: string;
  buttonText: string;
  buttonUrl: string;
  anchorTarget?: boolean;
  colorMode?: ColorModeForButton;
  market: string;
  kubernetesNamespace: string;
  backgroundColor?: {
    value: string;
  };
};

const LoanFormWrapperLT = ({
  sliderVariant,
  amountSlider,
  termsDescription,
  title,
  description,
  refundsLabel,
  termLabel,
  termSymbol,
  buttonText,
  buttonUrl,
  anchorTarget,
  colorMode,
  market,
  kubernetesNamespace,
}: LoanFormWrapperLTProps) => {
  const [amountInputValue, setAmountInputValue] = useState<number>(1000);
  const [periodValue, setPeriodValue] = useState<number>(36);
  const [interestRate, setInterestRate] = useState(1.5);
  const [monthlyPaymentValue, setMonthlyPaymentValue] = useState<number>(0);
  const loanIndex = loanFormData.findIndex((loanVariant) => {
    return loanVariant.sum === amountInputValue * 100;
  });
  const totalValue = loanFormData[loanIndex].totalAmount / 100;
  const paymentValue = loanFormData[loanIndex].mmp / 100;
  const sliders = [
    {
      ...amountSlider,
      value: amountInputValue,
      format: currencyFormatLT,
      marks: loanValues,
      type: Slider.Types.Primary,
      onChange: (value: number) => setAmountInputValue(value),
    },
  ];
  const valueList = [
    {
      label: title,
      description: currencyFormatLT(paymentValue, 2),
    },
    { label: termLabel, description: `${periodValue} ${termSymbol}` },
    {
      label: 'BVKGMS',
      description: currencyFormatLT(totalValue, 2),
    },
    { label: 'MPN', description: `${loanFormData[loanIndex].product.annualInterest} %` },
    { label: 'BVKKMN', description: `${loanFormData[loanIndex].product.apr} %` },
  ];

  const applyButton = `${swapSubdomain(
    buttonUrl,
    kubernetesNamespace
  )}?principal=${amountInputValue}&maturity=${periodValue}&channel=creditea_web`;

  const loanFormProps = {
    sliders,
    termsDescription,
    periodValue,
    interestRate,
    valueList,
    format: currencyFormatLT,
    callToAction: {
      title,
      description,
      refundsLabel,
      buttonText,
      colorMode,
      buttonAction: () => window.open(applyButton, anchorTarget === false ? '_blank' : '_self'),
    },
  };

  useEffect(() => {
    const index = loanFormData.findIndex((loanVariant) => {
      return loanVariant.sum === amountInputValue * 100;
    });
    setMonthlyPaymentValue(loanFormData[index].mmp / 100);
    setPeriodValue(loanFormData[index].product.maturityPeriods);
    setInterestRate(loanFormData[index].product.interest);
  }, [amountInputValue]);

  if (sliderVariant !== market.substring(8)) return null;
  return (
    <>
      <LoanForm {...loanFormProps} market={sliderVariant} paymentValue={monthlyPaymentValue} />
    </>
  );
};

export default LoanFormWrapperLT;
