import styled, { css } from 'styled-components';

export const Logo = styled.div<{ variant: string }>`
  position: relative;

  ${(props) =>
    props.variant === 'full' &&
    css`
      width: 134px;
      height: 24px;
    `}

  ${(props) =>
    props.variant === 'symbol' &&
    css`
      width: 40px;
      height: 40px;
    `}
`;
