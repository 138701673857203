import { get } from 'lodash';
import { BlogCategories, Blog } from '@web-creditea/interfaces';
import { TRANSLATIONS } from './blog.translations';
import NextImage from 'next/image';
import React from 'react';

export const translationsKeys = {
  category: 'CATEGORY',
  latestArticles: 'LATEST_ARTICLES',
  readTime: 'READ_TIME',
  months: 'MONTHS',
  allCategories: 'ALL_CATEGORIES',
  relatedArticles: 'RELATED_ARTICLES',
  previousText: 'PREVIOUS_TEXT',
  nextText: 'NEXT_TEXT',
  shareTheArticle: 'SHARE_THE_ARTICLE',
};

export const getBlogTranslationByKey = (key: string, language: string) => {
  const translationsObject = get(TRANSLATIONS, key);

  return get(translationsObject, `${language}_${key}`);
};

export const createPostDetailsText = (post: Blog, language: string, locales: string) => {
  if (!post.publishedDate || typeof post.publishedDate !== 'string') return '';
  const parsedDate = parseDate(post?.publishedDate, locales);
  const readTimeTranslation = getBlogTranslationByKey(translationsKeys.readTime, language);
  const readingTime = post?.useCustomReadingTime
    ? post?.customReadingTime
    : post?.estimatedReadingTime;

  if (language === 'CZ') {
    return `${parsedDate} · ${readingTime} min ${readTimeTranslation}`;
  }

  return `${parsedDate} · ${readTimeTranslation} ${readingTime} min`;
};

const createNextImage = (post: Blog) => {
  return (
    <NextImage
      src={post?.promoImage?.asset?.url ?? ''}
      alt={post?.promoImage?.customAltText ?? post?.promoImage?.asset?.altText ?? ''}
      fill
      style={{
        objectFit: 'cover',
      }}
      sizes=" (max-width: 599) 100vw, 50vw"
    />
  );
};

export const parsePostData = (post: Blog, language: string, locales: string) => ({
  ...post,
  title: post.title ?? '',
  slug: post?.slug?.current,
  dateAndReadTime: createPostDetailsText(post, language, locales),
  image: createNextImage(post),
  category: post?.category?.title,
  categorySlug: post?.category?.category?.slug?.current,
  description: post?.shortDescription,
  lang: language,
});

export const devicePerPageCount = {
  isScreenXL: 12,
  isScreenLG: 12,
  isScreenMD: 10,
  isScreenSM: 5,
  isScreenXS: 5,
};

export const handleMobilePerPageCount = (multiplicand: number) => multiplicand * 2;
export const subtractOneFrom = (minuend: number) => minuend - 1;

export const filterDraftBlogArticles = (articles: Blog[]) =>
  articles?.filter((article) => !article._id?.startsWith('drafts'));

export const allBlogsCategoryOption = (language: string) => ({
  id: '',
  label: getBlogTranslationByKey(translationsKeys.allCategories, language) || 'All categories',
  value: '',
  slug: '',
});

export const mapBlogCategores = (categories: BlogCategories[], language: string) => {
  const mapedCategories = categories.map((option) => ({
    id: option._id,
    label: option.title,
    value: option._id,
    slug: option.category?.slug?.current,
  }));

  return [allBlogsCategoryOption(language), ...mapedCategories];
};

export function parseDate(date: string | undefined, locales: string): string {
  if (!date) return '';
  const parsedDate = new Date(date);
  const formatedDate = new Intl.DateTimeFormat(locales, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
    .format(parsedDate)
    .replace('.', '');

  return formatedDate;
}

export const calculateRange = (currentPage: number, perPage: number, offset: number) => {
  if (currentPage === 1) {
    return `0..${offset}`;
  }
  return `${offset + 1 + perPage * (currentPage - 2)}..${
    offset + 1 + perPage * (currentPage - 2) + (perPage - 1)
  }`;
};
