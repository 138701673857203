import React, { useState, useEffect } from 'react';
import { calculatePaymentInfo } from '../../../helpers/contentMappers/calculatePaymentInfo';
import { LoanForm, Slider, currencyFormatEE } from '@ipf-ui/components-library';
import { useRouter } from 'next/router';

import { LoanFormWrapperProps } from '@web-creditea/interfaces';
import { swapSubdomain } from '../../../helpers/createMenu';

const LoanFormWrapperEE = ({
  sliderVariant,
  interestRate,
  amountSlider,
  periodSlider,
  termsDescription,
  title,
  description,
  refundsLabel,
  buttonText,
  buttonUrl,
  anchorTarget,
  colorMode,
  market,
  kubernetesNamespace,
}: LoanFormWrapperProps) => {
  const [amountInputValue, setAmountInputValue] = useState<number>(amountSlider?.value);
  const [periodInputValue, setPeriodInputValue] = useState<number>(periodSlider?.value);
  const [monthlyPaymentValue, setMonthlyPaymentValue] = useState<number>(1);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [monthlyInterest, setMonthlyInterest] = useState<number>(interestRate);
  const router = useRouter();
  const sliders = [
    {
      ...amountSlider,
      infoPill: amountSlider.hasInfoPill && amountSlider.infoPill,
      value: amountInputValue,
      type: Slider.Types.Labeled,
      format: currencyFormatEE,
      onChange: (value: number) => setAmountInputValue(value),
    },
    {
      ...periodSlider,
      infoPill: periodSlider.hasInfoPill && periodSlider.infoPill,
      value: periodInputValue,
      type: Slider.Types.Labeled,
      onChange: (value: number) => setPeriodInputValue(value),
    },
  ];
  const applyButton = `${swapSubdomain(
    buttonUrl,
    kubernetesNamespace
  )}?principal=${amountInputValue}&maturity=${periodInputValue}&channel=creditea&lang=${
    router.locale
  }`;

  const loanFormProps = {
    sliders,
    termsDescription: termsDescription,
    format: currencyFormatEE,
    callToAction: {
      title,
      description,
      refundsLabel,
      buttonText,
      colorMode,
      buttonAction: () => window.open(applyButton, anchorTarget === false ? '_blank' : '_self'),
    },
  };

  useEffect(() => {
    setMonthlyInterest(interestRate);
    const { monthlyPayment, overallPaymentsSum } = calculatePaymentInfo(
      monthlyInterest,
      amountInputValue,
      periodInputValue
    );
    setMonthlyPaymentValue(Math.round(monthlyPayment));
    setTotalValue(Math.round(overallPaymentsSum));
  }, [interestRate, monthlyInterest, amountInputValue, periodInputValue]);

  if (sliderVariant !== market.substring(8)) return null;
  return (
    <LoanForm
      {...loanFormProps}
      market={sliderVariant}
      interestRate={monthlyInterest}
      paymentValue={monthlyPaymentValue}
      periodValue={periodInputValue}
      totalValue={totalValue}
    />
  );
};

export default LoanFormWrapperEE;
