import React from 'react';
import Link from '../../atoms/Link/Link';

import * as Styled from './MenuLink.style';

interface MenuLinkProps {
  type: 'mobile' | 'desktop';
  external?: boolean;
  link?: {
    name?: string;
    href?: string;
    anchorTarget?: boolean;
  };
  active?: boolean;
  mode: 'dark' | 'light';
  closeMobileMenu?: () => void;
}

const MenuLink = ({ type, external, link, active, mode, closeMobileMenu }: MenuLinkProps) => {
  if (!link) return null;
  return (
    <Styled.MenuLink onClick={closeMobileMenu}>
      <Link
        externalLink={external}
        href={link.href}
        name={link.name}
        variant={type === 'mobile' ? 'ultraBold' : 'bold'}
        active={active}
        mode={mode}
        anchorTarget={link.anchorTarget}
        scroll={!link.anchorTarget}
      />
    </Styled.MenuLink>
  );
};

export default MenuLink;
