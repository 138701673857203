import * as React from 'react';

import Link from '../../atoms/Link/Link';
import { Icon, Gutter, theme } from '@ipf-ui/components-library';

import * as Styled from './Breadcrumbs.style';

interface BreadcrumbsProps {
  breadcrumbs: {
    text: string;
    href: string;
  }[];
}

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => (
  <Styled.BreadcrumbsWrapper>
    {breadcrumbs.map((crumb, i, { length }) => {
      if (i + 1 === length) {
        return (
          <Styled.BreadcrumbText key={`key-${crumb.text}`}>{crumb.text}</Styled.BreadcrumbText>
        );
      } else {
        return (
          <React.Fragment key={`key-${crumb.text}`}>
            <Styled.LinkStyle>
              <Link name={crumb.text} href={crumb.href} variant="light" mode={'faq'} />
            </Styled.LinkStyle>
            {i + 1 < length ? (
              <>
                <Gutter size={12} />
                <Icon name="ArrowRight" fillColor={theme.crediteaColors.primary.neutral[500]} />
                <Gutter size={12} />
              </>
            ) : null}
          </React.Fragment>
        );
      }
    })}
  </Styled.BreadcrumbsWrapper>
);
