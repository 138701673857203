import { Typography } from '@ipf-ui/components-library';
import styled from 'styled-components';

export const AccordionWrapper = styled.div`
  min-height: 50px;
`;

export const ButtonsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  padding: 24px 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;
  background-color: ${({ theme }) => theme.crediteaColors.primary.neutral[50]};
  ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: row;
    column-gap: 24px;
  }
`;

export const PhoneButton = styled.a`
  display: flex;
  color: ${({ theme }) => theme.crediteaColors.primary.brandBlue[500]};
`;
export const Contact = styled(Typography.Caption2)`
  margin-left: 10px;
`;

export const WhatsAppWrapper = styled.a`
  display: flex;
  padding-bottom: 12px;
`;
