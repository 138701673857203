import { isBoolean as boolCheck, isNumber as numberCheck } from 'lodash';
import {
  isBooleanValidator,
  isNumberValidator,
  LEAD_GATHERING_COUNTRIES,
  LeadGatheringCountryCodes,
  LeadGatheringRequestBody,
  MAX_LENGTH,
  maxLengthValidator,
  OptionalField,
  PHONE_LENGTH_PER_COUNTRY,
  Validator,
} from '@web-creditea/interfaces';

export const checkOptionalFields = (
  body: LeadGatheringRequestBody,
  fields: Array<OptionalField>
) => {
  const requiredFields = fields.filter(({ name }) => name in body);
  if (
    hasRequiredFields<LeadGatheringRequestBody>({
      body,
      fields: requiredFields.map(({ name }) => name),
    })
  ) {
    requiredFields.forEach((field) => {
      const { name, validators, args } = field;
      const value = body[name];

      validators.forEach((validator) => {
        let validatorDefinition = {
          fieldName: name,
          value: value,
        };

        if (typeof validator === 'function') {
          if (validator.name === MAX_LENGTH && args) {
            const validationArgs = args[validator.name];
            validatorDefinition = Object.assign(validatorDefinition, validationArgs);
          }
          validator(validatorDefinition);
        }
      });
    });
  }
};

export const hasRequiredFields = <T extends Object>({
  body,
  fields,
}: {
  body: T;
  fields: (keyof T)[];
}): boolean =>
  fields.every((field) => field in body && (Boolean(body[field]) || boolCheck(body[field])));

export const maxLength = ({ value, fieldName, length }: maxLengthValidator) => {
  if (value.toString().length > length) {
    throw new Error(`Length of ${fieldName} has been exceeded`);
  }
  return true;
};
export const isEmail = ({ value, fieldName }: Validator): Boolean | Error => {
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    throw new Error(`Field ${fieldName} is not a email`);
  }
  return true;
};

export const isNumber = ({ value, fieldName }: isNumberValidator): Boolean | Error => {
  if (!numberCheck(value)) {
    throw new Error(`Field ${fieldName} is not a number`);
  }
  return true;
};

export const isBoolean = ({ value, fieldName }: isBooleanValidator): Boolean | Error => {
  if (!boolCheck(value)) {
    throw new Error(`Field ${fieldName} is not a boolean`);
  }
  return true;
};

export const phoneValidator = (country: LeadGatheringCountryCodes): OptionalField => {
  if (!LEAD_GATHERING_COUNTRIES.includes(country)) {
    throw new Error('Selected country is not available');
  }

  return {
    name: 'phone',
    validators: [maxLength, isNumber],
    args: {
      maxLength: {
        length: PHONE_LENGTH_PER_COUNTRY[country],
      },
    },
  };
};
