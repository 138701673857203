import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetAllHomePageQuery,
  GetAllHomePageQueryVariables,
  GetAllHomePageDocument,
} from '@web-creditea/interfaces';

export const getHomePageData =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (lang: string, market: string, preview: boolean) => {
    const {
      data: { allHomePage },
    } = await client.query<GetAllHomePageQuery, GetAllHomePageQueryVariables>({
      query: GetAllHomePageDocument,
      variables: { lang, market },
      fetchPolicy: 'no-cache',
    });

    const pageDraft = allHomePage.find((page) => page._id?.startsWith('drafts'));
    const publishedPage = allHomePage.find((page) => !page._id?.startsWith('drafts'));
    const pageData = preview && pageDraft ? pageDraft : publishedPage;

    return pageData;
  };
