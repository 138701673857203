import React from 'react';
import { IframeFluid } from './Iframe.style';
import { IFrameHeightSettings } from '@web-creditea/interfaces';

export interface IframeProps {
  title?: string;
  url?: string;
  isPromoBar?: boolean;
  promoBarOnlyMobile?: boolean;
  heightSettings?: IFrameHeightSettings;
}

const Iframe = ({ url, title, isPromoBar, promoBarOnlyMobile, heightSettings }: IframeProps) => {
  return (
    <IframeFluid
      src={url}
      title={title}
      isPromoBar={isPromoBar}
      promoBarOnlyMobile={promoBarOnlyMobile}
      setManualHeight={heightSettings?.setManualHeight}
      mobileHeight={heightSettings?.mobileHeight}
      tabletHeight={heightSettings?.tabletHeight}
      desktopHeight={heightSettings?.desktopHeight}
      addTopNavigation={heightSettings?.addTopNavigation}
    />
  );
};

export default Iframe;
