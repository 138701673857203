import styled from 'styled-components';
import Link from 'next/link';
import { Col } from '@ipf-ui/components-library';

export const LinkWrapper = styled(Link)`
  color: inherit;
  padding: 18px 0;
  font-weight: 700;
`;

export const SocialsWrapper = styled(Col)`
  flex: 1;
`;
