import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetFaqPageQuery,
  GetFaqPageQueryVariables,
  GetFaqPageDocument,
  FaqSingleCategoryQuery,
  FaqSingleCategoryQueryVariables,
  FaqSingleCategoryDocument,
  FaqAllCategoriesQuery,
  FaqAllCategoriesQueryVariables,
  FaqAllCategoriesDocument,
  GetAllFaqQuery,
  GetAllFaqQueryVariables,
  GetAllFaqDocument,
} from '@web-creditea/interfaces';

export const getFaqPageData =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (lang: string, market: string, preview: boolean) => {
    const {
      data: { allFaqPage },
    } = await client.query<GetFaqPageQuery, GetFaqPageQueryVariables>({
      query: GetFaqPageDocument,
      variables: { lang, market },
      fetchPolicy: 'no-cache',
    });

    const pageDraft = allFaqPage.find((page) => page?._id?.startsWith('drafts'));
    const publishedPage = allFaqPage.find((page) => !page?._id?.startsWith('drafts'));
    return preview && pageDraft ? pageDraft : publishedPage;
  };

export const getFaqCategoryBySlug =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (language: string, market: string, slug: string | string[], preview: boolean) => {
    const {
      data: { allFaqCategories },
    } = await client.query<FaqSingleCategoryQuery, FaqSingleCategoryQueryVariables>({
      query: FaqSingleCategoryDocument,
      variables: {
        language,
        market,
        slug: typeof slug === 'string' ? slug : slug[0],
      },
      fetchPolicy: 'no-cache',
    });

    const faqCategoryDraft = allFaqCategories.find(
      (category) => category?._id?.startsWith('drafts')
    );
    const faqCategoryPublished = allFaqCategories.find(
      (category) => !category?._id?.startsWith('drafts')
    );
    return preview && faqCategoryDraft ? faqCategoryDraft : faqCategoryPublished;
  };

export const getAllFaqCategories =
  (client: ApolloClient<NormalizedCacheObject>) => async (language: string[], market: string) => {
    const {
      data: { allFaqCategories },
    } = await client.query<FaqAllCategoriesQuery, FaqAllCategoriesQueryVariables>({
      query: FaqAllCategoriesDocument,
      variables: {
        language,
        market,
      },
      fetchPolicy: 'no-cache',
    });
    return allFaqCategories;
  };

export const getAllFaq =
  (client: ApolloClient<NormalizedCacheObject>) => async (language: string[], market: string) => {
    const {
      data: { allFaq },
    } = await client.query<GetAllFaqQuery, GetAllFaqQueryVariables>({
      query: GetAllFaqDocument,
      variables: {
        language,
        market,
      },
      fetchPolicy: 'no-cache',
    });

    return allFaq;
  };
