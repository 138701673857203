export const SUCCESS = 'success';
export const ERROR = 'error';
export const SERVER_ERROR = 'Server error';
export const AUTH_ERROR = 'Authorization error';
export const BAD_REQUEST = 'Bad request';

export const LEAD_GATHERING_COUNTRIES = ['cz', 'ec', 'ee', 'lt', 'lv', 'mx'] as const;

export const PHONE_LENGTH_PER_COUNTRY = {
  cz: 12, // +420 + 9 digits
  ec: 14, // +420 + 11 digits
  ee: 11, // +372 + 8 digits
  lt: 11, // +370 + 8 digits
  lv: 11, // +371 + 8 digits
  mx: 12, // +52 + 10 digits
};

export type ResponseData = {
  status: typeof SUCCESS | typeof ERROR;
  message?: string;
  id?: string;
};

export type LeadGatheringCountryCodes = (typeof LEAD_GATHERING_COUNTRIES)[number];

export type LeadGatheringRequestBody = {
  country: LeadGatheringCountryCodes;
  brand: string;
  campaign: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  consent1Value: boolean;
  consent2Value: boolean;
  consent3Value: boolean;
  consent1Version: number;
  consent2Version: number;
  consent3Version: number;
  consent1Name: string;
  consent2Name: string;
  consent3Name: string;
  shortText1: string;
  shortText2: string;
  shortText3: string;
  longText: string;
  formURL: string;
  sourceURL: string;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmContent: string;
  utmTerm: string;
};

export type LeadGatheringResponseBody = {
  logID: string;
};
