import styled from 'styled-components';
import { theme } from '@ipf-ui/components-library';

export const SectionWrapper = styled.div`
  background-color: ${({ theme }) => theme.crediteaColors.primary.brandBlue[500]};
`;

export const FormHeader = styled.div`
  padding-top: 45px;

  ${theme.breakpoints.md} {
    width: 80%;
  }
`;

export const Form = styled.form`
  padding-bottom: 25px;

  ${theme.breakpoints.md} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 90px;
  }
  ${theme.breakpoints.lg} {
    gap: 130px;
  }
`;

export const ShowOnMobile = styled.div`
  ${theme.breakpoints.md} {
    display: none;
  }
`;

export const ShowNotOnMobile = styled.div`
  display: none;
  ${theme.breakpoints.md} {
    display: block;
  }
`;

export const WhiteText = styled.div`
  * {
    color: ${theme.crediteaColors.primary.neutral[50]} !important;
  }
`;
