import { BlogPostSectionsFragment } from '@web-creditea/interfaces';

export const renderContentList = (sections: BlogPostSectionsFragment[]) => {
  const filteredSections = sections.filter((section) => section.__typename !== 'Gutter');
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  return filteredSections.reduce<any[]>((acc, section) => {
    if ('scrollToSection' in section && section.scrollToSection?.enableAnchor) {
      return [
        ...acc,
        {
          anchor: section.scrollToSection.anchorTitle,
          title: section.scrollToSection.linkTitle,
        },
      ];
    }
    return acc;
  }, []);
};
