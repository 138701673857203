import React from 'react';
import Image from 'next/image';

import * as Styled from './SanityImageWrapper.style';

interface SanityImageWrapperProps {
  value: {
    _key: string;
    _type: 'imageWithAlt';
    asset: { _ref: string; _type: 'reference' };
    customAltText: string;
  };
  urlFor?: (filename: string) => string;
}

const SanityImageWrapper = ({ value, urlFor }: SanityImageWrapperProps) => {
  const { asset, customAltText } = value;

  if (!urlFor) return null;
  const filename = asset._ref.replace('image-', '');
  const imageUrl = urlFor(filename);

  return (
    <Styled.SanityImageWrapper>
      <Image src={imageUrl} alt={customAltText} fill style={{ objectFit: 'contain' }} />
    </Styled.SanityImageWrapper>
  );
};

export default SanityImageWrapper;
