import React from 'react';
import * as Styled from './Copyrights.style';
import PortableTextWrapper from '../../atoms/PortableTextWrapper/PortableTextWrapper';
import { PortableTextBlock } from '@portabletext/types';

interface CopyrightsProps {
  footerLegal?: { textRaw: Array<PortableTextBlock> };
}

const Copyrights = ({ footerLegal }: CopyrightsProps) => {
  return (
    <Styled.CopyrightsContainer data-testid="copyrights">
      {footerLegal && <PortableTextWrapper portableText={footerLegal?.textRaw} />}
    </Styled.CopyrightsContainer>
  );
};

export default Copyrights;
