/* eslint-disable @typescript-eslint/no-explicit-any */
import omit from 'lodash/omit';
import React from 'react';
import { Gutter, AllCols, Button, SearchInput, theme } from '@ipf-ui/components-library';

import {
  DownloadAppButtonsWrapper,
  LoanFormWrapperEE,
  LoanFormWrapperLV,
  LoanFormWrapperLT,
  LoanFormWrapperMX,
  LoanFormWrapperCZ,
  PortableTextWrapper,
} from '@web-creditea/utils';

import NextImage from 'next/image';

import { HeroContent } from '@web-creditea/interfaces';
import Link from 'next/link';
import { createLink, definedValues, getUserDevice } from '../../helpers';
import { Market } from '@web-creditea/interfaces';

const contentTypes = {
  HeroBackgroundImage: 'image',
  DownloadAppButtons: 'downloadAppButtons',
  HeroTextSection: 'textSection',
  LoanForm: 'loanForm',
  HeroButton: 'button',
  HeroSearchInput: 'HeroSearchInput',
};

interface createObjectValueProps {
  contentType?: string;
  contentProps: any;
  downloadAppButtonsTitle?: string;
  market?: Market;
  searchInput?: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  textColorTitle: 'light' | 'dark' | undefined;
  kubernetesNamespace?: string;
}

const createObjectValue = ({
  contentType,
  contentProps,
  downloadAppButtonsTitle,
  market,
  searchInput,
  setSearchInput,
  textColorTitle,
  kubernetesNamespace,
}: createObjectValueProps) => {
  const userDevice = getUserDevice(market);

  switch (contentType) {
    case 'HeroBackgroundImage':
      return {
        ...contentProps,
        image: (
          <NextImage
            src={contentProps.asset?.url}
            alt={contentProps.customAltText || contentProps.asset.altText || ''}
            priority
            fill
          />
        ),
      };
    case 'DownloadAppButtons':
      return (
        <>
          <Gutter size={24} />
          <DownloadAppButtonsWrapper
            showMobileButton={true}
            downloadAppButtonsTitle={downloadAppButtonsTitle}
            userDevice={userDevice}
            iconVariant={textColorTitle}
          />
          <Gutter size={40} />
        </>
      );
    case 'LoanForm':
      if (market === 'crediteaEE') {
        return (
          <LoanFormWrapperEE
            {...contentProps}
            market={market}
            kubernetesNamespace={kubernetesNamespace}
          />
        );
      } else if (market === 'crediteaLV') {
        return (
          <LoanFormWrapperLV
            {...contentProps}
            market={market}
            kubernetesNamespace={kubernetesNamespace}
          />
        );
      } else if (market === 'crediteaLT') {
        return (
          <LoanFormWrapperLT
            {...contentProps}
            market={market}
            kubernetesNamespace={kubernetesNamespace}
          />
        );
      } else if (market === 'crediteaMX') {
        return <LoanFormWrapperMX {...contentProps} kubernetesNamespace={kubernetesNamespace} />;
      } else if (market === 'crediteaCZ') {
        return (
          <LoanFormWrapperCZ
            {...contentProps}
            market={market}
            kubernetesNamespace={kubernetesNamespace}
          />
        );
      }
      break;
    case 'HeroButton':
      // eslint-disable-next-line no-case-declarations
      const { href, isExternal, linkTitle, anchorTarget } = createLink(contentProps.link);

      return !isExternal ? (
        <Link href={href} passHref>
          <Button
            variant={contentProps.buttonConfig?.variant ?? 'primary'}
            mode={contentProps.buttonConfig?.colorMode ?? 'dark'}
          >
            {linkTitle}
          </Button>
        </Link>
      ) : (
        <Button
          variant={contentProps.buttonConfig?.variant ?? 'primary'}
          mode={contentProps.buttonConfig?.colorMode ?? 'dark'}
          onClick={() =>
            window.open(href, anchorTarget ? '_self' : '_blank', 'noopener,noreferrer')
          }
        >
          {contentProps.link?.linkTitle}
        </Button>
      );
    case 'HeroSearchInput':
      return (
        <SearchInput
          onChange={setSearchInput}
          placeholder={contentProps.placeholder}
          value={searchInput}
        />
      );
    case 'HeroTextSection':
      return {
        ...contentProps,
        textContent: (
          <PortableTextWrapper
            portableText={contentProps.textContent?.textRaw}
            mode={textColorTitle}
          />
        ),
      };
    default:
      return contentProps;
  }
};

interface getContentElementsProps {
  elements?: HeroContent['elements'];
  downloadAppButtonsTitle?: string;
  market?: Market;
  searchInput?: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  textColorTitle?: string;
  kubernetesNamespace?: string;
}

const getContentElements = ({
  elements,
  downloadAppButtonsTitle,
  market,
  searchInput,
  setSearchInput,
  textColorTitle,
  kubernetesNamespace,
}: getContentElementsProps) => {
  const elementsObject: Record<any, any> = {};
  elements?.map((el) => {
    if (!el?.__typename) return;
    const key = contentTypes[el.__typename];
    elementsObject[key] = createObjectValue({
      contentType: el.__typename,
      contentProps: omit(el, ['__typename']),
      downloadAppButtonsTitle,
      market,
      searchInput,
      setSearchInput,
      textColorTitle:
        textColorTitle === theme.crediteaColors.primary.neutral[800] ? 'dark' : 'light',
      kubernetesNamespace,
    });
  });
  return elementsObject;
};

interface MapIntoContentProps {
  content?: Array<HeroContent | null>;
  downloadAppButtonsTitle?: string;
  market?: Market;
  searchInput?: string;
  setSearchInput?: React.Dispatch<React.SetStateAction<string>>;
  textColorTitle?: string;
  kubernetesNamespace?: string;
}

export const mapIntoContentProps = ({
  content,
  downloadAppButtonsTitle,
  market,
  searchInput,
  setSearchInput = () => {},
  textColorTitle,
  kubernetesNamespace,
}: MapIntoContentProps) => {
  return definedValues(content)?.map((item) => ({
    size: (item.isColumn ? 6 : 12) as AllCols,
    ...getContentElements({
      elements: item.elements,
      downloadAppButtonsTitle,
      market,
      searchInput,
      setSearchInput,
      textColorTitle,
      kubernetesNamespace,
    }),
  }));
};
