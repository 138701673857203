import React from 'react';
import Link from 'next/link';
import * as Styled from './Logo.style';

type ILogo = {
  children: React.ReactNode;
  variant: 'full' | 'symbol';
};

const Logo = ({ children, variant }: ILogo) => {
  return (
    <Link href="/">
      <Styled.Logo variant={variant}>{children}</Styled.Logo>
    </Link>
  );
};

export default Logo;
