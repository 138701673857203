import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetTranslationRedirectDocument,
  GetTranslationRedirectQuery,
  GetTranslationRedirectQueryVariables,
} from '@web-creditea/interfaces';

type TranslationsConfig = {
  slug: string;
  market: string;
  countryCode: string;
  languageCode: string;
};

export const getTranslationRedirect =
  (client: ApolloClient<NormalizedCacheObject>) => async (config: TranslationsConfig) => {
    const { countryCode, languageCode, market, slug } = config;

    const { data } = await client.query<
      GetTranslationRedirectQuery,
      GetTranslationRedirectQueryVariables
    >({
      query: GetTranslationRedirectDocument,
      variables: { market, slug },
      fetchPolicy: 'no-cache',
    });
    const translationRedirect = data.allPages.find((page) => !page._id?.startsWith('drafts'));
    if (!translationRedirect?.redirectUrl) {
      return false;
    }

    const { redirectUrl } = translationRedirect;
    if (redirectUrl.externalUrl) {
      return redirectUrl.externalUrl;
    }

    const internalLinkLanguage = redirectUrl.internalLink?.language?.toLowerCase();
    const lang = internalLinkLanguage === languageCode ? '' : `/${internalLinkLanguage}`;
    const redirect =
      redirectUrl?.internalLink &&
      'slug' in redirectUrl.internalLink &&
      redirectUrl.internalLink?.slug
        ? redirectUrl.internalLink.slug.current
        : '';

    return `/${countryCode.toLowerCase()}${lang}/${redirect}`;
  };
