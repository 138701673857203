import React from 'react';

import { IconNames, theme } from '@ipf-ui/components-library';
import { PortableTextWrapper } from '@web-creditea/utils';
import * as Styled from './IconListItem.styles';
import { PortableTextBlock } from '@portabletext/types';
import { Maybe, SimplerColor } from '@web-creditea/interfaces';
interface IconListItemProps {
  data?: {
    icon?: string;
    iconColor?: Maybe<SimplerColor>;
    textContent?: {
      textRaw?: Array<PortableTextBlock>;
    };
  };
}

const IconListItem: React.FC<IconListItemProps> = ({ data }) => {
  if (!data) return null;
  const { icon, iconColor, textContent } = data;

  return (
    <Styled.ListItem>
      <Styled.NewIcon
        name={icon as IconNames}
        circleColor={iconColor?.value ?? theme.crediteaColors.primary.neutral[100]}
      />
      {textContent?.textRaw && <PortableTextWrapper portableText={textContent.textRaw} />}
    </Styled.ListItem>
  );
};

export default IconListItem;
