import styled from 'styled-components';

export const ExitPreviewButton = styled.div`
  position: fixed;
  z-index: 9999;
  left: 50px;
  top: 8%;
  border-style: solid;
  border-color: #f90b0b;
  height: 50px;
  line-height: 45px;
  padding: 0 10px;
  cursor: pointer;
`;
