import {
  Country,
  FaqCategories,
  FaqSearchResults,
  FaqSingleCategoryQuery,
} from '@web-creditea/interfaces';
import type { LinksWithTitleProps } from '@ipf-ui/components-library';
import { definedValues } from '../returnMaybe';

export const createFaqCategoryLinks = (
  items?: (FaqCategories | Country)[],
  faqTranslation?: string
) => {
  return items?.map((item) => {
    switch (item.__typename) {
      case 'Country':
        return {
          name: item.name ?? '',
          subDescription: item.subDescription ?? '',
          anchorTarget: Boolean(item.anchorTarget),
          icon: item.icon ?? '',
          href: item.href ?? '',
        };
      case 'FaqCategories':
        return {
          name: item.category?.name ?? '',
          icon: item.category?.iconName ?? '',
          href: faqTranslation ? `/${faqTranslation}/${item.category?.slug?.current ?? ''}` : '',
        };
      default: {
        return {};
      }
    }
  });
};

export const createFaqGroupLinks = (
  list: FaqSingleCategoryQuery['allFaqCategories'][number]['list'],
  currentCategory: string | string[]
): LinksWithTitleProps[] => {
  const category = typeof currentCategory === 'string' ? currentCategory : currentCategory[0];
  const linksWithTitle = definedValues(
    list?.map((listData) => {
      const categoryItems = definedValues(listData?.faqList).map((listItem) => {
        if (listItem.__typename === 'Faq') {
          return {
            title: listItem.title ?? '',
            href: `${category}/${listItem?.slug?.current}`,
            internalLink: true,
          };
        } else {
          return {
            title: listItem.title ?? '',
            href: listItem.url ?? '',
            internalLink: false,
          };
        }
      });

      return {
        title: listData?.title ?? '',
        borderTop: Boolean(listData?.borderTop),
        links: categoryItems,
      };
    })
  );

  return linksWithTitle ?? [];
};

export const mapSearchResults = (
  faqList: FaqSearchResults[],
  faqTranslation: string
): LinksWithTitleProps[] => {
  return definedValues(
    faqList.map((faq) => {
      if (!faq.faqCategory?.length) return;
      return {
        title: faq.title ?? '',
        href: `/${faqTranslation}/${faq.faqCategory[0].category?.slug?.current}/${faq.slug?.current}`,
        internalLink: true,
      };
    })
  );
};

export const createFaqCategoryBreadcrumbs = (
  faqTranslation: string,
  categoryName: string,
  faqSlug?: string
) => {
  return [
    {
      text: faqTranslation.charAt(0).toUpperCase() + faqTranslation.slice(1),
      href: `/${faqSlug || faqTranslation}`,
    },
    {
      text: categoryName,
      href: '#',
    },
  ];
};

export const createFaqBreadcrumbs = (
  faqTranslation: string,
  faqCategory: { name?: string; slug?: { current?: string } },
  title: string,
  faqSlug?: string
) => {
  return [
    {
      text: faqTranslation.charAt(0).toUpperCase() + faqTranslation.slice(1),
      href: `/${faqSlug || faqTranslation}`,
    },
    {
      text: `${faqCategory.name}`,
      href: `/${faqSlug || faqTranslation}/${faqCategory.slug?.current}`,
    },
    { text: title, href: '#' },
  ];
};
